
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,600,700,800'); 
/* @import url('https://fonts.googleapis.com/css?family=Noto+Sans:400,500,700&display=swap'); */

@import url('https://fonts.googleapis.com/css2?family=Overpass+Mono:wght@300&display=swap');


@import url('//fonts.googleapis.com/css2?family=Noto+Sans+Mongolian&display=swap');

@import url('//fonts.googleapis.com/css2?family=Noto+Sans+TC&display=swap');

@import url('//fonts.googleapis.com/css2?family=Noto+Serif+Khmer:wght@400;700;900&display=swap');

@import url('//fonts.googleapis.com/css2?family=Noto+Serif+Tibetan:wght@300;400;500;600;700;900&display=swap');


/* @import url('//fonts.googleapis.com/earlyaccess/notosanstibetan.css'); */

/* CSS debug mode */
/* * {background-color:rgba(255,0,128,0.05);}   */


/*
@font-face {
   font-family: 'Noto Sans Cond';
   src: url('/fonts/NotoSans-CondensedBold.woff2') format('woff2'),
       url('/fonts/NotoSans-CondensedBold.woff') format('woff');
   font-weight: bold;
   font-style: normal;
}
*/

@font-face {
   font-family: 'Noto Sans SemCond';
   src: url('/fonts/NotoSans-SemiCondensedBold.woff2') format('woff2'),
       url('/fonts/NotoSans-SemiCondensedBold.woff') format('woff');
   font-weight: bold;
   font-style: normal;
}


@font-face {
   font-family: 'Noto Sans';
   src: url('/fonts/NotoSans-Light.woff2') format('woff2'),
       url('/fonts/NotoSans-Light.woff') format('woff');
   font-weight: 300;
   font-style: normal;
}

@font-face {
   font-family: 'Noto Sans';
   src: url('/fonts/NotoSans-Regular.woff2') format('woff2'),
       url('/fonts/NotoSans-Regular.woff') format('woff');
   font-weight: normal;
   font-style: normal;
}

@font-face {
   font-family: 'Noto Sans';
   src: url('/fonts/NotoSans-Medium.woff2') format('woff2'),
       url('/fonts/NotoSans-Medium.woff') format('woff');
   font-weight: 500;
   font-style: normal;
}

@font-face {
   font-family: 'Noto Sans';
   src: url('/fonts/NotoSans-SemiBold.woff2') format('woff2'),
   url('/fonts/NotoSans-SemiBold.woff') format('woff');
   font-weight: 600;
   font-style: normal;
}

@font-face {
   font-family: 'Noto Sans';
   src: url('/fonts/NotoSans-Bold.woff2') format('woff2'),
       url('/fonts/NotoSans-Bold.woff') format('woff');
   font-weight: bold;
   font-style: normal;
}

@font-face {
   font-family: 'Noto Sans';
   src: url('/fonts/NotoSans-Black.woff2') format('woff2'),
       url('/fonts/NotoSans-Black.woff') format('woff');
   font-weight: 900;
   font-style: normal;
}


/* ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ footer */

footer#site-footer { background:#172832; color:white; padding-bottom:0px; }
footer#site-footer > div.head { background: #dddddd; position:absolute; height:55px; width:100%; display:block;z-index:0;}
footer#site-footer > div.head::before { content:'';display:block;background:white /*#efefef*/; height:100%; width:1440px;max-width:100%; margin:auto;}
footer#site-footer > div.foot,
footer#site-footer > div.body { padding:40px 60px; width:1222px; max-width:100%; margin:auto; z-index:1;
   display:block;position: relative;box-sizing: border-box;
}

footer#site-footer > div.body { background:#fce08d; color:#172832; }
footer#site-footer div * { vertical-align: top;}
footer#site-footer .p50 { width:50%; display: inline-block;}
footer#site-footer .p35 { width:35%; display: inline-block;}
footer#site-footer .p65 { width:65%; display: inline-block;}
footer#site-footer .p40 { width:42%; display: inline-block;}
footer#site-footer .p60 { width:58%; display: inline-block;}

footer#site-footer #b2 #f1 span a { color:#111312; text-decoration: none; font-size:12px;}

footer#site-footer > .foot *,
footer#site-footer > .body * { font-size: 14px;  letter-spacing: 0.34px; font-family:"Noto Sans TC", "Noto Sans", "Noto Serif Tibetan", "Noto Serif Khmer", "Noto Sans Mongolian", "Roboto", "Helvetica", "Arial", sans-serif  !important;} 
footer#site-footer > .body #b1 #f1 p { font-size: 20px;  letter-spacing: 0.28px;  line-height: 25px; font-weight:700; display:inline-block; width:190px;margin:0; }
footer#site-footer > .body #b2 #f1 span { border: 2px solid #111312; text-transform: uppercase; font-size: 12px; letter-spacing: 0.35px; border-radius: 2px;padding:10px;
   margin-top:0px; display: inline-block;
}
footer#site-footer > .body #b1 #f2,
footer#site-footer > .body #b2 #f1 { padding-top:10px;margin-top:10px;}

footer#site-footer > .body #b1 #f2 { padding-top:0; }

footer#site-footer > .body #b2 #f1 { padding-bottom:15px; border-right:1px solid #111312; margin-right:-1px;}
footer#site-footer > .body #b2 #f2 > div { float:right; padding-right:80px; }
footer#site-footer > .body #b2 #f2 #s1 { display:flex; align-items: center;}
footer#site-footer > .body #b2 #f2 #s1 > a:not(:last-child) > div svg { max-width:32px; position:relative !important; }
footer#site-footer > .body #b2 #f2 #s1 > a:not(:last-child) > div,
footer#site-footer > .body #b2 #f2 #s1 > a { width:52px !important;height:52px !important;display:inline-flex;align-items: center; justify-content: center; }
footer#site-footer > .body #b2 #f2 #s1 > a { border:2px solid #111312; border-radius: 50%;margin-top:10px;margin-right:10px;}

footer#site-footer > div.foot #donate { text-transform:capitalize; width:120px; float:right; font-weight:700; font-family:"Noto Sans", "Noto Serif Tibetan", "Noto Serif Khmer", "Noto Sans Mongolian", "Roboto", "Helvetica", "Arial", sans-serif !important; margin-top:20px; }
footer#site-footer > div.foot #p2,
footer#site-footer > div.foot #p1 { display:inline-flex; align-items: center;}
footer#site-footer > div.foot #t1 > div { align-items: flex-start;  }
footer#site-footer > div.foot #p2 img,
footer#site-footer > div.foot #p1 img { margin-right:30px;}
footer#site-footer > div.foot #p1 img + span  { padding-bottom:5px; display: inline-block; line-height:24px;}
footer#site-footer > div.foot #p2 img + div,
footer#site-footer > div.foot #p1 img + div { width:150px; line-height:24px;}
footer#site-footer > div.foot #p1 img + div { width:220px; }
footer#site-footer > div.foot img + div a { display:block; text-decoration: none; color:white; font-weight:600; margin-top:12px; }

footer#site-footer > div.foot #t2 { position:relative;}
footer#site-footer > div.foot #t2::before { content:''; display:block; position: absolute; left:-65px; top:5px;height:75px; border-left: 1px solid white;}

footer#site-footer [lang='bo'] { font-size:18px !important;}

footer#site-footer.no-syncs > div.head::before { background-color:#efefef; }

/* ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ home */

.App:not(.home) { min-height:100vh;} 
.App:not(.home) .SearchPane { min-height:calc(100vh - 80px - 50px * 0);  }


.ISB *,
#popHowTo div p,
.etext-header-breadcrumbs *,
.AppSK *,#etext-nav .etext-search input,
#more-pages-menu a,[role='tooltip'] *, .App *,.nav *,.App *::before,.App *::after, .react-tabs *, .resource *:not(.fa):not(.material-icons), .resource + * *,
.inRes *, #popDL.cite *, #menu-citationLang *, #pop-login li { 
   font-family:"Noto Sans", "Noto Serif Tibetan", "Noto Serif Khmer", "Noto Sans Mongolian", "Roboto", "Helvetica", "Arial", sans-serif !important;
}

.SearchPane { width:1000px; margin:auto;margin-top:80px;max-width:90%; z-index:0;background:white;padding:50px; padding-bottom:0;
   padding-top:0 !important;  padding-bottom:0; }

#root .App .SearchPane,
#root > div > .resource {   box-sizing: content-box; background-color:#efefef; width:1160px; margin-left:auto;margin-right:auto;
   max-width:100%;background-color:#efefef;  position:relative; }

#root > div > .resource {  padding:155px 140px; padding-bottom:75px; display:flex; text-align:left; }

.subsubtitleFront { /*font-style:italic;*/ margin-top:10px;max-width:70%;margin-left:15%;  font-weight:700 !important; }
.subsubtitleFront > a {text-decoration: none; color: #d73449; }
.subsubtitleFront > a[lang="bo"] { padding-left:5px; }

h4:not(.subsubtitleFront) + .subsubtitleFront.feedB,
.subsubtitleFront:last-of-type,
.subsubtitleFront + .subsubtitleFront { font-weight:400 !important;}


body { overflow:auto !important;padding-right:0 !important; /*font-family:"Roboto", "Helvetica", "Arial", sans-serif; */
   background-color:#dddddd;   }

/* .resultPage > .loader { position:fixed;display:block; width: 100vw;height: 100vh; left: 0; top: 0; } */

#root .App .SearchPane { padding-left:0;padding-right:0; width:1440px;max-width:100%;}

.SearchPane .fond-logo  { background:white; }
.SearchPane.resultPage .fond-logo { display:none; }


.SearchPane #logo {    
   width: 100%;
   display: flex;
   font-size: 0;
   position: relative;
   justify-content: center;
}
.SearchPane #logo > div { 
   width:1440px;
   max-width:100%;
   height:100%;
   position:absolute;
   display:flex;
   align-items: flex-end;
   justify-content: flex-end;
   top:0;
}
.SearchPane #logo > div > div {
   width:620px; height:230px; background:white;padding:0px 30px;box-sizing: border-box;
   font-size:24px;color:black;line-height:28px; display:flex; flex-direction: column; justify-content: center; align-items: flex-start;
}

.SearchPane #logo h1 { color: #152631; font-size: 40px; font-weight: 900; font-family:"Noto Sans TC","Noto Sans", "Noto Serif Tibetan", "Noto Serif Khmer", "Noto Sans Mongolian", "Roboto", "Helvetica", "Arial", sans-serif  !important;
   /* letter-spacing: 0.65px;  */
   line-height: 48px;margin-top:0; margin-bottom:14px;text-align: left;;
}

.SearchPane #logo h1[lang="bo"] {  font-size:61px; margin-bottom:32px; font-weight:500; line-height: 105px; }

.SearchPane #logo h1 + div {
   font-weight:500;
   margin-bottom:15px;
}
.SearchPane #logo h1 ~ span {
   color: #4a4a4a;
   font-size: 16px;
   line-height:22px;
   display:block;
   font-weight: 400;
   text-align: left;
   /* letter-spacing: 0.31px; */
}

.SearchPane #logo h1[lang="bo"]~span {
   line-height:200%;
}

.infoPanel {
   background: #d73449;
   color: white;
   font-weight: 600;
   flex-wrap: wrap;
}

.infoPanel p svg {
   vertical-align: bottom;
   padding:0 5px;
}
.infoPanel p svg.error {
   transform:rotate(45deg);   
   vertical-align: -6px;
}

.infoPanel p { width:100%; }
.infoPanel p + p { margin-top:-5px;  }

.infoPanel p a { color:white; text-decoration: underline;}

.infoPanel.inRes {
   left: 0;
   margin: 80px auto 0 auto!important;
   width: 100% !important;
   top: 0;
   text-align: center;
   width:calc(1160px + 2*140px) !important;   
   max-width:100%;
}


.infoPanel p { width:100%; margin:16px 0; display: inline-block;}

.infoPanel + .resource > .ariane { top:40px;  }

#root > div > .infoPanel + .resource { padding-top:85px; }

.infoPanel.version { 
   z-index: 1000001;
   position: absolute;
   width:11%;
   left:44.5%;
   height:50px;
   top:0px;
   display: flex;
   align-items: center;
   justify-content: center;
}
.infoPanel.version p { margin:0; }

.infoPanel.version a {
   display: flex;
   align-items: center;
   justify-content: center;
   text-decoration: none;
   color:white;
}
.infoPanel.version a .info {
   padding: 0 5px 2px 5px ;
}

.infoPanel.version a:hover span { text-decoration: underline; }

.infoPanel p .nl:not(:last-child):after { content:'';display:block;}

.msg-popup { position: fixed; top:0 ;left:0;right:0;bottom:0; display:flex; align-items: center; justify-content: center; z-index:100100;}
.msg-popup .back { width: 100%; height:100%; background:rgba(0,0,0,0.5); position: absolute;}
.msg-popup .front { background:white; padding:20px 40px;box-shadow: 0 0 10px 3px rgba(0,0,0,0.5); border-radius: 0px; z-index:1; max-width:80%; width:540px; position: relative;
   box-sizing: border-box  ; max-height:80%; overflow: auto;
}
.msg-popup .front p { color:#555; text-align: center; font-weight: 500; font-size: 15px;}
.msg-popup .front p a { color:#d73449; font-weight:600; text-decoration: none; }
.msg-popup .front p a:hover { text-decoration: underline; }
.msg-popup .front p > br + a:last-child { color:white; text-transform: uppercase; text-decoration: none; font-weight: 700; padding:10px 15px; background: #d73449; border-radius: 5px; 
   display: inline-block; margin-top: 10px;
}
.msg-popup .front .close { color:#555; position:absolute; right:15px; top:15px; cursor: pointer;}

#root > div > :not(.static):first-child ~ .nav:not(.etext-nav).msgPopupOn::after { content:''; position: absolute; width:100%;height:100%;background:rgba(0,0,0,0.5); z-index: 1;}
#root > div > :not(.static):first-child ~ .nav:not(.etext-nav).msgPopupOn { box-shadow: 0 2px 4px rgba(0,0,0,0.35); }

#root > .profile.static:not(#a) > .nav:not(.etext-nav).msgPopupOn::after { content:none; }
#root > .profile.static:not(#a) > .nav:not(.etext-nav).msgPopupOn { box-shadow:none;}

.static-container > #samples > .doc-content { max-width:100%; padding-top:0; padding-bottom:100px;}
.static .doc-content .index { position:fixed; width:310px; background:white; top:130px; padding:0; padding-top:0px; padding-bottom:40px;}
.static .doc-content .index > div { height:calc(100vh - 80px - 50px /*- 40px*/); overflow: auto; box-sizing: border-box; padding-bottom:40px; box-sizing: border-box;}
.static .doc-content .index ~ * { padding-left:350px; }
.static .doc-content .index .c25:not(.c19,.c8) a { font-size:16px; font-weight:600; margin-top:5px; display: block; }
.static .doc-content .index .c19 a { font-size:14px; font-weight:500;}
.static .doc-content .index .c8 a { font-size:13px; font-weight:400; }
.static .doc-content a:focus-visible { outline:none; }

/* .static-container > #samples > .doc-content .index a {  color:#000; } */


.static .doc-content .index .c8,
.static .doc-content .index .c27 { display: none;}

.static-container > #samples > .doc-content [id] { scroll-margin:80px; }

.static .doc-content .index + div > h3.c24 > span:not(.c18),
.static .doc-content .index + div > p.c3.subtitle,
.static .doc-content .index + div > p > span:not(.c18) {width:100% !important; text-align: center; }

.static .doc-content .index + div > p > span { height:auto !important; }
.static .doc-content .index + div > p > span > img { max-width:100%; object-fit: contain; object-position: center bottom; width:auto !important;height:auto !important;}

.static .doc-content .index + div > p:not(.subtitle) > span:not(.c18,.c1) { padding:0px 0 0 0 !important; margin-top:40px;}

.static .doc-content .index + div > p.c3.subtitle { padding: 0 0 0px 0 !important; margin-top:-10px;margin-bottom:40px;}

.static .doc-content .index sup { display:none; }

.static .doc-content .index .on { font-weight:800 !important; /*text-decoration:underline; text-decoration-thickness: 2px; text-decoration-style: dotted ;*/}

/*
.static .doc-content .index .on::before { content:">"; position:absolute;right:100%;top:0;margin-right:5px;margin-top:4px;}
.static .doc-content .index .on::after { content:"<"; position:absolute;left:100%;top:0;margin-left:5px;margin-top:4px;}
*/

[lang='bo'] .static-container[data-dir^="user-guide"] #samples { padding-bottom:0; margin:0 !important; }
.static-container[data-dir^="user-guide"] #samples .doc-content.bo { padding-bottom:0px; padding-right:0;}
.static .doc-content.bo iframe { width:100%; height:calc(100vh - 90px); border:none; top:-50px; position: relative; margin-bottom:-50px;}
.static .doc-content.bo .index { padding-top:16px; width:310px;}
.static .doc-content.bo .index p { margin-bottom:0px;margin-top:10px; }
.static .doc-content.bo .index~* { padding-left:350px; }


.static-container > #samples > .doc-content h3 { text-align: left; text-transform: none;}

.static-container > #samples > .doc-content .c14 { text-decoration:none; }

.static-container[data-dir^="user-guide"] #samples { margin-bottom:0px; padding-top:55px;}

.static-container[data-dir^="user-guide"] #samples img {}

.static .doc-content h3 > span { font-weight:500;}
.static .doc-content h2 > span { font-weight:500;}
.static .doc-content h1 > span { font-weight:700;}

.static .doc-content .title span { font-family: "Noto Sans TC","Noto Sans", "Noto Serif Tibetan", "Noto Serif Khmer", "Noto Sans Mongolian", "Roboto", "Helvetica", "Arial", sans-serif !important; 
   font-weight: 900; font-size:42px; line-height:44px; white-space: nowrap;
}

.static .doc-content .title { margin-bottom:30px;}

@media(max-width:1024px) {
   .static-container[data-dir^="user-guide"] #samples { padding:20px;}
   .static-container[data-dir^="user-guide"] #samples .doc-content { padding:0;  }
   .static-container[data-dir^="user-guide"] #samples .doc-content .index { top:50px; }
}


@media(max-width:800px) {
   .static-container[data-dir^="user-guide"] #samples .doc-content .index { position: relative; top:110px; }
   .static-container[data-dir^="user-guide"] #samples .doc-content .index > div { height:auto; overflow:visible; padding:60px 0 0 0; }
   .static-container[data-dir^="user-guide"] #samples .doc-content .index + div { padding-left:0; margin-top:115px; }
   .static-container[data-dir^="user-guide"] #samples .doc-content .index + div > .title:first-child { position: absolute; top:55px; }
}



@media(max-width:400px) {
   .static-container[data-dir^="user-guide"] #samples .doc-content .index + div > .title:first-child span { font-size:34px; }
}

/* .static .doc-content .index + div > p.c2.subtitle > span { padding:} */


html[lang="bo"] .msg-popup .front p { font-size:18px;}

@media(max-width:1024px) {
   .infoPanel.version { top:0; }
}

/* -------------------------------------------------------------------------------------------------------------------- searchbar */

#data-checkbox { display:flex;justify-content: center; margin:10px; margin-left:-105px; visibility:hidden;}
.data-checkbox{ margin-left:4px !important; }
.data-checkbox span svg { font-size:16px !important; }
.data-checkbox > span:last-child { font-size:13px !important; }
.data-checkbox > span:first-child { width:24px; color:rgba(0,0,0,0.7) !important;}
.data-checkbox > span > span { width:24px;height:24px;}
.data-checkbox > span > span:last-child { margin-top:12px; }

#root ~ #menu-datatype,
#root ~ #menu-language { z-index:1000000;}

#root ~ #menu-datatype ul,
#root ~ #menu-language ul { padding:0 !important;}

#search-bar { width:100%; scroll-margin-top: 80px; }
#search-bar button { margin-top:6px; }
#search-bar > div:first-child { box-shadow: 0 2px 4px rgba(187, 187, 187, 0.5); border-radius:2px; font-weight:600;  }
#search-bar > div:first-child  > div:first-child  > div:first-child { width:calc(100% - 160px); margin-right:200px; }

#search-bar { background-color: #fce08e; padding-top:30px;padding-bottom:5px; display:block;}
.App.home:not(.khmerServer) #search-bar { padding-bottom:30px; }

#search-bar > div { display:inline-flex;}
.SearchPane > div {display:flex;justify-content: center;}
.SearchPane .formControl { margin-top:14px; margin-left:-240px; /*margin-left:calc(800px - 150px - 100px);*/ width:200px; position:absolute;}
.SearchPane .formControl:before {  left: 0; right: 0; bottom: 0; content: "\00a0";  position: absolute;  }
.SearchPane a {text-decoration:none}

.SearchPane .formControl > div::before,
.SearchPane .formControl > div::after  { content:none;}


.SearchPane.resultPage #data-checkbox { display:none; }

#clear-filters { vertical-align:-5px; margin-left: 5px; cursor:pointer; color:rgba(0,0,0,0.55)}

.active-filter > span { display:inline-block; padding-left:5px; font-size:11px; color:#d73449; font-weight:500; display: inline-flex; flex-shrink:0; }
.active-filter { background:rgba(196, 164, 164, 0.55); padding:0 5px; border-radius: 15px; box-shadow:3px 3px 10px rgba(0,0,0,0.35);  text-transform:capitalize;
   margin:0 10px 0 0; display:inline-block; line-height:100%;border:1px solid transparent; display: inline-flex; align-items: baseline;
}
.active-filter,
.active-filter svg { font-size: 10px; vertical-align:middle; color: #152631; }
.active-filter svg { margin-left:5px; margin-right:5px; font-size:13px; vertical-align:-3px; cursor:pointer;}
.active-filter.exclu { border:1px solid rgba(229, 74, 53,0.65); text-decoration:line-through;text-decoration-color:rgba(229, 74, 53,1); }
.active-filter.exclu span b span { text-decoration:line-through;text-decoration-color:rgba(229, 74, 53,1); }

.active-filter > span b span { max-width:350px;text-overflow: ellipsis;overflow: hidden; white-space: nowrap; display: block; }

/* .active-filter > span > span:first-child { display:none; } */


.active-filter.disabled > a:last-child
/* .active-filter:first-child > a:last-child  */
{ opacity:0.5; pointer-events: none;  }

.active-filter { font-size:0; box-shadow: 0 2px 4px rgba(187, 187, 187, 0.5); border-radius: 2px;background:white; height:28px;line-height:28px;}
.active-filter,#clear-filters  {  margin-bottom:10px;  }

.active-filter span b,
.active-filter b span { color: #152631; font-size:11px; font-weight:700; padding-left:2px;}
/* .active-filter > span > b { margin-left:10px;} */


#filters-UI { padding-bottom:15px; text-align:left; background-color: #fce08e; margin-top:-5px; height:auto; /*position:sticky;*/top:80px;z-index:1000;}
#filters-UI > div {  width:700px; min-height:30px; overflow:hidden; /*white-space: nowrap; position:absolute; */ max-height:135px; height:auto;
   padding-bottom:0px; padding-top:25px;
}

#filters-UI > div:empty { min-height:0; padding:0 0 30px 0;}

.fond-logo + #filters-UI,
#filters-UI:first-child { margin-top:0;  }
.fond-logo + #filters-UI > div,
#filters-UI:first-child > div { }



#filters-UI #clear-filters { vertical-align:-2px; white-space: nowrap;}
#filters-UI #clear-filters > span { font-size:11px; font-weight:600;color:#4a4a4a; vertical-align: 8px; padding-right:5px;  }


#filters-UI > div { width:960px; margin-left: calc(960px - 700px); }
#filters-UI [data-simplebar] { height:100%; }
#filters-UI > div:empty { padding:35px 0 0 0; }


.SearchPane:not(.resultPage) #filters-UI { display:none; }

#suggestions { position:absolute; z-index:100001; width:100%;box-shadow: 0 -2px 4px rgba(187, 187, 187, 0.5),0 2px 4px rgba(187, 187, 187, 0.5);}

#menu-datatype > div:last-child { box-shadow: 0 -2px 4px rgba(187, 187, 187, 0.5), 0 2px 4px rgba(187, 187, 187, 0.5);}

.SearchPane .formControl { font-size:14px; font-weight:500; }

#menu-datatype .menu-dataT,
.SearchPane .formControl .menu-dataT {
   padding-right: 45px;
   position:relative;
}
#menu-datatype .menu-dataT .icone,
.SearchPane .formControl .menu-dataT .icone{
   width:35px;
   height:140%;
   background-repeat: no-repeat;
   background-size: auto 100%;;
   background-position: center;  
   position:absolute;
   right:0;
   top:-20%;
   opacity:0.8;
}

.SearchPane .formControl.scan .menu-dataT .icone, 
.SearchPane .formControl.instance .menu-dataT .icone, 
.SearchPane .formControl.etext .menu-dataT .icone,
#menu-datatype [data-value=Scan] .menu-dataT .icone, 
#menu-datatype [data-value=Instance] .menu-dataT .icone, 
#menu-datatype [data-value=Etext]  .menu-dataT .icone {
   height:100%; 
   top:0;
}


.SearchPane .formControl.lineage .menu-dataT .icone, 
#menu-datatype [data-value=Lineage] .menu-dataT .icone {
   height:120%; 
   top:-10%;
}

.SearchPane .formControl > div > div > div { padding-right:28px; }

#menu-datatype > div:last-child ul li[data-value="Scan"] { display:none; }

#menu-datatype > div:last-child ul li { justify-content: flex-end; }

#suggestions:not(.advanced) li { padding:0; background-color: white;}

#suggestions.advanced li { background-color: white;}

#menu-datatype > div:last-child ul li,
#suggestions li > span { padding:12px 16px; font-size:14px; color: #4c4c4c; font-weight:600; }

#suggestions li.active,
#menu-datatype > div:last-child ul li:hover,
#suggestions li:hover {  background-color:#f5f5f5; }

#suggestions .lang { text-transform: uppercase; color:#d73449; font-weight:700; font-size:10px; align-items:baseline; top:0px;}

#search-bar button { opacity:1;}

#suggestions li:hover  .lang,
#suggestions li.active  .lang { font-weight:800; }


#suggestions li:hover, 
#suggestions li.active { color:black; font-weight:700; }

#suggestions li .maxW { /*background:yellow; */ max-width:calc(100% - 250px); overflow: hidden; text-overflow: ellipsis; padding:10px 0; } 
#outline #suggestions li .maxW { max-width:calc(100% - 165px);  } 


#suggestions .spell-warn { display: flex ; align-items: center; margin-left:15px; opacity:0.65; font-weight:400; font-size:13px;}
#suggestions .spell-warn span { display: flex; align-items: center; margin-left:5px; flex-wrap:wrap; max-width:100%;}

/*
#suggestions { z-index:10000; } 
*/
/* --------------------------------------------------------------------------------------------------------------------- topbar */

.nav { width:100%; height:80px; position:fixed;top:0;left:0;background:white; display:flex;align-items: center; justify-content: center;
   box-shadow: 0 2px 4px rgba(187, 187, 187, 0.5); z-index:100010; 
}

.nav #back,.nav #back h2 span { 
   font-size: 14px;
   font-weight: 700;
   line-height: 20px;
   color: #152631;
}
.nav #back > * { vertical-align:middle;}
.nav #back {  max-width:calc(100% - 420px); display: flex;  align-items: center; justify-content: flex-end;}
.nav #back span { margin-left:auto; }
.nav #back > span:first-child { padding-right:10px;padding-top:2px; } 
.nav #back a { cursor: pointer; flex-shrink:0;}
.nav #back h2::before { content:' '; border-left:2px solid  #152631; margin:0 10px; height:12px;width:2px; display:inline-block; vertical-align:-1px; }
.nav #back h2 .T { display:none; }
.nav #back h2.on { font-size:18px;}
.nav #back h2.on, .nav #back h2 span { color:#d73449; }
.nav #back h2.on > *,.nav #back h2.on::before { vertical-align: middle;}
.nav #back h2 .lang { display:none; }

.nav #back > span:last-child { display:inline-block;   max-width:calc(100% - 100px); margin-left:0;   white-space: pre; overflow: hidden; text-overflow: ellipsis;  
   color:#d73449;
}

.nav.etext-nav #back > span:last-child { max-width:none; }
.nav.etext-nav #back > span:first-child > h2:before { content:none; }

.nav #back h2 {    overflow: hidden; margin:0; display:inline; } 
.nav #back h2 span { /*max-width:calc(75% - 70px);*/ display:inline;} 

.nav #back span + a { padding-top:4px;}

.over-nav > div,
#etext-nav > div,
.nav > div { width:1222px; display:flex;justify-content: space-between;align-items:center; max-width:100%;}

/* ------------------------ new UX ------------------------- */


.resource #outline:not(#a),
.resource [data-prop="tmp:containingOutline"] #outline:not(#a) {
   padding:0;
   margin-top:0px;
   width:100%;
}

.resource [data-prop="tmp:containingOutline"]:not(#a) {
   align-items: baseline;
}

.resource .data#outline > div {
   box-sizing: border-box;
}

.resource [data-prop="tmp:containingOutline"] #outline:not(#a) > h2 {
   display:none;
}

#root .App .SearchPane { margin-top:0; }

.infoPanel.inRes { margin-top:0 !important; }

.over-nav ~ div .resource > .index,
.over-nav ~ div .resource > div:last-child > .title,
.over-nav ~ div .resource > div:last-child > #head
{ display:none; }

.over-nav ~ div .resource > .index + div:not(#a) { 
   width:100%; margin-left:0;  
}

.over-nav + .nav.etext-nav { position:sticky; top:0; }

.over-nav + .nav.etext-nav .inner-nav,
.over-nav + .nav:not(.etext-nav) > div:not(#uiLangPopup) > :not(#logo, #lang, #lang-login, .inner-nav) { display:none; }
.over-nav + .nav .inner-nav { display: flex; }

#pop-login,
#lang-login { cursor:pointer; }

.over-nav + .nav .inner-nav > div { margin:0 25px; line-height:44px;height:44px; font-size:16px;}

.over-nav + .nav .inner-nav > div a { font-size:16px; color:black;}
.over-nav + .nav .inner-nav > div a:hover { text-decoration: underline;}

.over-nav + .nav .inner-nav > div a.active { font-weight:600; color:#d73449; }

body > #pop-login { z-index:1000000 ;}

#pop-login li { display: block; min-width:80px; text-transform: uppercase; color:#d73449; font-size:14px; font-weight:600; line-height:170%;}

.over-nav { display: flex; align-items: center; justify-content: center; height:50px; background:#172832; 
   position: relative; width:100% ; z-index:100001;
}

.over-nav .BDRC-link {
}

.over-nav .BDRC-link img {
   filter:invert(1);
   height:24px;
   vertical-align: -5px;
   margin-right:8px;
}

.over-nav > div a,
.over-nav > div { color:white; text-decoration: none; }

.over-nav > div a { 
   display: inline-block; margin-left:50px; line-height:44px;height:44px;
}

.over-nav + .nav { position: relative; }

#root > div > .infoPanel + .resource {
   width:1440px;
   box-sizing: border-box;
   padding-left: 80px;
   padding-right: 80px;
}

.resource #head + .data {
   padding-top:30px;
   padding-bottom:30px;
}

/*
.over-nav + .nav ~ .App { margin-top: 50px; }

#root > div > .infoPanel + .resource > .index {
   padding-top:110px;
}

#root > div > .infoPanel + .resource > .index + div > #head + .data {
   padding-top:160px;
}

*/

.nav #logo > a:first-child img:not(#a) {
   height:60px;
}

.infoPanel + .resource > .ariane { 
   padding-left:70px; 
   margin-left:110px;
   padding-right:70px;
   width:1080px;
}

.inner-search-bar:not(#a) {
   width:1440px;
   max-width:97%;
   height:120px;   
   box-sizing: border-box;
   margin:auto;
   display: flex;
   align-items: center;
   justify-content: center;
   z-index:100000;
   position: relative;
}

.SearchPane .inner-search-bar:not(#a) {
   max-width:100%;
}


#search-bar #simple-search {
   position: absolute;
   right: 0;
   top: 100%;
   font-size: 12px;
   margin-top: -6px;
   line-height: 44px;
   height: 44px;
   cursor: pointer;
   font-weight:700;
   z-index:10;
   color:#343434;
}


.inner-search-bar #simple-search {
   position: absolute;
   right: 0;
   top: calc(100% - 0px);
   left:0;
   display: flex;
   font-size: 0;
   z-index:102;
   justify-content: space-between;
}

.inner-search-bar #simple-search a  {
   font-size: 12px;
   margin-top: -6px;
   line-height: 44px;
   height: 44px;
   cursor: pointer;
   font-weight:700;
   color:#343434;
}


.inner-search-bar #simple-search a.regular {
   font-weight:400;
   cursor:auto;
}


.inner-search-bar #simple-search a .new {
   color:white;
   font-weight:600;
   background:#d73449;
   padding:1px 4px;
   margin-right:5px;
   font-size:10px;
}


.inner-search-bar #simple-search a:first-child + a {
   margin-left:auto;
   margin-right:30px;
}

.inner-search-bar:not(#a)::after {
   content:'';
   position: absolute;
   width:100%;
   height:100%;
   background:#fce08d;
   left:0;
   top:0;   
}

.inner-search-bar:not(#a) > div {
   width:calc(100% - 110px * 2);
   margin:auto;
   display: flex;
   align-items: baseline;
   padding:0 70px; 
   box-sizing: border-box;
}

.inner-search-bar:not(#a) > div > span:first-child {
   width:calc(110px + 70px + 10px);
   display: block;
   font-size:14px;
   font-weight:700;
   color:#343434;
   z-index:1;
}

.ais-SearchBox-loadingIndicator, .ais-SearchBox-reset {
   z-index:1; 
}

.home .inner-search-bar:not(#a) > div > span:first-child {
   visibility: hidden;
}


.inner-search-bar:not(#a) > div > span:last-child {
   width:calc(100% - (110px + 70px + 10px));
   display: block;
   position: relative;
}

.inner-search-bar:not(#a) > div > span:first-child { 
   visibility:hidden;
}

.AppSK.advanced #simple-search + div {
   width:630px !important;
}

.ais-SearchBox-form:not(#a),
.inner-search-bar:not(#a) > div > span:last-child { 
   width:calc(100% - (110px + 70px + 10px) - 190px - 70px + 80px);
   height:60px;
   box-sizing: border-box;
}

.fromAdvanced .ais-SearchBox-form:not(#a) {
   width:700px; 
   height:60px;
}


.resource [data-prop='tmp:outlineSearch'] .search input,
.ais-SearchBox-form input,
.inner-search-bar:not(#a) > div > span:last-child input {
   padding:18px 16px;
   display: block;
   width:100%;
   box-sizing: border-box;
   font-size:16px;
   position: relative;
   z-index:101;
   box-shadow: rgba(187, 187, 187, 0.5) 0px 2px 4px;
   border:none;
   border-radius: 4px;
   outline:none;   
}


.resource [data-prop='tmp:outline'],
.resource [data-prop='tmp:outlineSearch'] {
   align-items: baseline;
}

.resource [data-prop='tmp:outlineSearch'] .search  {
   position:relative;
}

.resource [data-prop='tmp:outlineSearch'] .search input {
   box-shadow: none;
   border-bottom:1px solid black;
   border-radius: 0;
}

.resource [data-prop='tmp:outlineSearch'] .search > div {
   width:calc(100% - 190px);
   position: relative;
}

.resource [data-prop='tmp:outlineSearch'] .search input,
.ais-SearchBox-form input {
   /* padding-left:48px;
   padding-right:48px; */
   line-height: 40px;
   padding: 8px 48px 8px 16px;
}


.resource [data-prop='tmp:outlineSearch'] .search input {
   padding-left:0;
}

.inner-search-bar:not(#a) > div > span:last-child input::placeholder {
   color:#aaa;
   font-weight:600;
}

.inner-search-bar:not(#a) > div > span:last-child input ~ a.go button,
.inner-search-bar:not(#a) > div > span:last-child input ~ button {
   position:absolute;
   right:0;
   top:0;
   width:44px;
   height: 44px;
   margin-top:7px;
   margin-right:2px;
   z-index: 1000;
   color:#aaa;
}


.inner-search-bar:not(#a) > div > span:last-child input ~ a:not(.go) {
   position:absolute;
   right:0;
   top:100%;
   text-decoration: none;
   font-size:12px;
   line-height: 24px;
   font-weight: 700;
   color:#343434;
   height:44px;
   line-height:44px;
   margin-top:-5px;
   z-index:1;
}

:not(#search-bar) + #filters-UI { 
   display:none;
}


/*
:not(#search-bar) + #filters-UI ~ #res-header > div > :not(#pagine) { 
   visibility: hidden;
}
*/

.resource > .ariane { margin-top:-10px; }

.resource > .ariane a span span {
   line-height:44px;
   height:44px;
}

.inner-search-bar .suggestions {
   background:white;
   box-shadow:0 2px 3px 1px rgba(0,0,0,0.25);
   position:absolute;
   z-index:103;
   max-width:100%;
   overflow: hidden;
   box-sizing: border-box;   
   text-align: left;
}

.inner-search-bar .suggestions > li,
.inner-search-bar .suggestions > span {
   line-height:44px;
   height:44px;
   display:block;
   padding:0 10px;   
   position: relative;
}

.inner-search-bar .suggestions > li,
.inner-search-bar .suggestions > span a {
   text-decoration: none;
   color:#343434;
   display: flex;
   white-space: nowrap;
}

#suggestions .search-result-item-lang,
.inner-search-bar .search-result-item-lang {
   display:none;
}


#suggestions suggested,
.inner-search-bar  suggested {
   font-weight:700;
   display: inline;
}

ul.suggestions .fromHisto > span > svg:first-child {
   font-size:16px;
   margin-right:12px;
   margin-left:2px;
}

ul.suggestions li > span > svg:first-child {
   font-size:22px;
   margin-right:8px;
}

ul.suggestions .fromHisto svg ~ span {
   color:#52188c !important;
}

ul.suggestions li {
   align-items: center;
   position: relative;
}

ul.suggestions li > span:first-child {
   width:100%;
   align-items: center;
   display: flex;
}

ul.suggestions li .search-result-item-res {
   max-width:calc(100% - 64px);
   text-overflow: ellipsis;
   overflow: hidden;
   flex-grow:1000;
}

ul.suggestions li > span:first-child + * {
   position:absolute;
   right:0;
   top:-2px;
}

ul.suggestions li > span > svg:first-child {
   opacity:0.5;
}


#outline .suggestions > li {
   position: relative;
}

#outline .suggestions > li.selected,
.inner-search-bar .suggestions > li.selected,
.inner-search-bar .suggestions > li:hover,
.inner-search-bar .suggestions > span:hover {
   background-color:rgba(0,0,0,0.05);
}

.inner-search-bar .suggest-bg {
   width:100%;
   height:100%;
   position:fixed;
   top:0;
   left:0;
   background:rgba(0,0,0,0.35);
   pointer-events:none;
}


.inner-search-bar .suggestions .suggest-str {
   white-space:nowrap;

   max-width:calc(100% - 0px); 
   text-overflow: ellipsis;
   overflow:hidden;
} 

.inner-search-bar .suggestions .suggest-in {
   font-style:italic;
   color:#888;
   margin: 0 5px;
}


.inner-search-bar .suggestions .suggest-types {
   color:#888;
   font-weight:600;
}

.inner-search-bar .suggestions .suggest-types img { 
   height:23px; vertical-align: middle; opacity:0.65; margin-left:5px;
}

/*

.H100vh .infoPanel + .resource > .inner-search-bar:not(#a),
.H100vh .over-nav,
.H100vh .over-nav + .nav { z-index:1000; }

*/
@media (max-width: 1320px) {
   .nav:not(#a), .over-nav {
      padding-left: 1.5%;
      padding-right: 1.5%;
      max-width: 100%;
      box-sizing: border-box;
   }
/*

   .nav #logo { width:210px; position: relative;}

   .nav #logo:not(#a),
   .nav #logo > a:first-child { display:block; }

   .nav #logo > a:first-child span:not(#a) { padding-top:3px; font-weight: 500;}
   
   .nav #logo > a:first-child img { float:left; vertical-align: baseline;}

   .nav #logo > a:last-child img { position:absolute; right:0; top:10px; }

   .nav #logo #by { margin-right:5px; }

   .nav #logo #by + a span:not(#a) { font-weight:500; font-size:18px; }
*/
   .over-nav + .nav .inner-nav > div { line-height:normal; height:auto; max-width:80px; text-align: center; }
}


.App.tradition #samples > div {
   font-size:0;
}



.App.tradition .tradi-content {
   margin-right:35px;
}

.App.tradition h1{ 
   font-size:32px;
}


.App.tradition .tradi-content#tradi-links {
   /* padding-left:30px; */
   box-sizing: border-box;
}

.App.tradition .tradi-content#tradi-links,
.App.tradition .tradi-content#tradi-all {
   /* width:calc(50% - 35px); */
   width:100%;
}


.App.tradition .tradi-content.listing,
.App.tradition .tradi-content.main,
.App.tradition .tradi-content#tradi-selected,
.App.tradition .tradi-content#tradi-recent {
   width:100%;
}

.App.tradition .tradi-content > a {
   font-size:16px;
   margin-left:20px;
}

.App.tradition .tradi-content.listing > a {
   margin-left:0;
}


.App.tradition .tradi-content.listing > h5 ~ a {
   margin-left:20px;
}

.App.tradition .tradi-content.listing [lang='en'] {
   text-transform: capitalize;
}


.App.tradition .tradi-content h5 {
   margin:0;
   line-height:36px;
   font-weight:600;
}

.App.tradition .tradi-content {
   display:flex;
   flex-wrap: wrap;
   margin-bottom:35px;
   width:100%;
}

.App.tradition .tradi-content.display-block {
   display:block;
}
.App.tradition .tradi-content.display-block a {
   display: block;
   line-height:36px;
}

.App.tradition .tradi-content.display-block h2 {
   margin-bottom:5px;
}

.App.tradition .tradi-content h2{ 
   font-size:22px;
   width:100%;
}


.App.tradition .tradi-content .hidden {
   display:none;
}

.App.tradition .tradi-content .disabled {
   opacity:0.5;
   pointer-events: none;
   display: none !important;
}

.App.tradition .tradi-content a.has-img {
   margin-right:5px;
}

.App.tradition .tradi-content a.has-img img {
   display: block;
   margin: auto;
}


.App.tradition .tradi-content#tradi-all a.has-img img {
   width:140px;   
}



.App.tradition .tradi-content#tradi-all a.has-img span {
   font-size:18px;
   color:#333;
   font-weight:600;
   display: inline-block;
   width:100%;
   background-color:#fce08d;
   text-align: center;
   line-height:44px;
}

.App.tradition .tradi-content:not(#tradi-all) a.has-img span {
   width:75px;
   max-width:100%;
   line-height: 22px;
   text-align: center;
   display: flex;
   align-items: center;
   justify-content: center;
   margin-bottom:22px;
}


.App.tradition .tradi-content:not(#tradi-all) a.has-img:last-child span { 
   margin-bottom:0;
}

.App.tradition .tradi-content:not(#tradi-all) a.has-img span[lang="bo"] {
   line-height:28px;
}

.App.tradition .tradi-content.main:not(#a) a.has-img {
   margin-right:10px;
   text-align: center;
}


.App.tradition #tradi-selected a.has-img span:not(#a),
.App.tradition .tradi-content.main:not(#a) a.has-img span {
   width:130px;
}


.App.tradition #tradi-selected a.has-img img,
.App.tradition .tradi-content.main:not(#a) a.has-img img {
   width:75px;
   height:75px;
   object-fit: cover;
   display:block;
}

.App.tradition #tradi-selected a.has-img img,
.App.tradition .tradi-content.main:not(#a) a.has-img img {
   margin-bottom:5px;
}



.App.tradition #tradition-breadcrumbs {
   font-size:14px;
   margin-bottom:25px;
}


.etext-header-breadcrumbs .ariane a ~ span,
.etext-header-breadcrumbs .ariane a,
.etext-header .ariane a,
.etext-header .ariane a ~ span,
.infoPanel + .resource > .ariane a ~ span,
.infoPanel + .resource > .ariane a,
.App.tradition #tradition-breadcrumbs a {
   color:#343434;
   text-decoration: underline;
   /* text-transform: capitalize;    */
   font-weight:400;
   font-size:14px;

   white-space: nowrap; 
   overflow: hidden; 
   text-overflow: ellipsis; 

   flex: 0 0 auto;
   flex-shrink:0; 
}

.ariane .can-shrink:not(#a) {
   flex-shrink:0;
   max-width:65%;
}

.ariane .can-shrink:not(#a) ~ .can-shrink {
   flex-shrink:0;
}

.ariane span.can-shrink:not(#a):last-of-type,
.ariane .can-shrink:not(#a) ~ .can-shrink ~ .can-shrink {
   flex-shrink:1;
}

/*
.ariane[data-level="3"] > * {
   max-width:33%;
}

.ariane:not([data-level]) > span > * {
   max-width:70%;
}
*/


.etext-header-breadcrumbs .ariane,
.infoPanel + .resource > .ariane {
   line-height: 22px;
   display: flex;
   justify-content: flex-start;
}

.etext-header-breadcrumbs .ariane a ~ span,
.etext-header .ariane a ~ span,
.infoPanel + .resource > .ariane a ~ span {
   text-decoration: none;
}

.App.tradition #tradition-breadcrumbs span {
   color:#343434;
}


.etext-header-breadcrumbs .ariane > :not(:first-child)::before,
.etext-header .ariane  a:not(:first-child)::before,
.infoPanel + .resource > .ariane a:not(:first-child)::before,
.infoPanel + .resource > .ariane span:not(:first-child)::before,
.App.tradition #tradition-breadcrumbs a:not(:first-child)::before,
.App.tradition #tradition-breadcrumbs span:not(:first-child)::before {
   content:">";
   display: inline-block;
   margin:0 10px;
   pointer-events: none;
}


.App.tradition .tradi-content #latest {
   padding:0; 
   margin-top:0px;
}

.App.tradition .tradi-content #latest h3 {
   display:none;
}

.App.tradition .tradi-content #latest .seeAll {
   margin-top:-115px;
   /* border-color:#d73449; */
   margin-right: 0;
   text-decoration: none;
   color:#343434;
   font-size:16px;
}

.App.tradition .tradi-content #latest .seeAll:hover {
   color:#d73449;
}

.App.tradition .tradi-content #latest > div {
   margin-top:0;
}

#latest > div {
   font-size:0;
}

.App.tradition .tradi-content #latest > div:first-of-type {
   background-color:#fce08d;
   padding:45px 30px;
   min-height:300px;
}

.App.tradition .tradi-content #latest > div:first-of-type.no-result {
   min-height:0;
}

.App.tradition .tradi-content #latest > div:first-of-type.no-result .slide-bg > div {
   font-size:16px; 
   width:100%;
   opacity:0.65;
}

.App.tradition .tradi-content #latest #syncs-nav {
   margin-top:20px;
   text-align: center;
}

.App.tradition .tradi-content #latest .slide-bg > div p + a {
   text-decoration: none;
}

.App.tradition .tradi-content #latest #syncs-nav span {
   margin:0 90px;
}

#latest .date {
   font-size:13px;
   opacity:0.65;
   float:right;
}

#latest .recent-selector {
   display:none; 
}

#latest #syncs-nav.disabled {
   pointer-events: none;
}

#latest #syncs-nav.disabled .on {
   opacity:0.35;
}

.App.tradition .tradi-content #latest .recent-selector {
   display:block; 
   position:absolute;
   bottom:6px;
   left:calc(50% - 80px);
   width:160px;
   text-align: center;
}

.App.tradition .tradi-content #latest :not(#syncs-nav) + .recent-selector {
   bottom:-50px;
}

#res-container .recent-selector > div::after,
.App.tradition .tradi-content #latest .recent-selector > div::after{
   border-bottom-color:#343434;
}

@media(min-width:1320px) {

   .App.tradition .tradi-content #latest .slide-bg > div {
      width:165px;
   }

   .App.tradition .tradi-content #latest .slide-bg > div .header {
      height:165px;
   }
}





/* ----------------------------- end new-UX/header ---------------------------- */

.resource [data-prop] .data.access:not(#a) {
   padding:0;
   background:none;
}

.resource [data-prop] .data.access h3 {
   font-size: 14px;
   text-align: left;
   padding: 0;
   margin-bottom:10px;
}

.resource [data-prop] .data.access h3::before {
   width:20px;
   height:30px;
   background-position: center;
   margin-right:10px;
}

.resource [data-prop] .data.access h3::before {
   content:none;
}

.resource [data-prop] .data.access h3 {
   max-width:none;
}

.resource [data-prop] .data.access h3 span {
   padding:0; 
   font-weight:400;
}

.resource [data-prop] .data.access h3 span u {
   text-decoration: none;
}

.resource [data-prop] .data.access .fairuse-IA-link + .fairuse-IA-link {
   vertical-align: top;
   cursor:pointer;
   position: relative;
   margin-left:28px;
}

.resource.etext-outline {
   position:sticky;
   left:0;
   top:120px;
   width:400px;
   /* height:100vh; */
   height:calc(100vh - 120px);
   margin-left:0;
   overflow: auto;
   z-index:1000;
   box-shadow: 2px 0 3px rgba(0,0,0,0.15);
   background:white;
}


.resource.etext-outline a {
   cursor:pointer;
}

.resource.etext-outline .data#outline .root + div {
   width: calc(100% - 25px - 50px - 35px + 30px);
}

.resource.etext-outline .data#outline .root + div .top.on + .details .sub > .ulink:first-child + span,
.resource.etext-outline .data#outline .root + div .top.on + .details .sub > .ulink:first-child {
   display:none;
}


.resource.etext-outline  .data#outline .top.is-root .ulink a,
.resource.etext-outline  .data#outline .on h2 span,
.resource.etext-outline  .data#outline .on > span:not(.pType) span { font-weight:700 !important;  }


.resource.etext-outline+* {
   display: flex;
   justify-content: flex-end;
}

.resource.etext-outline.withOutline-true+* {
   margin-top:calc(-100vh + 120px);
}
.resource.etext-outline  .etextrefs:not(#a) {
   margin-left:0;
   box-sizing: border-box;
   width:100%;
}

.resource.etext-outline  .etextrefs:not(#a)  {
   height:100%;
}

.resource.etext-outline .data#outline .parTy.on + span a:not(#a):hover,
.resource.etext-outline .data#outline .parTy.on + span a {
   color:#d73449;
}


.resource.etext-outline .data#outline .parTy.on + span + .abs img:not(#a) {
   filter:none;
}


.resource.etext-outline .data#outline .parTy.on + span + .abs img:first-child:not(:last-child) {
   display:none;
}

.resource.etext-outline .data#outline .parTy.on + span + .abs img:last-child:not(:first-child) {
   display:inline;
}
/*
.resource ~ .ETSBresults > div:not(#a) {
   white-space: pre;
}
*/
.resource ~ .ETSBresults > div:not(#a),
.resource ~ .monlamResults > div:not(#a) {
   position: sticky;
   top: 120px;
   height: calc(100vh - 120px);
}

#root > div > .resource.etext-view:not(#a) {
   width: calc(100% - 400px);
   box-sizing: border-box;
   margin: 0;
   padding:10px;
}

.resource .data.etextrefs#outline:not(#a) {
   padding:10px;
}

.resource .data.etextrefs#outline > div:not(#a) {
   padding:15px 15px 10px 10px;
}

#root > .withETSBresults.withOutline > .resource.etext-view:not(#a),
#root > .withMonlam.withOutline > .resource.etext-view:not(#a) {

   width: calc(100% - 400px - 400px);

}

#root > .withOutline:not(.withMonlam,.withETSBresults) > .resource.etext-view:not(#a),
#root > .withMonlam:not(.withOutline) > .resource.etext-view:not(#a) {

   width: calc(100% - 400px);

}

#root > :not(.withMonlam,.withOutline,.withETSBresults) > .resource.etext-view:not(#a) {
   width: 1440px;
   max-width: 97%;
   margin: auto;
}

.resource.etext-outline .simplebar-content {
   height:100%;
}

.resource.etext-outline.withOutline-false {
   display:none;
   position: relative; 
}

.etext-viewer-loader {
   z-index:1000000;
}

button.close-etext-results,
button.close-etext-outline {
   z-index:1;
   position:absolute;
   top:1px;
   right:1px;
   cursor: pointer;
}

.show-outline-etext:not(#a) {
   position:sticky;
   background:white;
   width:40px;
   height:40px;
   box-shadow: 0 2px 4px rgba(187, 187, 187, 0.5);
   top:140px;
   left:15px;
   margin-bottom:-60px;
   z-index:10;
   margin-top:20px;
}

.download-etext-confirmation {
   position:fixed;
   left:0;
   right:0;
   top:0;
   bottom:0;
   z-index:1000000;
}

.download-etext-confirmation > div {
   width:100%;
   height:100%;
   position: absolute;
   display: flex;
   justify-content: center;
   align-items: center;
}

.download-etext-confirmation > div:first-child {
   background:rgba(0,0,0,0.25)
}

.download-etext-confirmation > div:last-child > div {
   background:white;
   padding:30px;
   font-size:15px;
   position: relative;
   box-shadow: 0 0 3px 3px rgba(0,0,0,0.175);
}

.download-etext-confirmation > div:last-child > div .close::before {
   content:"+";
   font-size:30px;
   font-weight:500;
   transform: rotate(45deg);
   position: relative;
   display: block;
   margin-top:-5px;
}

.download-etext-confirmation > div:last-child > div .close {
   position: absolute;
   right:0;
   top:0;
   cursor:pointer;
}

.download-etext-confirmation > div:last-child > div a {
   background:#d73449;
   color:white;
   text-decoration: none;
   padding:10px;
   font-weight: 600;
   border-radius:4px;
}

.stndz-popup-notification {
   z-index:1000000 !important;
}

/* ----------------------------- end new-UX/etext ---------------------------- */


[data-prop="tmp:propHasScans"] h4:not(#a) {
   display:flex;
   flex-direction: column;
}

[data-prop="tmp:propHasScans"] h4:not(#a) + h4 {
   margin-top:20px;
}

[data-prop="tmp:propHasScans"] h4:not(#a) > a:not(.noIA) {
   order:1;
}

[data-prop="tmp:propHasScans"] h4:not(#a) > a {
   display: inline-block;
   overflow:hidden;
}
[data-prop="tmp:propHasScans"] h4:not(#a) > div {
   order:0;
   margin-left:0;
   margin-bottom:5px;
}

[data-prop="tmp:propHasScans"] h4:not(#a) .images-thumb {
   width:auto; 
   background-image:none !important;
   background:none;   
   height:auto;
   border:none;
   line-height: 0;   
   /* display: inline-block; */   
   display:inline-flex;
   justify-content: center;
   max-width:100%;
}

[data-prop="bdo:workHasInstance"] h4:not(#a) .images-thumb img { 
   display:none;
}

[data-prop="tmp:propHasScans"] h4:not(#a) a.error-401 {
   display:none;
}


[data-prop="tmp:propHasEtext"] .etext-top-links > a,
[data-prop="tmp:propHasScans"] .images-thumb-links > .urilink {
   font-weight:600;
}

/* ----------------------------- end new-UX/instance ---------------------------- */

.nav > div > div > a > img { vertical-align: middle;}

.nav #logo { display:flex; align-items:center;}

.nav #logo a { display: inline-block; margin-right:15px; }
.nav #logo a#BDRC span,
.nav #logo a:last-child span,
.nav #logo a:first-child span { font-size:32px; /*line-height:26px;*/ font-weight:400; line-height:36px;}

.nav #logo a:first-child span + .buda-small { font-size:11px; text-transform: none; line-height:12px; padding-left:2px;}

.nav #logo a:first-child .buda-caption { display:inline-flex; flex-direction: column; }

.nav #logo a#BDRC + a img { height:40px;}

.nav #logo.khmer > a:first-child img { width:65px; height:auto; margin-right:0; }

.nav #logo > a:first-child img { height:65px; display: inline-block; margin-right:10px;}
.nav #logo > a:first-child * { vertical-align: middle;}

.nav a { text-decoration: none; font-size:0; display:inline-block; }

#more-pages-menu a,
.nav #logo.khmer + .links a,
footer #donate,
.nav a#about,
.nav a#collections,
.nav a > span,
.nav #donate, 
.nav #login { font-weight:700; font-size:14px;letter-spacing: 0.29px; text-transform: uppercase; text-decoration: none; }

.nav #guide { margin-left:25px;}

.nav a#collections { margin-left:25px; }

.nav #logo.khmer + .links  { margin-left:15px; }

.nav #logo.khmer + .links a { color:#192229; margin:0 20px; }

.nav #login > div { display: flex; align-items: center; }
.nav #logo.khmer ~ #lang { margin-left:35px;}
.nav #logo.khmer ~ #login > div.logged { flex-direction: column; align-items: center; }
.nav #logo.khmer ~ #login > div.logged > span:first-child { margin-bottom:10px; }
.nav #logo.khmer ~ #login > div.logged > span { margin-left: 0;}


.nav #login > div > span:first-child { max-width:150px ; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;}


.nav #login.proxied > * { opacity:0.5; pointer-events:none; }
.nav #login.proxied > div > :first-child { display: none;}

.nav #logo #by span { padding-top:7px; text-transform:none; letter-spacing: 1px; font-weight:600;}

#more-pages-menu a,
.nav a#collections,
.nav a#about { color: #192229;} 

#login { top:0;right:0;z-index:1; display:inline-block; margin-left:auto;  color:#d73449; }
#login button {color:rgba(0,0,0,0.54); }

.nav #logo:not(.khmer)  + a { margin-left:52px; margin-right:25px; }
/* .nav #about  { margin-left:105px; margin-right:105px;  } */

.nav a > span { color:#192229; display:inline-block;   }

/* #login span { display:inline-block; margin-left:25px; cursor:pointer;  */
   /*pointer-events:none; opacity:0.65;*/
/* } */

footer #donate,
.nav #donate { width:150px;height:50px;border-radius: 4px; color:white;background-color: #d73449; line-height:50px; 
   text-align:center; display:inline-block;  margin-left:25px; }
.nav #donate img { vertical-align: middle; display: inline-block; margin-right:10px;}

.nav #lang { cursor:pointer; /*margin-top:7px;*/ margin-left:25px; display: inline-block;}
.nav #lang img { display: inline-block; width:28px; } 

/* .resource .title + .data + .data .browse { display:none; } */
.nav .history { margin-left:35px; display:flex;}
.nav .history span { background-color:#efedee; width:40px;height:40px;border-radius:20px; display:inline-flex;justify-content: center;margin-left:15px;
   padding-left:2px; box-sizing: border-box; 
}
.nav .history span img { opacity:0.5; }
.nav .history {visibility:hidden; display:none; }

/* ---------------------------------------------------------------------------------------------------------------------- Cambodia website */

.App.khmerServer #filters-UI #clear-filters,
.App.khmerServer #filters-UI #clear-filters > span { color:white; }

.App.khmerServer .searchWidget.datatype:not(.instance,.work,.scan) { display:none; }

.App.khmerServer #filters-UI,
.App.khmerServer #search-bar  { background-image: linear-gradient(270deg, #0c5f89 0%, #9ccab4 100%, #a5d0b6 100%); }
.App.home.khmerServer #search-bar { padding: 55px 0;  background-image: linear-gradient(249deg, #0c5f89 0%, #9ccab4 100%, #a5d0b6 100%); }
.App.home.khmerServer #search-bar > div:first-child { width:400px; } 
.App.home.khmerServer #search-bar > div:first-child > div:first-child >  div:first-child { margin-right:0px; width:100%;}
.App.home.khmerServer #search-bar button:first-of-type { margin-right:-48px; }
.App.home.khmerServer #search-bar button:first-of-type svg { color: #d73449 !important; opacity:1 !important; }

.App.home.khmerServer #search-bar .links { padding-right: 50px; display:inline-flex; align-items: stretch;}
.App.home.khmerServer #search-bar .links a { margin: 0 45px; display: inline-block; padding-bottom:5px; }
.App.home.khmerServer #search-bar .links > span { position: relative; display: inline-flex;}
.App.home.khmerServer #search-bar .links > span:first-child::after { content:''; position:absolute; display: block; right:0; top:0px; height:20px;width:1px; background:white;}  
.App.home.khmerServer #search-bar .links > span:first-child a { overflow:hidden; position: relative;  }
.App.home.khmerServer #search-bar .links > span:first-child a::before { content:''; display:block; width:110%; height:100%;box-shadow:inset 0 -1.5px 1px white; position: absolute;left:-5%;}
.App.home.khmerServer #search-bar .links a { color:white; font-size:14px; text-transform: uppercase; font-weight:700;}

.App.home.khmerServer .SearchPane #logo > div { justify-content: flex-start; padding-left:calc(135px + (100% - 1440px) / 2); box-sizing: border-box;}
.App.home.khmerServer .SearchPane #logo > div > div { padding:50px; height:auto; }
.App.home.khmer.static h1,
.App.home.khmerServer .SearchPane #logo > div > div h1 { font-family:"Noto Sans", "Noto Serif Tibetan", "Noto Serif Khmer", "Noto Sans Mongolian", "Roboto", "Helvetica", "Arial", sans-serif !important; font-weight:900; font-size:46px; line-height:53px;text-align:left;} 
.App.home.khmerServer .SearchPane #logo > div > div p { text-align: left; font-size:16px;margin-bottom: 0;}

.App.home.khmerServer #data-checkbox { display:none; }

.App.home.khmerServer section#learn { scroll-margin: 80px;}

.App.home.khmerServer section.white-bg + .white-bg { padding-top:0 ;}
.App.home:is(.khmerServer,.static.khmer) section.white-bg { background: white; }
.App.home.khmerServer section p:last-child { margin-bottom: 0; } 
.App.home.khmerServer section > div { width: 1222px; max-width:100%; margin:0 auto; text-align: left; padding-left:80px; box-sizing: border-box; position: relative;}
.App.home.khmerServer section { padding:80px 0 80px 0; clear:both; }

.App.home.khmerServer section h2 { margin-top:0;  /*width:635px; */ font-size:34px; font-weight:700; color:#152631; margin-bottom:0;}
.App.home.khmerServer section h2::after { content:''; border-bottom: 2px solid #e9665c; height:0px; width: 95px; display: block; margin-top:40px;margin-bottom: 25px; }
.App.home.khmerServer section p { width:570px; font-size:16px; color:#4a4a4a; } 
.App.home:is(.khmerServer,.static.khmer) section .note { color:#d73449; }
.App.home:is(.khmerServer,.static.khmer) section .noteText:first-child { margin-top:120px; }
.App.home:is(.khmerServer,.static.khmer) section .noteText { display:block; font-size:12px; vertical-align: baseline; color:#4a4a4a;}
.App.home:is(.khmerServer,.static.khmer) section .noteText .note { font-size:16px; padding-right:10px; }

.App.home:is(.khmerServer,.static.khmer) section .khmer-link { color:#d73449; font-weight:600;}

.App.home.khmerServer section .float { float:right; text-align: center; padding-bottom:50px;}
.App.home.khmerServer section .float img { width:485px;height:485px; object-position: center; object-fit: none; border-radius:50%; margin-top:-65px; }
.App.home.khmerServer section .float p { width: 180px ; margin:0 auto; display:block; text-align: center; font-style: italic; font-size:12px;margin-top:40px;}

.App.home.khmerServer .fond-logo { position: relative;}
.App.home.khmerServer .fond-logo .abs { display:none; }
.App.home.khmerServer .fond-logo .link { color:#d73449; display: inline-block;font-weight:600;cursor: pointer; font-size:13px;}

@media(max-height:860px) {

   .App.home.khmerServer .fond-logo .abs { position: absolute; bottom:8px; animation: move 1.35s ease-in-out 0s infinite; display: block;}
   .App.home.khmerServer .fond-logo .abs svg { color:white; font-size:64px;  }
   
   @keyframes move {
      0% { bottom:0px; }
      50% { bottom:8px; } 
      100% { bottom:0px; }
   }
}

.App.home.khmerServer section .abs { padding:0; position:relative; }
.App.home.khmerServer section .abs div { position:absolute; text-align: center; width:100%; box-sizing: border-box; margin-top:-30px; padding-right:80px;}
.App.home.khmer.static section.orange-gradient > div > div > div a,
.App.home.khmerServer section .abs div a { text-transform:uppercase; font-size:14px; overflow: hidden; color:#111312; padding-bottom:5px; position: relative; display: inline-block;}

.App.home.khmerServer section .imgs { padding-top:70px; }
.App.home.khmerServer section .imgs img { vertical-align: middle; display: inline-block; margin-right:40px;}

.nav #logo.khmer + .links a { position: relative; overflow: hidden; margin-top:10px; padding-bottom:5px;}

.nav #logo.khmer + .links a.current::before,
.App.home.khmer.static section.orange-gradient > div > div > div a::before,
.App.home.khmerServer section .abs div a::before { content:''; display:block; width:110%; height:100%;box-shadow:inset 0 -1.5px 1px #152631; position: absolute;left:-5%;}

.App.home.khmer.static header { display:flex; justify-content: space-between; align-items: stretch;}
.App.home.khmer.static header > * { width:50%; }
.App.home.khmer.static header img + div { padding-left:65px; display: flex; flex-direction: column; justify-content: center;}
.App.home.khmer.static header img + div > * { width:445px; max-width:100%; }
.App.home.khmer.static header img + div p { color:#4a4a4a; font-size:16px; line-height: 28px;text-align: left; }

.App.home.khmer.static section :is(h2,p) { margin:0; }
.App.home.khmer.static section img { max-width:100%;}

.App.home.khmer.static section.orange-gradient { background-image: linear-gradient(258deg, #e9665c 0%, #ffd965 100%); justify-content: center; }
.App.home.khmer.static section.orange-gradient > div { justify-content: flex-end;}
.App.home.khmer.static section.orange-gradient > div > div { width:500px; justify-content: flex-start;}
.App.home.khmer.static section.orange-gradient > div > div { font-size:19px; color:white; display:flex;align-items: stretch;}
.App.home.khmer.static section.orange-gradient > div > div > img { width:155px; }
.App.home.khmer.static section.orange-gradient > div > div > div { display:flex;flex-direction: column; justify-content: space-between; padding:5px 0 5px 30px;}
.App.home.khmer.static section.orange-gradient > div > div > div span { letter-spacing: 1px;}
.App.home.khmer.static section.orange-gradient > div > div > div a { color:white; align-self: flex-start; font-size: 12px; font-weight: 700; letter-spacing:1px;}
.App.home.khmer.static section.orange-gradient > div > div > div a::before { box-shadow:inset 0 -1.5px 1px white; }

.App.home.khmer.static section > div { width:1222px; padding:85px 0px 85px 80px; box-sizing: border-box; margin:auto; text-align: left; display:flex; align-items: stretch; font-size:0; 
   justify-content: space-between; max-width:100%;
}
.App.home.khmer.static section > div .baseline { display:flex; align-items: baseline;}
.App.home.khmer.static section > div .col { display: flex; flex-direction: column; justify-content: space-between; width:475px; max-width:100%; padding-right:45px; }
.App.home.khmer.static section > div .col + .col { padding-right:0; padding-left:45px; }
.App.home.khmer.static section > div h2 { font-size:22px; width: 200px; color:#152631; box-sizing: border-box; padding-right:25px;}
.App.home.khmer.static section > div h2.small { font-size:15px; }
.App.home.khmer.static section > div h2:not(.small) { line-height:24px;margin-top:-3px; }
.App.home.khmer.static section > div p { font-size:15px; line-height:24px; color:#4a4a4a; letter-spacing: 0.75px;}
.App.home.khmer.static section:first-of-type > div p { font-size:18px; line-height:28px;}

.App.home.khmer.static section > div .note { font-style: normal; font-weight: 700;}

.App.home.khmer.static section > div .col p + p { margin-top:20px; }

.App.home.khmer.static section > div .col .img-caption:not(:first-child) { margin-top:60px; }
.App.home.khmer.static section > div .col .img-caption p { text-align: right; font-style: italic; font-size:12px; margin-top:40px; line-height:20px;}

.App.home.khmer.static section > div .col .img-caption.border img { width:80%; margin-left:10%;}
.App.home.khmer.static section > div .col .img-caption.border > span { padding:24px 0; background:#efefef;display:block;}
.App.home.khmer.static section:not(.white-bg) > div .col .img-caption.border > span { background:white;}

.App.home.khmer.static section > div .col2 { column-count:2;column-gap:90px; }
.App.home.khmer.static section > div .col2 h3 { font-size:15px; margin-top:0; line-height:24px; margin-bottom: 24px;}

.App.home.khmer.static section > div .col2 p + h3,
.App.home.khmer.static section > div .col2 p + p { margin-top:24px;}

.App.home.khmer.static section.footnote > div { padding: 50px 0 50px 80px; }
.App.home.khmer.static section.footnote > div > div { width:calc((100% - 80px) /3); }
.App.home.khmer.static section.footnote .noteText { margin-top: 0; }
.App.home.khmer.static section.footnote .noteText + .noteText { margin-top:10px; }
.App.home.khmer.static section.footnote .noteText .note { width:15px; display: inline-block;}

.App.home.khmer.static section > div h2 { flex-shrink:0; }
@media(max-width:1280px) {
   .App.home.khmerServer .SearchPane #logo > div { padding-left:50px;  }

   .nav #donate { width:60px; color:transparent;padding-left:10px;}
   .nav .khmer #BDRC span { display:none; }

   .nav #logo.khmer + .links a { margin:10px 10px 0 15px; }

   .nav #logo.khmer > a:first-child { margin-right:0; }

   
   #etext-nav { padding:0 24px; box-sizing: border-box;}
}

.App.guidedsearch.khmer button.red { cursor:pointer; background:#d73449; color:white; border:none; text-transform: uppercase; font-size:12px; font-weight: 800; padding:7px 12px; border-radius: 2px;} 

.App.guidedsearch.khmer .static-container.sticky { display: block; text-align:left; padding-bottom:80px;}

.App.guidedsearch.khmer .static-container.sticky > div {
   width:calc(1440px - (1440px - 1222px) - 150px - 80px - 35px);
   margin-left:calc((1440px - 1222px) / 2 + 80px);
   text-align: left;
   padding-left:35px;
   box-sizing: border-box;
   padding-right:35px;
}

.App.guidedsearch.khmer .static-container.sticky > div:first-child {
   position:sticky;
   padding-top:0px;
   top:80px;
   background:#efefef;
   padding-bottom:55px;
   z-index:100;
   transition:all 100ms ease-in-out;
}

.App.guidedsearch.khmer .static-container.sticky > div:first-child::after {
   content:""; height:15px; width:100%; margin-left:-35px; display: block; background:white; bottom:0px;position: absolute;
}

.App.guidedsearch.khmer .static-container.sticky > div:first-child {
   padding-bottom:35px;
}


.App.guidedsearch.khmer .static-container.sticky > div:first-child h1 { padding-top:80px; font-size:34px; margin:0;
   transition:all 100ms ease-in-out;
}
.App.guidedsearch.khmer .static-container.sticky > div:first-child h1 { padding-top:20px; }

.App.guidedsearch.khmer .static-container.sticky h2 { font-size:16px; color:#343434; text-transform: uppercase; margin:0; position: relative;}

.App.guidedsearch.khmer .static-container.sticky .selector { padding-bottom:45px; scroll-margin-top: 200px;}
.App.guidedsearch.khmer .static-container.sticky .selector h2 > * { visibility:hidden; }
.App.guidedsearch.khmer .static-container.sticky .selector h2 > img { width:22px; vertical-align: -6px;/*position: absolute; right:0;*/ margin-left:20px;}
.App.guidedsearch.khmer .static-container.sticky .selector h2 > span { font-weight: 400; font-size:10px; vertical-align: 2px; text-transform: none; padding-left:20px;}
.App.guidedsearch.khmer .static-container.sticky .selector:hover h2 > * { visibility: visible;}

.App.guidedsearch.khmer .static-container.sticky > div:first-child .selector {padding-bottom:0; padding-top:40px;  position: relative; display: flex;
   transition:all 100ms ease-in-out; align-items: center;
}
.App.guidedsearch.khmer .static-container.sticky > div:first-child .selector { padding-top:0px;  }

.App.guidedsearch.khmer .static-container.sticky > div:first-child .selector [data-prop] { position: absolute; right:0;  top:-50px; width:100%; text-align: right; 
   pointer-events:none;
}
.App.guidedsearch.khmer .static-container.sticky > div:first-child .selector [data-prop] > * { pointer-events: auto;}

/* .App.guidedsearch.khmer.scrolled .static-container.sticky > div:first-child .selector [data-prop] { top:-60px; } */

.App.guidedsearch.khmer .static-container.sticky > div:first-child .selector label { margin:0; cursor: pointer; }
.App.guidedsearch.khmer .static-container.sticky > div:first-child .selector label:first-of-type > span{ vertical-align:-2px; padding-right:12px;}

.App.guidedsearch.khmer .static-container.sticky > div:first-child .selector label > span:last-child { font-size:15px; line-height: 20px; font-weight:500; color:#343434; }
.App.guidedsearch.khmer .static-container.sticky > div:first-child .selector label > span:first-child:not(:last-child) { margin-right:30px; margin-top:4px; }

.App.guidedsearch.khmer .static-container.sticky > div:first-child + div h2 { border-bottom:2px solid #e3e3e3; padding-bottom:10px; }


.App.guidedsearch.khmer .static-container.sticky > div:first-child + div {   
   background:white;
   min-height:calc(100vh - 480px);
   padding-bottom:55px;
   padding-top:30px;
}
.App.guidedsearch.khmer .static-container.sticky > div:last-child { padding:35px 0;}

.App.guidedsearch.khmer .static-container.sticky > div:first-child + div .flex { display:flex; justify-content: space-between; }
.App.guidedsearch.khmer .static-container.sticky > div:first-child + div .flex > div { width:415px; max-width:calc(50% - 15px); }

.App.guidedsearch.khmer .static-container.sticky > div:first-child + div [data-prop] { display:flex; flex-wrap: wrap; padding:15px 0 0px 0; justify-content: space-between;}
.App.guidedsearch.khmer .static-container.sticky > div:first-child + div [data-prop] .option { display:block; margin-bottom:-5px; }
.App.guidedsearch.khmer .static-container.sticky > div:first-child + div [data-prop="binding"] .option,
.App.guidedsearch.khmer .static-container.sticky > div:first-child + div [data-prop="format"] .option,
.App.guidedsearch.khmer .static-container.sticky > div:first-child + div [data-prop="language"] .option { padding-right:20px; }
.App.guidedsearch.khmer .static-container.sticky > div:first-child + div [data-prop="style"] .option { width:30%; }
.App.guidedsearch.khmer .static-container.sticky > div:first-child + div [data-prop="topic"] .option { width:50%; }
.App.guidedsearch.khmer .static-container.sticky > div:first-child + div [data-prop] .option label > span:not(.checkbox) { font-size:15px; font-weight:500; color:#343434; 
   padding-bottom: 2px; 
}
.App.guidedsearch.khmer .static-container.sticky > div:first-child + div [data-prop="binding"],
.App.guidedsearch.khmer .static-container.sticky > div:first-child + div [data-prop="format"],
.App.guidedsearch.khmer .static-container.sticky > div:first-child + div [data-prop="completion"],
.App.guidedsearch.khmer .static-container.sticky > div:first-child + div [data-prop="language"] { justify-content: flex-start; }

.App.guidedsearch.khmer .static-container.sticky > div:first-child + div [data-prop="keyword"] input,
.App.guidedsearch.khmer .static-container.sticky > div:first-child + div [data-prop="direct"] > div > div:first-of-type { border:none; box-shadow: none; border-radius: 0;
   background-color:#efefef; width:415px; max-width:100%; box-shadow: inset 0 0 4px rgba(0,0,0,0.25); font-size:14px; 
}
.App.guidedsearch.khmer .static-container.sticky > div:first-child + div [data-prop="direct"] > div { max-width:100%;}

.App.guidedsearch.khmer .static-container.sticky > div:first-child + div [data-prop="direct"] > div > div:first-of-type { color:#343434 !important; }

.App.guidedsearch.khmer .static-container.sticky > div:first-child + div [data-prop="keyword"] input { padding:10px; box-sizing: border-box;}

.App.guidedsearch.khmer .static-container.sticky > div:first-child + div [data-prop="keyword"] > div > div::after,
.App.guidedsearch.khmer .static-container.sticky > div:first-child + div [data-prop="keyword"] > div > div::before { display: none;}

[class$="-indicatorSeparator"] { display: none; }

.App.guidedsearch.khmer .static-container.sticky > div:first-child + div [data-prop] .option .empty-checkbox { font-size:0; position: relative;}
.App.guidedsearch.khmer .static-container.sticky > div:first-child + div [data-prop] .option svg { font-size:22px; }
.App.guidedsearch.khmer .static-container.sticky > div:first-child + div [data-prop] .option .empty-checkbox svg { opacity:0; }
.App.guidedsearch.khmer .static-container.sticky > div:first-child + div [data-prop] .option .empty-checkbox::after {
   content:''; position: absolute; display: block;width:14px;height:14px;background:white; top:3px;left:3px;border:1px solid rgba(0,0,0,0.45);
}

.App.guidedsearch.khmer .static-container.sticky + div { 
   position:fixed; 
   right:calc((100% - 1222px) / 2 - (260px - 150px));
   top:80px;
   width:260px;
   text-align: left;
   display: block;
   height:100%; 
   background-color: #efefef;
}
.App.guidedsearch.khmer .static-container.sticky + div nav a { display: block; font-size:11px; text-transform: uppercase; color:#4a4a4a; font-weight: 700; margin-bottom:20px; }
.App.guidedsearch.khmer .static-container.sticky + div nav a:nth-last-child(2) { margin-bottom:30px;}

.App.guidedsearch.khmer .buttons > *,
.App.guidedsearch.khmer .static-container.sticky + div nav .buttons > * {  display: inline-block;min-width: 72px; position: relative; width:72px;}
.App.guidedsearch.khmer .static-container.sticky + div nav .buttons .reset { margin-left:20px; }
.App.guidedsearch.khmer .static-container.sticky + div nav .buttons > button {min-height:31px; vertical-align: middle; box-sizing: border-box;}
.App.guidedsearch.khmer .static-container.sticky + div nav .buttons button[disabled] { /*background-color: #d7344990;*/ pointer-events:none; overflow: hidden; }
.App.guidedsearch.khmer .static-container.sticky + div nav .buttons button { width:84px; }

.App.guidedsearch.khmer .buttons button.reset  { color:#2f2f2f; font-weight: 600; cursor:pointer;
   border-radius: 2px;  background-color: #fbfafa;  border: 1px solid #e7e7e7; padding:7px 12px; font-size:12px; text-transform: uppercase;
}
.App.guidedsearch.khmer .buttons button.reset:disabled { opacity:0.5; cursor:auto; }


.App.guidedsearch.khmer .static-container.sticky + div nav .log  { font-size:14px; font-weight:500; }
.App.guidedsearch.khmer .static-container.sticky + div nav .log.recheck > * { visibility: hidden;}
.App.guidedsearch.khmer .static-container.sticky + div nav .log .error { color:#d73449; }
.App.guidedsearch.khmer .static-container.sticky + div nav .log .info { opacity:0.35; }

.App.guidedsearch.khmer .static-container.sticky + div h3 { color:#152631; font-size: 14; font-weight: 900; margin-bottom:35px; margin-top:90px;}

.App.guidedsearch.khmer .red.mobile { display:none;}

@media(max-width:1440px) {
   /* .App.guidedsearch.khmer .static-container.sticky + div { right:calc((100% - 1222px) / 2); } */

      
   .App.guidedsearch.khmer .static-container.sticky > div {
      margin-left:calc((100% - 1222px) / 2 + 80px);
   }
}



@media(max-width:1280px) {

   .App.guidedsearch.khmer .static-container.sticky > div {
      width:calc(100% - 185px - 104px - 60px);
      margin-left:104px;
   }
   .App.guidedsearch.khmer .static-container.sticky + div { width:200px; right:0;  }

   .App.home.khmer.static section > div > div,
   .App.home.khmer.static section > div { align-items: flex-start; flex-wrap: wrap;}
   .App.home.khmer.static section > div h2 { width:100%; font-size:32px !important; line-height: 38px !important; margin-bottom:20px;}

   .App.home.khmer.static section > div { padding:80px 24px; width:100%;box-sizing: border-box;}

   .App.home.khmer.static section.orange-gradient > div { justify-content: space-around;}

   .App.home.khmer.static section > div .col { width:50%; box-sizing: border-box;}

   .App.home.khmer.static section.footnote > div { padding:50px 24px;}

   .App.home.khmer.static section.orange-gradient > div > div > img { width:130px; }
   .App.home.khmer.static section.orange-gradient > div > div { width:calc(30vw + 130px + 30px); }
   .App.home.khmer.static section.orange-gradient > div > div > div { width:30vw;}
   .App.home.khmer.static section.orange-gradient > div > div > div span { letter-spacing: 0; font-size:17px; }

   .resource .data + .data.open-etext { padding-left:0; padding-bottom:0 !important;  }

}


@media(max-width:1024px) {

   .App.home.khmer.static section.orange-gradient > div > div { width:100%; padding-left:calc((100% - 500px) / 2);  }

   .App.home.khmer.static section.orange-gradient > div > div + div { margin-top:80px; }

   .App.home.khmer.static header img + div > * { width:100%; }

   .App.home.khmer.static header > img { object-fit: cover;}

   .App.home.khmer.static header img + div { padding-left:24px;}

   .App.home.khmer.static section > div .col { padding-right:18px; }
   .App.home.khmer.static section > div .col + .col { padding-left:18px; }

   .App.home.khmer.static section > div .col2 { column-gap:36px;}


   .App.home.khmer.static section.orange-gradient > div > div > div { width:37.5vw;}

   .App.home.khmer.static h1 { font-size:38px; line-height: 40px;}


   div #res-header { height:75px !important; }
   #root .App .resultPage #res-header > div { flex-wrap: wrap;}
   div #res-header #pagine { width: 100%; justify-content: center;}
   #res-container #pagine div { width: 70% ; }


   #root div .App .resultPage #filters-UI > div { padding-left:40px; padding-right:40px; }
}

@media(max-width:800px) {

   .App.home.khmerServer section > div:not(#a),
   .App.home.khmer.static section.footnote > div,
   .App.home.khmer.static section > div  { width:100%; padding-left:90px;padding-right:90px;  box-sizing: border-box;}
   .App.home.khmer.static section > div .col:not(#a) { width:100%;  padding-left:0; padding-right:0;}
   .App.home.khmer.static section > div .col + .col { padding-top:80px;}

   .App.home.khmer.static section > div .col .img-caption p { text-align: center; }

   .App.home.khmer.static section.footnote > div > div { padding-top:10px;
      width:100%; 
   }

   /* .App.home.khmer.static h1, .App.home.khmerServer .SearchPane #logo > div > div h1 { font-size:42px; line-height: 48px;} */

   .App.home.khmer.static header h1 { margin-top:0; font-size:46px; line-height: 53px;}

   .App.home.khmer.static header { display:flex; justify-content: center; align-items: center; }
   .App.home.khmer.static header img,
   .App.home.khmer.static header { height:100vh !important; }
   .App.home.khmer.static header > * { width:100%;}
   .App.home.khmer.static header img + div { 
      position: absolute;
      padding: 25px;
      box-sizing: border-box;
      background: white;
      transform: scale(0.75);
      transform-origin: 100% 50%;
    }
   .App.home.khmer.static header img { position: absolute; }



/*
   .App.home.khmer.static header > * { width:100%; }
   */

   /* .SearchPane #logo > div { transform:none;} */


   .App.home.khmer.static section > div .col2 { column-count:1;}


   #root .App .resultPage #res-header > div { padding:0 40px !important; }


   #res-header > div .widget-header ~ .widget-header:not(:is(.etextBestM,.placeMap)) { display:none; }

}

@media(max-width:1100px) {
   .App.guidedsearch.khmer .static-container.sticky + div { display:none; }

   .App.guidedsearch.khmer .static-container.sticky > div { margin:auto; width:1024px;max-width:calc(100% - 20px); }
}


a.result .contenu ul > div:last-child h3:is([lang$="-khmr"],[lang="km"]) { font-size:16px; }

#popLang li[lang="km"] {font-size:16px;line-height:16px;}

:is([lang$="-khmr"],[lang="km"]) { font-size:115%;}


.App.browse.khmer .SearchPane > div > div { 
   width:1222px; 
   padding-left: 80px;
   box-sizing: border-box;
}


.App.browse.khmer h1 { margin-top:70px; margin-bottom:50px; text-align: left; padding-left:35px;}

.App.browse.khmer .SearchPane > div > div #samples {
   padding-left:115px;
   padding-right:70px;
}

.App.browse.khmer .SearchPane > div > div .head { display: flex;}

.App.browse.khmer .SearchPane > div > div .head > div { 
   font-size: 11px;
   color: #d73449;
   font-weight: 700;
   width:120px;
   height:65px;
   display: flex;
   align-items: center;
}


.App.browse.khmer .SearchPane > div > div .param {
   position: relative;
   font-size:0;
}

.App.browse.khmer .SearchPane > div > div .param .val {
   width:250px;
   height:65px;
   display: flex;
   align-items: center;
   font-size: 16px;
   color:#172832;
   position: relative;
   scroll-margin:150px;

}

.App.browse.khmer .SearchPane > div > div .param .val > span { cursor: pointer; }
.App.browse.khmer .SearchPane > div > div .param .val > span:hover { color:#d73449; }
.App.browse.khmer .SearchPane > div > div .param .val > span:hover + span { color:#d73449; }

.App.browse.khmer .SearchPane > div > div .head + .param > .val {
   font-weight: 700;
}

.App.browse.khmer .SearchPane > div > div .param > .val.on {
   color:#d73449;
   font-weight: 700;
}

.App.browse.khmer .SearchPane > div > div .param > .val > span svg {
   margin-left:5px;
   vertical-align: -7px;
   position: absolute;
}

.App.browse.khmer .SearchPane > div > div .param > .val.on > svg {
   display: none;
   position: absolute;
   left:-30px;   
   cursor: pointer;
   z-index:1;
   font-size:20px;
}
.App.browse.khmer .SearchPane > div > div .param > .val.on:hover > svg {
   display: block;
}
.App.browse.khmer .SearchPane > div > div .param > .val.on::after {
   content:'';
   position: absolute;
   left:-35px;
   width:35px;
   height:100%;
   display: block;   
   z-index:0;
}

.App.browse.khmer .SearchPane > div > div .param  .val + [data-simplebar]:not(:last-child)::before,
.App.browse.khmer .SearchPane > div > div .param > .val.on + .param:not(:last-child)::before {
   content:'';
   display: block;
   border-left:1px solid #d73449;
   width:40px;
   height:100%;
   position: absolute;
   left:-80px;
   top:-32px;
}

.App.browse.khmer .SearchPane > div > div .param  .val + [data-simplebar],
.App.browse.khmer .SearchPane > div > div > div > .param > .param,
.App.browse.khmer .SearchPane > div > div > div > .param > .param > .param,
.App.browse.khmer .SearchPane > div > div > div > .param > .param > .param > .param {
   padding-left:120px;
}

.App.browse.khmer .SearchPane > div > div .param  .val + [data-simplebar] .val {
   width:100%;
}

.App.browse.khmer .SearchPane > div > div .param  .val + [data-simplebar] .val a {
   color:#152631;   
}

.App.browse.khmer .SearchPane > div > div .param  .val + [data-simplebar] .val a:hover {
   color: #d73449;
}


.App.browse.khmer .SearchPane > div > div .param  .val + [data-simplebar] {
   /* padding-top:20px; */
   background-color:rgba(239,239,239,0.425);
}

.App.browse.khmer .simplebar-scrollbar::before { background: #d73449; }
.App.browse.khmer .simplebar-scrollbar.simplebar-visible::before { opacity:0.35; }

.App.browse.khmer .SearchPane > div > div .param > .val::before {
   content:'';
   display: block;
   border-left:1px solid #d73449;
   border-bottom:1px solid #d73449;
   width:40px;
   height:65px;
   position: absolute;
   left:-80px;
   top:-32px;
}

.App.browse.khmer .SearchPane > div > div .param > .val:first-child::before {
   height:35px;
   top:0px;
}


.App.browse.khmer .SearchPane > div > div .head +.param > .param { 
   padding-left:105px; 
}


/*
.App.browse.khmer .SearchPane > div > div .param ~ .val::before,
*/
.App.browse.khmer .SearchPane > div > div .head +.param > .val + .param:not(#a)::before,
.App.browse.khmer .SearchPane > div > div .head +.param > .val::before {
   content:none;
}


@media(max-width:1280px) {

   .App.browse.khmer .SearchPane > div > div  {
      padding-left:0;
      max-width:calc(100% - 24px * 2);
   }
   .App.browse.khmer .SearchPane > div > div #samples {
      padding-right:40px;
   }

   
}


#uiLangPopup { position: fixed; width:100%;height:100%; display: flex; align-items: center; justify-content: center; top:0; left:0; z-index:100000; }
#uiLangPopup .bg { position: absolute;  width:100%;height:100%; background-color:rgba(0,0,0,0.2);} 
#uiLangPopup .fg { position: relative; background-color:white; border-radius:5px; padding:20px;}
#uiLangPopup .fg > div:first-child { display: flex; align-items: center;}
#uiLangPopup .fg > div > div { width:100%; padding-top:15px;}
#uiLangPopup .fg > div > div > li { justify-content: center;}
#uiLangPopup .fg > div > div > li.is-locale { font-weight:900; background-color:#f5f5f5;}

/* ---------------------------------------------------------------------------------------------------------------------- "sidepanes" */


/* .SidePane > div { padding-bottom:50px; } */
.SidePane > div > ul { align-items: center; }
.SidePane > .close { position:absolute; top:-6px;right:-6px;}
.SidePane > .hide { position:absolute; top:-6px;left:-6px;}
.SidePane > #annoCollec { position:absolute; top:-6px;left:40px;}
.SidePane > #annoCollec svg.score0 { border-radius:50%; background-color:rgba(255, 205, 3, 0.4);border:1px dashed rgba(167, 134, 1, 0.4);}
.SidePane > #annoCollec svg.score1 { border-radius:50%; background-color:rgba(17, 173, 17, 0.3);border:1px solid rgba(36, 106, 36, 0.4);}

.SidePane { position:absolute;transition:left 400ms ease-in-out,right 400ms ease-in-out; background:white;min-height:calc(100vh - 355px); position:fixed;padding:20px;
   background:rgba(246,246,246,0.935); z-index:100000; top:0;  }
/* .SidePane.left {  box-shadow:2px 0 2px 0px rgba(0,0,0,0.35); }  */
.visible .SidePane.left { overflow-y:auto;}
.SidePane.right { right:-500px; box-shadow:-2px 0 2px 0px rgba(0,0,0,0.35);}
.SidePane.right.visible { right:0; /*overflow-y:auto; */ }
/* .SidePane > div { position:fixed } */
.SidePane .loader { position:absolute !important;margin-right:9px;margin-top:18px;right:0;}
.datatypesLoader, .facetLoader { left:0px !important;top:-10px !important;}

.App > :first-child { position:relative; z-index:10000; transition:left 400ms ease-in-out;}
/* .App > :not(.visible):first-child { left:-500px; } */
.App > :not(.visible):first-child #resizableLeftPane { position:absolute;  } 
.App > .visible:first-child { left:0; }
.App > .visible:first-child #resizableLeftPane { position:fixed; } 
/* .App > .visible:first-child + .SearchPane { margin-left:0; } */

#resizableLeftPane .SidePane { position:relative; overflow-x:hidden;} 

.react-resizable-handle {
   position: absolute;
   width: 15px;
   height: 100%;
   bottom: 0;
   right: -10px;
   box-sizing: border-box;
   cursor: e-resize;
   z-index:101;
 }
 
.SearchPane:not(.resultPage) .SidePane.left { display:none; }

.SidePane.left { position:relative; display:inline-block; width:300px;min-width:300px;  box-sizing: content-box; background:#efefef; padding:0;}

.SidePane.left .simplebar-track.simplebar-vertical { right:12px; opacity:0.5;}

.SidePane.left > [data-simplebar] { height:100%; padding:0 35px 35px 35px; width:300px; box-sizing: border-box;}

/*
.SidePane.left {
   scrollbar-width: none;
  -ms-overflow-style: none;
}

.SidePane.left::-webkit-scrollbar {
  display: none;
}
*/

.SidePane.left { position:sticky; top:230px; align-self: flex-start; min-height:0; overflow-y: auto; }
/* .SidePane.left.isTop {height:calc(100vh - 355px); } */


.SidePane.left .sidebar-title { color: rgba(17, 19, 18, 0.9); font-weight:800; font-size:18px; text-align:left; margin-bottom:20px; 
   /* font-family:"Noto Sans"; */
}


.SidePane.left .widget-header svg { font-size:18px; }

/* .AppSK .filter-title, */
.SidePane.left .widget-header {display:flex;justify-content:space-between;padding:0;border-bottom:1px solid #c1c1c1; cursor:pointer;}

#settings + .widget-header { align-items: center;}
#settings + .widget-header .header-icon { color:#4a4a4a; font-size:20px; padding-top:2px;padding-right:4px;}

.AppSK .filter-title p,
.SidePane.left .widget-title { color: #4a4a4a; font-weight:700;font-size:10px;text-transform: uppercase; /*font-family:"Noto Sans";*/ margin-bottom:4px; }

.SidePane.left .widget-title .new { position:absolute; font-weight:400; color:#d73449; padding-left:5px; font-size:8px; margin-top:1px;}

.SidePane.left .collapse { padding:5px 0 0 15px; transition:none;}

/* TODO fix left column width under ~1440px
@media(max-width:calc(920px + 130px + 40px + 300px)) {
   .SidePane.left { padding-left:0 };
}
*/


.App.guidedsearch.khmer .static-container.sticky .buttons { display:none; }

@media(max-width:1024px) {
   .App.guidedsearch.khmer .static-container.sticky > div:first-child + div [data-prop="style"] .option { width:50%; }

   
   .App.guidedsearch.khmer .static-container.sticky > div:first-child + div .flex { flex-wrap:wrap; }
   .App.guidedsearch.khmer .static-container.sticky > div:first-child + div .flex > div { width:100%; max-width:100%; }
   
   #root .nav.khmerServer { height:220px; }
   #root .nav.khmerServer:not(.on) { top:-220px; }
   .nav #logo.khmer + .links { width:150px; }
   
   .App.browse.khmer .SearchPane   {min-height:calc(100vh - 100px); padding-bottom:100px; box-sizing: border-box;}
 
   .App.browse.khmer div h1 { margin-top:20px; margin-bottom:20px;}

   .App.browse.khmer .SearchPane > div > div h1 + #samples { padding-left:36px; }

   .App.browse.khmer .SearchPane > div > div .param .val + [data-simplebar], 
   .App.browse.khmer .SearchPane > div > div > div > .param > .param, 
   .App.browse.khmer .SearchPane > div > div > div > .param > .param > .param, 
   .App.browse.khmer .SearchPane > div > div > div > .param > .param > .param > .param {
      padding-left:80px;
   }

   .App.browse.khmer .SearchPane > div > div .param .val + [data-simplebar]:not(:last-child)::before, 
   .App.browse.khmer .SearchPane > div > div .param > .val.on + .param:not(:last-child)::before,
   .App.browse.khmer .SearchPane > div > div .param > .val::before { left:-60px;}

   .App.browse.khmer .SearchPane > div > div .head +.param > .param { padding-left:80px;}

   .App.home.khmerServer section .float img { 
      width: 40vw;
      height: 40vw;
      margin-left: 30px;
      margin-top:0;
   }

   .App.home.khmerServer section > div { padding:60px;}

   .App.home.khmerServer #search-bar .links { margin-bottom:20px;}

   div #filters-UI { top:0px; padding-bottom:5px; }

   .SidePane.left .widget-header { align-items: center; }

   div #res-header { height:40px; }

   #root div .App .resultPage #filters-UI > div { margin-left:0px; padding-top:15px; max-height:80px; padding-right:15px;}
}

[lang='km'][data-browser='Firefox'] .home ~ footer :is(p,div),
[lang='km'][data-browser='Firefox'] .home .static-container :is(h1,h2,p,a),
[lang='km'][data-browser='Firefox'] .home #learn p { word-break:break-all;}


/* ----------------------------------------------------------------------------------------------------------------------- samples/results */

#results,
#samples { width: 960px; margin-right:40px; /*margin: auto;*/ text-align: left; z-index: 0; padding:30px;background:white; display:block;
   padding-left:16px; padding-right:16px; padding-bottom:140px; max-width:100%;box-shadow: 0 2px 10px #e2e2e2; box-sizing:border-box;
}
#samples { padding: 60px 0 ; width:910px;margin-right:0px; /*padding-left:37px; */ margin-bottom:75px; width:1220px;}
#samples > h3 { text-transform: uppercase; text-align: center; font-size:28px;margin:0 0 10px 0;}

#results {  padding-bottom:75px;margin-bottom:75px;}

p.no-result { 
   font-size: 16px;
   margin: 20px 22px 0 22px;
}

.no-result .noR { display:block; margin-top:25px; }
.no-result .noR li:not(#a) { list-style-type: disc; margin-left:30px;margin-top:6px;}

.no-result #clear-filters { margin-left:0; margin-top:5px; display:flex;align-items: center;}
.no-result #clear-filters * { color:#d73449; }
/* .no-result + .no-result { margin-top:0px;} */

.no-result .reset-khmer { display:flex; align-items: baseline;}
.no-result .reset-khmer #clear-filters { display: inline-flex; }

.no-result .noR:not(.bo-x-ewts) li:last-child { display:none; }
#res-container .no-result .noR.bo-x-ewts li:last-child { pointer-events:auto; }
#res-container .no-result .noR.bo-x-ewts li:last-child .uri-link { margin-left:0;}
.noR.bo-x-ewts li .uri-link:not(:hover) { border-bottom:1px solid transparent; }

.no-result .uri-link { display:inline-block; margin-left:10px;  }

.no-result.more-result { display:flex; align-items: baseline; margin-bottom:10px;}
.no-result.more-result #clear-filters { display:inline-flex; margin:0; margin-left:5px; }

.no-result + #pagine { margin-top:30px;}

/* sticky's parent must not have overflow set */
/* .SearchPane.resultPage { overflow-y:auto;} */


#res-container > ul li h4 span { text-transform: none; font-weight:500;}

/* #res-container > ul li { padding-left:0; pointer-events:none; } */

#res-container > ul h4 { padding-left:22px;; }
#res-container > ul h4 { color:#343434; font-size:24px; /*font-family:"Noto Sans";*/ font-weight:900; text-transform:uppercase; }
#res-container > ul:not(#samples) h4 {
   /* white-space: nowrap; text-overflow: ellipsis; overflow: hidden; height:32px; */
}
#res-container > ul:not(#samples) li { height:32px;}

#res-container #samples > h4 { padding-left:0; font-size:18px; text-transform: none;  font-weight:400; text-align:center; }

/* .SearchPane.resultPage #res-container { padding-top:70px;} */

/* #res-header { justify-content: flex-end;} */
#res-header { height:70px; position:relative;z-index:1; position:sticky;top:160px;z-index:1000; background-color:rgb(239,239,239)}
#res-header > div { /*width:calc(930px + 130px + 40px - 100px); margin-left:calc(230px + 35px + 35px); margin-right:100px; */ 
   width:960px; margin-left:calc(960px - 700px); text-align:left; display:flex; 
   align-items: center; justify-content: space-between;}
#res-header > div .collapse { padding-left:5px; background:white;  margin-right:auto;
   /* position:absolute;top:100%;  */
   box-shadow: 0 2px 10px #e2e2e2;padding:15px; 
   transition:none;}
#res-header > div .collapse.close {display: none;}
#res-header > div .widget-header { display:flex; cursor:pointer; margin-top:10px;margin-left:34px;align-items: center;}
#res-header > div .widget-header svg { font-size:16px;}
#res-header > div .widget-header .widget-title { font-size:10px;color:#4a4a4a;font-weight:700; text-transform: uppercase; }

#res-header #pagine { font-size:12px; font-weight:500; width:auto; margin-top:10px; padding:0; padding-right:calc(40px);}

#res-header > div .widget-header:nth-last-child(2) { margin-right:auto; }

#res-header #pagine #nb { 
   margin-left: 10px;
   padding-left: 15px;
   border-left: 1px solid #4a4a4a;
   color: rgba(17, 19, 18, 0.5);
}

#res-header #pagine #nb:first-child { border:none; }

#res-container > .fixloader,
#res-container > .loader { position:fixed; top:calc(50vh - 40px); z-index:10000; }


.fond-logo ~ #res-container > .fixloader,
.fond-logo ~ #res-container > .loader:first-child:last-child { position:absolute; top:calc(50vh + 140px); }

.SearchPane:not(.resultPage) #res-header { display:none; }

#pagine { width:100%;display:flex;padding:20px;justify-content: space-between; box-sizing:border-box}
#pagine svg {height:36px;width:36px; cursor:pointer;}

#pagine div { line-height:36px; text-align:center;}
#pagine div a { cursor:pointer; display:inline-block;margin-left:5px;margin-right:5px;}
#pagine div a:hover { text-decoration:underline; }

#results > li > h4 + svg { margin-top:10px; }
#res-container > ul li h4 { white-space: normal;line-height:28px; }
#res-container > ul li h4 a { pointer-events:auto; text-transform:none; font-weight:500;color: #d73449; padding-bottom:30px; display:inline-block;}
#res-container > ul li h4 a:hover { text-decoration: underline;}
#res-container > ul li h4 + span { display:none !important;}
/* #res-container > ul li:hover { background:none;} */
#res-container > ul li { height:auto !important;}

#results  > li:first-child > h4 > a { padding-bottom:0; }

.result-content > hr { margin:0;height:0;padding:0;opacity:0;border:0; }
li + a.result { margin-top:-5px; }

/* ---------------------------------------------------------------------------------------------------------------------------- latest */

#latest { width:100%;background:white;padding:60px 60px 100px 60px;display: block;box-sizing: border-box; position:relative;}

#latest > div:first-of-type { padding: 60px 30px ; margin:auto; width:1220px;background:#efefef;box-sizing: border-box;max-width:100%;margin-top:60px; }
#latest .slide-bg { display:flex; justify-content: flex-start; margin-left:0; transition: margin-left 0.8s ease-in-out; }
#latest h3 { text-align: left; font-size:28px; margin:auto; width:1220px;box-sizing: border-box;max-width:100%;padding-left:49px;}

#latest > div {  overflow: hidden;position: relative;}

#latest .slide-bg > div { width:195px;height:auto;margin:0 18px; text-align: left;flex-grow:0;flex-shrink:0;transition: opacity 0.4s ease-in-out; }
#latest .slide-bg > div .header { height:195px; background: #fce08d; display:flex; align-items: center; justify-content: center;}
#latest .slide-bg > div .header::before { background-image:url("/icons/image.svg"); background-size:52px auto; width:125px;height:125px;background-color:white;
   border-radius: 50%; content:''; background-repeat: no-repeat; background-position: center;
}
#latest .slide-bg > div .header.thumb::before {content:none;}
#latest .slide-bg > div .header.thumb { background-size:auto 100%; background-position:center;background-repeat: no-repeat; 
   box-shadow: 0 1px 4px #cecece; background-color:black;
}

#latest .slide-bg > div p { color: #343434; font-weight: 500; font-size: 15px; margin-top:30px;}
#latest .slide-bg > div p + a { color: #343434;font-weight: 700;font-size: 13px; }
#latest .slide-bg > div p + a:hover { border-bottom: 1px solid #343434;}

#latest .slide-bg:not(.slided) > div:nth-child(n+6) { opacity: 0;}
#latest .slide-bg.slided > div:nth-child(n+6) { opacity: 1;}

#latest .slide-bg:not(.slided) > div:nth-child(-n+5) { opacity: 1;}
#latest .slide-bg.slided > div:nth-child(-n+5) { opacity: 0;}

#latest #syncs-nav { margin-top: 60px; }
#related-nav span, #latest #syncs-nav span { display: inline-block; margin:0px 10px; opacity:0.35; }
#related-nav span.on, #latest #syncs-nav span.on { cursor:pointer; opacity:1;}

#related-nav { text-align:center;}

#latest .slide-bg.slided { margin-left:calc(-100% + 5px); }

#latest .seeAll { position: absolute; right:0; color:#343434; margin-right:calc(60px + 49px + 36px + 18px); display: inline-block; text-transform:uppercase;
   border-bottom:2px solid #343434;font-weight:500;top:70px;;
}
#latest .seeAll:hover { color:#d73449; border-color: #d73449;}

/* --------------------------------------------------------------------------------------------------------------------- results/matches */

.external .result-box  { position:absolute;top:calc(35px + 20px + 0px); display: flex; flex-direction: column; justify-content: flex-start;
   top: calc(35px + 20px + 0px); left: 15px; width: 40px; text-align: center; ;}
.external .result-box img { width:20px;height:auto;margin-left:0; }
.result-box .sameAsLogo { line-height:18px;border-radius:4px; border-width:1px; }
.external .result-box .sameAsLogo { border-radius:6px;border-width:2px;}
.external .result-box .source-data { display:block; margin-left:0px; }

.result-box .source-data { margin-left:10px;}
.result-box .source a img { margin-left:0; }

.result-box { pointer-events: none;}
.result-box .source { pointer-events: auto;}

#matches + .result-box .sameAsLogo { margin:0; height:12px; line-height:12px; font-weight:600; vertical-align: 2px; }

.external ~ .result-box { display:none; }

a.result { padding:0;/*margin-bottom:10px;*/width:100%;display: inline-block; /*margin-top:3px;*/ max-width:calc(100% - 100px);}
a.result + #matches a.result { max-width:calc(100% - 25px);}
a.result button { text-transform:none; padding-bottom:0;}
/* a.result button::before { content:'Abstract Works';display:block;height:50px;position:absolute;left:0; }  */
.result-box ~ #matches, a.result ~ #matches { /*margin-top:-15px!important;  padding:2px 0 0px 0 ;*/ margin-left:185px; font-size:13px; 
   margin-bottom:5px; /*border-left: 1px solid rgba(0,0,0,0.25) ;*/ line-height:20px;  }
#matches .match  { align-items: baseline;  }
.result-box ~ #matches:empty, a.result ~ #matches:empty { margin-bottom:8px; margin-top:-20px !important;padding-top:0 !important;}

/* box-shadow:-5px 0 5px -4px rgba(0,0,0,0.2);  } */
#matches .match { margin:10px 0; max-width:calc(100% - 100px); }
a.result + #matches a.result + #matches .match { max-width:calc(100% - 75px); }
a.result + #matches .result-content { padding-left:0; min-height:70px; }

.result-content.wThumb { min-height:140px; }
a.result + #matches .result-content.wThumb { min-height:120px; }


a.result + #matches .result-content #num-box { border-width:1px; border-color:rgba(0,0,0,0.35); margin-top:0px; /*pointer-events:none;*/}
a.result + #matches .result-content h3 { font-size:12px; line-height:20px; }
a.result + #matches .result-content #matches { font-size:12px; line-height:18px; }
a.result + #matches .result-content .contenu { margin-left:100px; max-width:calc(100% - 160px - 24px);}
a.result + #matches .result-content #matches { margin-left:125px; margin-bottom:0;}

a.result + #matches .result-content.etext #permalink,
a.result + #matches .result-content.etext #icon .RID { visibility:hidden;}

a.result ~ #matches .result-content #matches .match.Text.Match .label { display:none; }
a.result + #matches .result-content #matches .match.Text.Match { margin-top:0;}

a.result + #matches .result-content::before { left:110px; width:calc(100% - 110px - 50px); }

#matches .match:last-child { margin-bottom:0;}
#matches > .match > span:not(.lang):not(.instance-link) { display:inline ; line-height:20px; position:relative; font-size:12px;}
.result .contenu h3 .lang,
#matches span .lang { /*position:absolute;top:auto;bottom:-5px;*/display:none;line-height:0;}

.result .contenu h3 .link-out { display:inline-block; margin-left:5px; vertical-align: -1px;}

/*
.result .contenu h3:hover .lang,
#matches span:hover > .lang { display:inline;}
*/
#matches span.label { text-transform:capitalize; font-weight:700; float:left;clear:both; flex-shrink:0; font-size:12px; }
/* #matches span:not(.label) {  } */
.match { display:flex; margin-bottom:0px }
.match a { /*word-break:  break-word;*/ overflow-wrap: break-word; }
.match .label.invisible { opacity:0; }

#matches .match.same.As,
#matches .match.same.as,
#matches .match.Same.As { display:none;}

.result-content #num-box:empty::before { content:'1'; }

.result-content #num-box svg { position:absolute; top:60px;}

.result-content #num-box { width:22px;height:22px;border: 2px solid rgba(10,20,30,0.5); font-size:9px;color:rgba(10,20,30,0.75);font-weight:700;line-height:normal;
   display:inline-flex;align-items: center;justify-content: center; box-sizing: border-box; vertical-align: top; line-height:10px; cursor:pointer;
   margin-right:5px;
}

.result-content #num-box.checked { background:#152631;color:white;}

.result-content #icon { display: inline-block;width:calc(80px + 25px + 25px + 25px);height:90px; vertical-align: top; text-align: center;
   position:absolute;  margin-left:0px;
}

.AppSK .result .thumb a .RID,
.result-content #icon .RID { color: rgba(118, 118, 118, 0.9); font-size:9px;font-weight:600; 
      max-width: 130px;  overflow: hidden; position: relative; text-overflow: ellipsis; text-align: center; margin: 5px auto; white-space: nowrap;
}
      
.result-content .contenu { display:inline-block; margin-left:160px; max-width: calc(100% - 160px - 20px); vertical-align: top; }


.result-content #icon img { width:70px; max-height:90px;}

.result-content #icon.person img{ width:75px; }

.result-content #icon.lineage img,
.result-content #icon.work img { width:80px; }

.result-content #icon.images img,
.result-content #icon.instance img,
.result-content #icon.topic img{ width:60px; }

.result-content #icon .copyRi.lowQ img { margin-top:4px !important; }

.result-content #icon.wCopyR .copyRi img,
.result-content #icon.wCopyR > img { width:20px; display: inline-block; margin:4px 6px 0 6px; opacity:0.75; }
.result-content #icon.wCopyR { margin-bottom:50px; }

.result-content #icon.wCopyR > a img { width:24px !important; margin-bottom:4px; }

.result-content #icon > .copyRi {  position:absolute; left:0px; margin-top:-45px !important; width:33px;height:33px;background:white;
   border-radius:50% ; box-shadow:0px 0px 2px 1px  rgba(0,0,0,0.25);
}
.result-content #icon > div:first-child:not(.thumb) ~ .copyRi { left:34px; margin-top:-52px !important;} 

.result-content #icon.wCopyR .copyRi img { margin:1px; opacity:0.7;}

.result-content #icon .copyRi.noImg { display:flex;align-items: center;justify-content: center;}
.result-content #icon .copyRi.noImg img { width:35px;}

.result-content.otherSrc #icon .provImg:not(.oriRec) { display:none;}

.result-content #icon .provImg { vertical-align: baseline; margin-right:0 !important; height:36px; width:auto !important; 
   position:absolute; right:17px; margin-top:-65px !important; opacity:1 !important;
}

.result-content #icon .provImg.sat { 
   width: 26px !important;
   height: 25px;
   margin-top: -55px !important;
   background: white;
   display: flex;
   align-items: center;
   justify-content: center;
   border: 2px solid green;
   font-size: 11px;
   font-weight: 600;
   border-radius: 4px;
   margin-right: -1px !important;
   line-height:12px;
}

.result-content #icon .provImg.loc {height:15px;margin-top:-40px !important;}

.result-content #icon .provImg.lul,
.result-content #icon .provImg.sbb { height:30px;margin-top:-55px !important;}

.result-content #icon .provImg.idp,
.result-content #icon .provImg.rkts,
.result-content #icon .provImg.cudl,
.result-content #icon .provImg.gretil,
.result-content #icon .provImg.ia { height:24px; margin-top:-53px !important;}


.result-content #icon .provImg.idp { border:1px solid rgba(0,0,0,0.05);}

.result-content #icon:not(.wCopyR) .provImg { margin-top:-45px !important; right:35px; background:white;}

.result-content #icon:not(.wCopyR).work .provImg { margin-top:-61px !important; } 

.result-content #icon > div:first-child:not(.thumb) ~ .provImg.eap { right: 40px; margin-top:-55px !important;}
/* 
.result-content #icon.lineage img { width:80px;}

.result-content #icon.person img,
*/

.result-content #icon > .thumb { background:black; width:130px; height:130px; margin:auto; overflow:hidden; box-shadow:0 1px 4px #cecece; display:flex;
   justify-content: center;
}
.result-content #icon > .thumb img { height:100%; width:auto; max-width:1000px; max-height:1000px; }

.result-content #icon > div:nth-child(2):not(:last-of-type) { position:absolute; visibility:hidden;top:0;left:0; width:100%;}
.result-content:hover #icon > div:nth-child(1):not(.thumb) { visibility:hidden; }
.result-content:hover #icon > div:nth-child(2) { visibility:visible; }

a.result + #matches .result-content #icon { width:95px;  }
a.result + #matches .result-content #icon > .thumb { width:85px; height:85px;  }

/*
a.result + #matches .result-content #icon img { min-width:40px; width:40px; }
a.result + #matches .result-content #icon img { min-width:40px; width:40px; }
*/
a.result + #matches .result-content #icon div:last-of-type { max-width:80px;   }

a.result + #matches .result-content #icon > :not(.thumb) img { width:40px; }

/*
.result-content .contenu h3 > span.highlight:first-child,
.result-content .contenu h3 > span:first-of-type:empty + span.highlight { margin-left:-10px;}
*/

.samePop, [role=tooltip] { z-index:1000000 !important;}

#results > li + .result-content { scroll-margin:430px; }

.result-content #permalink { position: absolute;  right: 15px;  top: calc(50% - 16px); cursor:pointer; }
.result-content #permalink.wInstance { top:100px; }


.result-content { position:relative;  scroll-margin:250px; margin:0 0px; padding: 35px 25px; min-height:90px; } 
.result-content+.result-content::before { content:" ";width:calc(100% - 80px - 50px);display:block;position:absolute;
   border-top: 4px solid rgba(239,238,238,0.6); left:80px; top:-2px; 
}


.place.result-content { min-height:110px; } 
.person.result-content { min-height:95px; } 
.work.result-content { min-height:100px; } 
.topic.result-content { min-height:70px; } 

.result-content:hover::after { content:' ';width:100%;height:100%;position:absolute;z-index:-2;top:0;left:0;background-color: rgba(239,238,238,0.6); }

.result-content:hover::before,.result-content:hover+.result-content::before { opacity:0; }

.result-content.StatusEditing,.result-content.StatusWithdrawn,.result-content.StatusProvisional { opacity:0.45;} 


.result-box { width:calc(100% - 75px);display:flex; justify-content: flex-end; position:absolute; bottom:10px;}

#matches .match.by + .by > span.label,
#matches .match.publisher + .publisher > span.hidden-en { visibility: hidden; }
#matches .match.by + .by,
#matches .match.publisher + .publisher { margin-top:-11px; }

.highlight:empty { display:none; }

/*
#matches .match.publisher div.multi > span.highlight ~ span,
#matches .match.publisher div.multi > span.highlight { display:inline-block; }
*/

/*
#matches .match.publisher.en > span > span,
#matches .match.publisher.en + .match > span > span { display: inline-block;}

#matches .match.publisher.en { margin-bottom:0; }
#matches .match.publisher.en + .match { margin-top:-1px; }
#matches .match.publisher.en + .match.publisher > span.hidden-en { visibility:hidden;}

#matches .match.publisher.en > span > span:first-child,
#matches .match.publisher.en + .match > span > span:first-child { padding-right:3px;}

#matches .match.publisher.en > span > span:last-child,
#matches .match.publisher.en + .match > span > span:last-child { width:45px;}

#matches .match.publisher.bo > span > span:last-child::after,
#matches .match.publisher.bo + .match > span > span:last-child::after {  content:''; padding-left:5px;}

#matches .match.publisher.bo > span > span + span,
#matches .match.publisher.zh > span > span + span { padding-left:3px;display:inline-block;}
*/

.resource a.outL,
.match .inRoot ~ a { font-weight:600; vertical-align: 0px; white-space:nowrap;}
.resource h2 + h3 .sepa,
.match .inRoot ~ .sepa { margin:0 10px;  display:inline-block; height:13px; vertical-align: -4px; border:1px solid #343434; }
.resource a.outL img,
.match .rec img,
.match .outL img { vertical-align: middle; background: white; margin-left:-5px;}
.result-content:hover .match .rec img,
.result-content:hover .match .outL img { background: #f5f5f5;}

.match .inRoot + .sepa { display:block; height: 0; border:none; }

.resource a.outL { float:none; background: #d73449; border:none !important; border-radius:5px; padding:0px 15px;  color:white !important;
   margin-top:-5px; margin-left:15px; font-size:13px; height:40px;line-height: 40px; display: inline-block;
}
.resource a.outL img { background:none; filter:invert(100%) saturate(0%) brightness(1000%); vertical-align: -9px;}
.resource a.outL:hover { text-decoration: underline;}

.resource h3.inT { width:calc(100%); padding-top:5px; line-height:30px;}

#matches .match .assets { margin:0 4px; }
#matches .match .assets img,
#matches .match .assets svg { height:16px; vertical-align:-4px; width:auto !important; display:inline-block;margin:0 4px; }
#matches .match .assets svg path { stroke:rgba(0,0,0,0.4);fill:rgba(0,0,0,0.4); }

#matches > .match ~ .instance-collapse,
#matches > .match ~ .instance-collapse + .label,
#matches > .match ~ .instance-link { margin-top:0px;  }

#matches > .instance-collapse,
#matches > .instance-collapse + .label,
#matches > .instance-link { margin-top:10px;  }

.instance-collapse svg { font-size:19px;}

#matches .res-collapse { transition:none; }

.instance-link { color:#d73449; /* rgba(229, 74, 53,1);*/   margin-right:25px;display:inline-flex;align-items:baseline;font-weight:700; /* margin-top:10px; */}
.instance-link > span { cursor:pointer; }
.instance-link > emph { color:black; }

.instance-collapse { display:inline-flex !important; align-items:flex-end; margin-top:-2px; cursor:pointer; height:18px; overflow:hidden; }

.instance-collapse.repro  { margin-top:5px; align-items: center;}
.instance-collapse.repro > span { font-size:12px;}

.instance-collapse > span { border-bottom:1px solid transparent; }
.instance-collapse:hover > span { border-color:#d73449; }

#matches > .match:first-child { margin-top:8px;  }

.source { /*position:absolute;*/ top:0; margin-top:8px; margin-left:0px; display:inline-block; flex-shrink:0; cursor:pointer;}

.sameAsLogo,
.source a img { height:18px; margin:0 5px; padding-top:2px; display:inline-block;}
/* .source a:last-child img { height:35px; vertical-align:top;  } */

.source a img { margin:0; height:12px;margin-left:5px;;}

.sameAsLogo { color:black; font-weight:bold; background-color:#eee;border:1px solid #aaa;font-size:10px;align-items: center; padding-top:0px;padding:0px 2px;  line-height:20px; }

.source-data { vertical-align:middle; }

.source-data,
.source-data a { display:inline-block; position:relative; }
.source-data span { width:0;height:0;display:inline-block;position:absolute;background:transparent;
   border:6px solid transparent;border-left-color:rgba(229, 74, 53,1);transform:rotate(-45deg);
   top:-8px;right:-4px; opacity:0;transition:all 100ms ease-in-out;  pointer-events: none;
}

/* .source-data:hover span { opacity:1; pointer-events: auto; cursor:pointer;} */

/*
a.menu-item-source {
   position: absolute;
   padding-left: 16px;
   left: 0;
   top: 0;
   width: 100%;
   display: flex;
   height: 100%;
   align-items: center;
   color:rgba(0,0,0,0.87);
   text-decoration: none;
}
*/
span.void { opacity:0; pointer-events: none; }

/* a.result .contenu h3 { font-size:13px !important; } */

a.result .lang, .match .lang,.data .lang,h2 .lang, .imageVolumePopup .lang
{ font-size: 9px;display:inline-block;margin-left:2px;/*margin-top:7px; position:absolute; */ color:rgba(0,0,0,0.5); display:inline; white-space: nowrap; 
   /*vertical-align: -4px; margin-bottom:-4px;*/ 

   vertical-align: baseline;
   position: relative;
   top: 0.5em;

   font-weight:400;
}
h2 .lang,.data .lang { position:relative;margin:0;vertical-align:-2px;padding:5px;line-height:0;z-index:1;letter-spacing:0;}
h2 .lang { margin-top:22px;font-weight:normal; }
.imageVolumePopup .lang { position:relative;padding:0px;margin-top:12px; }
/* a.result .lang, .match .lang { min-width:50px;} */
a.result .contenu ul { padding:0px 0px 0px 0px !important;}
a.result .contenu ul > div:last-child {
   /* padding:0 16px !important; */
   height: auto;
   flex-grow: 10;
   flex-shrink: 10;
   flex: 1 1 auto;
   min-width: 0;
}

/* a.result .contenu ul > div:last-child .author { color:rgba(0,0,0,1); font-weight:500; font-size:12px; font-style:italic;} */

.resource h3.withdrawn { font-style:italic; opacity:1; margin-top:15px; display:block;}

h3.withdrawn svg { vertical-align:text-bottom; margin:0px 10px; opacity:0.55; font-size:16px;} /*color:rgba(229, 74, 53,1)}*/
h3.withdrawn svg:first-child { margin-left:0; }

h3.withdrawn ~ * { opacity:0.45;}

.AppSK .result .data .T,
.result .T { text-transform: uppercase; color:#343434; font-size:9px; font-weight:600; vertical-align:top; display: flex; 
   line-height:10px; margin-bottom:12px; align-items: center;}

.result .T > span:not([lang]) { display:inline-block; border:2px solid rgba(0,0,0,0.25); background:white; margin-left:15px; border-radius:2px; 
   display:inline-flex; align-items: center; justify-content: center; position:relative; height:14px; width:auto; padding:0 2px;
}
.result .T > span:not([lang])::before { content:' '; font-size:10px; font-weight:700;  text-transform:none; }

.result .T span[data-lang] + [data-lang] { margin-left:5px; }

.result .T span[data-lang$='En'] { border-color:rgba(0,150,255,0.5); }
.result .T span[data-lang$='En']::before { content:'EN'; font-weight:800; }

.result .T span[data-lang$='Bo'] { border-color:#d73449; }
.result .T span[data-lang$='Bo']::before { content:'ཨ'; font-size:22px; font-weight:400; padding-top:3px; }

.result .T span[data-lang$='Pi'] { border-color:rgba(250,150,0,1); }
.result .T span[data-lang$='Pi']::before { content:'Pi'; }

.result .T span[data-lang$='Pgd'],
.result .T span[data-lang$='Sa'],
.result .T span[data-lang$='Inc'] { border-color:#fce08d; }
.result .T span[data-lang$='Pgd']::before,
.result .T span[data-lang$='Sa']::before,
.result .T span[data-lang$='Inc']::before { content:'अ'; font-size:16px; font-weight:400; padding-bottom:2px; }

.result .T span[data-lang$='Zh'] { border-color:rgba(0,50,255,1); }
.result .T span[data-lang$='Zh']::before { content:'中'; font-size:13px; font-weight:700; padding-bottom:2px; padding-right:2px; }

.result .T span[data-lang$='Es']::before { content:'ES'; }
.result .T span[data-lang$='Fr']::before { content:'FR'; }
.result .T span[data-lang$='It']::before { content:'IT'; }
.result .T span[data-lang$='Nl']::before { content:'NL'; }
.result .T span[data-lang$='No']::before { content:'NO'; }
.result .T span[data-lang$='Ru']::before { content:'RU'; }

.result .T span[data-lang$='Dz']::before { content:'Dz'; }
.result .T span[data-lang$='Hi']::before { content:'Hi'; }
.result .T span[data-lang$='Km']::before { content:'Km'; }
.result .T span[data-lang$='Mn']::before { content:'Mn'; }
.result .T span[data-lang$='Ne']::before { content:'Ne'; }
.result .T span[data-lang$='Ur']::before { content:'Ur'; }
.result .T span[data-lang$='My']::before { content:'My'; }

/* TODO exhaustive support of languages/script */

.result .T span[data-lang*="/Script"]::before { visibility:hidden; } 

.result .T >span[data-lang*="/Script"] { padding-top:0}


.resource > h2,
a.result .contenu ul > div:last-child h3 > span:first-child { word-break: break-word; overflow-wrap: break-word; }

a.result .contenu ul > div:last-child h3 {
   color: rgba(0, 0, 0, 0.87); font-size: 13px; font-weight: 700; 
   line-height: 22px; display:block; margin:3px 0 5px 0;
}

a.result .contenu ul > div:last-child h3[lang="?"] span:empty,
a.result .contenu ul > div:last-child h3[lang="?"] { display:none; }

a.result .contenu ul > div:last-child p {
   color: rgba(0, 0, 0, 0.54);   font-size: 0.85rem;   font-weight: 400; 
   line-height: 1.46429em; margin: 0;  display: block;
}

a.result .contenu ul > div:first-child { position:relative;}
a.result .contenu ul > div:first-child > svg { position:absolute; top:-16px; right:16px;font-size:14px; }

/* [data-prop$="tmp/reproductions"] .colon, */

[data-prop$="tmp/incomplete"] .colon { display:none; }


[data-prop$="tmp/reproductions"] span { font-size:13px; font-weight:700; text-transform: lowercase !important;}
[data-prop$="tmp/reproductions"] > :nth-child(1) { order:2; padding-left:5px; }
[data-prop$="tmp/reproductions"] > :nth-child(2) { order:1; }

iframe.ontoSearch { width:calc(100% - 10px); border:none; margin-left:10px; height:500px;
   box-shadow:0 0 5px rgba(0,0,0,0.5); background-color:rgba(0,0,0,0.015);}

.data .anno,svg.annoticon { vertical-align: -9px;margin-left:5px; color:rgba(0,0,0,0.0); display:none;}
h2:hover svg.annoticon,h4:hover svg.annoticon {  color:rgba(0,0,0,0.5); cursor:pointer;}
h2:hover svg.annoticon:hover,h4:hover svg.annoticon:hover { color:rgba(0,0,0,0.65); }
.data .anno span:not(.lang) {/*color : rgba(229, 74, 53,1);*/ cursor:pointer; color:rgba(0,0,0,0.5);}
.data .anno span:not(.lang):hover { text-decoration:underline; color:rgba(0,0,0,0.65); }

.data .faded { display:inline-block; cursor:pointer; padding:3px; border-radius:16px;
   background-color:rgba(0, 0, 0, 0.1);
}
.data .faded.score0 { background-color:rgba(255, 205, 3, 0.4);border:1px dashed rgba(167, 134, 1, 0.4);}
.data .faded.score1 { background-color:rgba(17, 173, 17, 0.3);border:1px solid rgba(36, 106, 36, 0.4);}

/* ------------------------------------------------------------------------------------------------------------------------ resource */

h4 { margin-top:0px;margin-bottom:0px;font-size:24px; }
/* h4 + h4 { margin-top:5px;}  */

/* .resource .data h4.label,.resource .data .ulink, .resource .data .urilink,.resource .data h4 span[lang] { letter-spacing:0.25px; } */

[lang$="ewts"] { text-transform:none !important; }


h2:not(:hover) .lang,
h4:not(:hover) > .lang
{ display:none; }

.resource .loader { position:absolute; }
.resource #fond .loader { position:static; }

.H100vh { height:100vh;}
.OF0 { overflow:hidden; }
.PE0 { pointer-events:none; cursor:auto; }


.over-nav ~ .H100vh { z-index:1000000; position: relative; }


#root > div > .resource.simple { cursor:pointer; padding-top:5px; }
#root > div > .resource.simple > *    { pointer-events:none; }


#root > div > .resource.preview .mobile-button,
#root > div > .resource.simple .mobile-button { display:none; }


#root > div > .resource.preview { padding-top:5px; }

#root > div > .resource.preview > .index + div { background:white; }

.resource.preview .data.ext-props,
.resource.preview .data.legal-props { padding-top:20px;}
.resource.preview .data#perma { padding-bottom:20px;}
.resource.preview .data { padding-bottom:0px;}


.resource.preview .data#map { padding: 0 15px 15px 15px; }
.resource.preview .data#map .header { padding:0; width: 100%; }
.resource.preview .data#map .header .map .placeMap { height:200px; }

.resource.preview .data#first-image {  height:70px; width:70px; display: inline-block;  padding:20px;}

.resource.preview .data .firstImage { height:70px; width:70px; min-height:70px; background:#fce08d; pointer-events: none; display: inline-block; box-sizing: border-box;}
.resource.preview .data .firstImage img { width:65px; height:65px; object-fit: cover; object-position: center; }
.resource.preview .data .firstImage .loader { width:100%; height:100%; box-sizing: border-box; filter:invert(1); margin-left:-5px; margin-top:-5px}

.resource.preview .data#first-image,
.resource.preview #head { float:left; }


.resource.preview .data .images-title { display:inline-flex; line-height: 16px; }

.resource.preview .data .images-thumb, 
.resource.preview .data [data-prop="bdo:workHasInstance"] .images-thumb,
.resource.preview #head .header { height:70px; width:70px; box-sizing: border-box; }


.resource.preview .data .images-thumb, 
.resource.preview .data [data-prop="bdo:workHasInstance"] .images-thumb { margin-bottom:5px; }

.resource.preview #head .header::before { width:55px; height:55px; }
.resource.preview #head + div + div { clear:both; }

.resource.preview.place #head .header::before { background-size:35%;}

.resource.preview :is(#head,#first-image) + div { padding:15px 15px 15px 0; min-height:70px; display: flex; flex-direction: column; justify-content: center;
   /*justify-content: space-between; flex-wrap:wrap; align-items: center; align-content: center*/;
}

.resource.preview .data.ext-props:last-child > div:last-child { padding-bottom:25px; } 

.resource.preview :is(#head,#first-image) + div h2.on.intro+h2 a { line-height: 0;}

.resource.preview :is(#head,#first-image) + div h2.on.intro+h2 span { line-height: 16px;}

.resource.preview :is(#head,#first-image) + div h2.on+h2,
.resource.preview :is(#head,#first-image) + div h2.on { line-height: 16px; height:auto; font-size: 0; align-items: center; display: flex; padding-left:0; min-height:18px;}

.resource.preview :is(#head,#first-image) + div h2.on.intro,
.resource.preview :is(#head,#first-image) + div h2.on+h2 span,
.resource.preview :is(#head,#first-image) + div h2.on span { font-size:13px !important; }
.resource.preview #head + div h2.on ~ .date { margin-top:0px; display: flex; font-size: 0;  align-items: center; padding-left: 0; }
.resource.preview #head + div h2.on ~ .date span { font-size:13px; }
.resource.preview :is(#head,#first-image) + div h2.on ~ a { display: inline-flex; width: auto; font-size:13px; position: relative; height:18px; align-items: center;}
.resource.preview :is(#head,#first-image) + div h2.on ~ a:hover { border:none; text-decoration: underline; }
.resource.preview :is(#head,#first-image) + div h2.on ~ a img { min-height:24px;margin-top:0px; }

.resource.preview .data.top-props, 
.resource.preview .data.bot-props { padding-left: 15px;}

.resource.preview .data h4 { font-size: 12px; }

.resource.preview .data > div > h3 { margin-bottom:0px; font-size:12px; line-height:16px;}
.resource.preview .data > div > h3 a.propref { font-size: 12px;}
/* .resource.preview .data > div > h3 + .group:has(.sub) { margin-top:5px; } */

.resource.preview .data > :not([data-prop="bdo:note"]) .sub:not(:last-child) { margin-bottom: 5px; }

.resource.preview .data > [data-prop="bdo:note"] > div, 
.resource.preview .data > div .sub > .subgroup > .subsub > h4.first, 
.resource.preview .data .propCollapseHeader > .sub > h4.first, 
.resource.preview .data .propCollapse > div > div > .sub > h4.first, 
.resource.preview .data > [data-prop="bdo:creator"] .subgroup .subsub:first-child, 
.resource.preview .data > div > .group > .sub > h4.first { margin-bottom: 0px; font-size:12px; line-height:16px;}


.resource.preview .data > div .sub > .subgroup > .subsub > h4.first a.propref,  
.resource.preview .data .propCollapseHeader > .sub > h4.first  a.propref, 
.resource.preview .data .propCollapse > div > div > .sub > h4.first  a.propref, 
.resource.preview .data > div > .group > .sub > h4.first  a.propref { font-size:12px; }

.resource.preview .data > div > h3 a.propref  { font-weight:600; }


.resource.preview .data > [data-prop="bdo:note"] .sub h4 { font-size: 12px !important; }

.resource.preview .data#outline > h2,
.resource.preview .data#outline div h2, 
.resource.preview .data#outline div span:not(.lang),
.resource.preview .has-collapse.custom .expand,
.resource.preview .data > [data-prop="bdo:note"] .sub > h4 + div:not(.subsub),
.resource.preview .data > [data-prop="bdo:note"] .sub > h4.first,
.resource.preview .data > [data-prop="bdo:note"] .sub,
.resource.preview .data.legal-props > div h4,
.resource.preview .data.ext-props > div h2,
.resource.preview .sub > h4.label:not(:first-of-type) { font-size:12px; line-height:16px;}


.resource.preview .data.ext-props > div h2 { margin:25px 0 0 0;}

.resource.preview .data#outline > h2 { margin-left:0 }

.resource.preview .data#outline > h2::after,
.resource.preview .data.ext-props > div h2::after { content:":"; }

.resource.preview .data.ext-props + .data.ext-props > div { padding-left:40px;}
.resource.preview > div:has(.ext-props > div > h2:last-child):has(.ext-props + .ext-props:last-child >  a + div:empty)
      .data.ext-props { display:none; }

.resource.preview > div:has(.ext-props > div > h2:last-child):has(.ext-props + .ext-props:last-child >  a + div:empty) { padding-bottom:20px;}

.resource.preview .group > h4:first-child:not(.first), 
.resource.preview .group > h4.multiple .urilink, 
.resource.preview .sub > h4.label, 
.resource.preview .sub h4.first, 
.resource.preview .data > div > h3 ~ .group > h4.multiple,
.resource.preview .subsub,
.resource.preview .data > div > .group { line-height:16px; }

.resource.preview .data.ext-props > a:first-child { display: none;}

.resource.preview .data.ext-props:last-of-type:not(#perma) > div:not(.firstImage):not(.browse):not(.header)::after { height:0; }

/* .resource.preview .subsub { margin-bottom:3px; } */

.resource.preview .data .sub.keep { margin-top:-5px; }

.resource.preview h4.multiple,
.resource.preview .data > div > h3 ~ .group > h4.multiple { margin:3px 0 0 0 !important; }

.resource.preview .data.legal-props > div h3 { margin-bottom:10px; }


.resource.preview .data > [data-prop="bdo:note"] .sub > h4 + div.subsub { padding-left:0; }
.resource.preview .data > [data-prop="bdo:note"] .sub { padding-right:0; width:100%; }


.resource.preview .data:not(#perma) > div:not(.firstImage):not(.browse):not(.header)::after { margin: 10px 0 10px -10px; }
.resource.preview .data.ext-props:not(#perma) > div:not(.firstImage):not(.browse):not(.header)::after { margin: 0; }

.resource.preview.hasTabs .title { display:none; }
.resource.preview.hasTabs > .index + div { top: 0 !important; }

.resource.preview .data > [data-prop="bdo:note"] > .group > div:not(.no-collapse) .sub:last-child, 
.resource.preview .data > [data-prop="bdo:note"] .sub:not(:last-child) { margin-bottom:10px;  }

.resource.preview .data:last-child > [data-prop]:has(+ :not([data-prop])) { padding-bottom:25px; }

div:has(.resource.preview) .infoPanel.inRes { margin:0 !important; }

.resource.preview > .ariane { display:none; }

.resource.preview .data#perma > div { flex-direction: row; justify-content: space-between;}
.resource.preview .data#perma > div > #rid { margin: 10px 0;}
.resource.preview .data#perma > div > :not(#rid,#permalink) { display: none; }



.resource .fairuse-IA-link {
   display:inline-flex; 
   align-items:center; 
   justify-content:center;
}

.resource .fairuse-IA-link img.ia { width: 19px; margin-right:10px; display: inline-block;}
.resource .fairuse-IA-link img.link-out { margin-left:5px; display: inline-block; }

.resource .fairuse-IA-link:hover { text-decoration: underline; border:none !important; }

/* -+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ resource */


.open-etext { position:absolute; right:140px; margin-top:0px; z-index:10;}
.open-etext > div { }
.open-etext > div::after { content:none !important; }
.open-etext + .data h2 { max-width:calc(100% - 30px); }  /*- 150px */ 
.browse-by a,
.open-etext > div a { color:white !important; background-color: #d73449; border-radius:5px;display:flex;align-items: center;justify-content: center; height:40px;
   padding: 0 15px; border:none !important; font-weight:600; font-size:13px; cursor:pointer;
}
.open-etext.disable > div a { opacity:0.5; pointer-events: none; }

.browse-by { margin-top:10px; }
.browse-by::after { content:none !important ;}
.browse-by img { filter: invert(100%) brightness(135%); width:27px; display: inline-block; margin-right:13px; }


.resource > .index { width:15%; padding-top:10px; position:fixed; }
.resource:not(.etext-view):not(.static) > :not(.index):not(.ariane):not(.simplebar-track) { width:85%; margin-left:15%; /*position:relative;*/ }

.resource > .ariane { position:absolute; top:105px; color: #343434; left:0; margin-left:140px;padding-left:12.125%; }
.resource > .ariane img { display:inline-block; margin-right:5px; vertical-align: -1px;}
.resource > .ariane a { text-decoration: none; color: #343434; font-weight:500; font-size:13px; }
.resource > .ariane a span { display:inline-block; }
.resource > .ariane a > span::first-letter { text-transform: capitalize;}
.resource > .ariane a:hover span { text-decoration: underline;}
.resource > .ariane a span span { font-weight:700; }

.resource > .index .title { display:block; }

.resource > .index .title h2 { max-width:100% !important; width:100% !important; background:none !important; box-shadow:none !important;height:auto; 
   display:block !important; font-size:14px; font-weight:700;
}

.resource > .index .title h2:not(:first-child) { margin-top:20px; }

.resource > .index .title h2 a { display:block; }
.resource > .index .title h2 .T::before { content:none !important; width:40px; background-size:80% auto; background-position:center; margin-right:16px;}
.resource > .index .title h2 .RID { display:none !important; }

.resource > .index h3 { /*margin-left:56px;*/ margin-top:8px; font-size:12px; letter-spacing:0px; display:block;}

.resource > .index h3 a { color:#343434; text-decoration: none; }

/* .resource > :not(.index) > .title h2 .T { margin-bottom:15px; } */
/* .resource > :not(.index) > .title h2 .T::before { display:none; } */
/* .resource  > :not(.index) > .title h2 .RID { left:0; } */

.resource > .index .disabled { pointer-events: none; opacity:0.5; }

#main-info, #resources, #ext-info {
   scroll-margin: 100px;
}
#ext-info > div::after { content:none !important;}

#resources:last-child { padding-bottom:35px; }

/* ------------------------------------------------------------------------------------------------------------------------ static pages */

.static-container > #samples { padding-top:65px;}
.static-container > #samples > div { padding-left:75px; padding-right:75px; }
.static-container #samples a { color:#d73449;}
.static-container #samples a:hover { text-decoration: underline;}

.static-container #samples h3:not([id]) { margin-bottom:32px;}
.static-container #samples p + h3:not([id]) { margin-top:65px;}

.nav.zhMirror a#about { margin-right:0; margin-left:20px;  }
.nav.zhMirror a#about + a { margin-left:-95px;  }


.budax .static-container .center { text-align: center; }

.budax .static-container .hide.top { position:relative; overflow:hidden; display:block;}
.budax .static-container .hide.top iframe { border:none; z-index:-1; display:block; margin-top:-150px; margin-bottom:-150px;}
/* no need for this actually: */
/* .static-container .hide.top::before { content:'';display: inline-block; background:white;width:100%;height:145px;position: absolute;}  */

.budax .static-container .over,
.budax .static-container h2 { z-index:1; position:relative; }

.budax .static-container #samples h1 { font-size:32px; }
.budax .static-container #samples h2 { font-size:28px; }
.budax .static-container #samples h3 { font-size:24px; text-align:left;}
.budax .static-container #samples h4 { font-size:20px; }

.budax .static-container iframe,
.budax .static-container img { max-width:100%; } 

@media only screen and (max-device-width:1440px) {
   .budax body { min-width:840px; }
   .budax .static-container #samples > div { padding:0 20px;}

   .budax .static-container #samples .hide.top iframe { margin-top:-100px; margin-bottom:-130px;  }
}


/* ------------------------------------------------------------------------------------------------------------------------ outline */

.resource .data#outline div .node-nav:not(#a) {
   display:inline-block;
   text-transform: uppercase;
   color: white;
   background-color: #d73449;
   font-size: 10px;
   font-weight: 700;
   padding: 2px 4px;
   border-radius: 3px;
   cursor: pointer;
}


.resource .data#main-info,
.resource .data#outline { scroll-margin:80px; }

.resource .data#outline { background-color: #ffffff; padding: 35px; padding-bottom: 0; padding-top:45px; }
.resource .data#outline > h2 { margin-left:35px; margin-bottom:35px; }
.resource .data#outline > div { background-color: #fcf5e2; padding: 25px 20px; }
.resource .data#outline > div.loader { background-color: transparent; padding: 0;  }

.resource .data#outline > .search { background-color: #fce08d; justify-content:center; padding:20px; position:relative; }
.resource .data#outline > .search > div { display:inline-block; position: relative;}
.resource .data#outline > .search::after { content:none !important; }
.resource .data#outline > .search input { display:inline-block;width:375px;height:40px;line-height:40px;font-weight:600;border:none;outline:none; 
   padding-left:20px; color:black; box-shadow: 0 2px 4px rgba(187, 187, 187, 0.5); padding-right:70px; position:relative;   
}
.resource [data-prop="tmp:outlineSearch"] div input ~ span,
.resource .data#outline > .search input ~ span { display: inline-block; position:absolute; height:40px;width:40px; cursor:pointer; border-radius:50%; }
.resource [data-prop="tmp:outlineSearch"] div input + span,
.resource .data#outline > .search input + span { background-image:url(/icons/loupe.png); background-position:center; background-repeat:no-repeat; 
   right:4px; top:8px; z-index:1000;  opacity:0.65;
}

.resource [data-prop="tmp:outlineSearch"] div input + span:hover::before,
.resource .data#outline > .search input + span:hover::before{
   background-color:#ccc5;
   mix-blend-mode: darken;
   content:'';
   width:100%;
   height:100%;
   position: absolute;
   border-radius: 50%;
}

.resource [data-prop="tmp:outlineSearch"] div input + span + span,
.resource .data#outline > .search input + span + span { right:42px; display:inline-flex; align-items: center;justify-content: center; width:40px; height:40px;top:8px; z-index:1000;
   box-sizing: border-box;
}


.resource [data-prop="tmp:outlineSearch"] div input + span + span:hover,
.resource .data#outline > .search input + span + span:hover {
   background-color:#ccc5;
   border-radius: 22px 22px;
}

.resource .data#outline > .search input.disabled { pointer-events: none; background:#f9f9f9; }
.resource .data#outline > .search input.disabled ~ span { pointer-events: none; opacity:0.4; }

.resource .data#outline:last-child { padding-bottom:45px; }
.resource .data#outline div { flex-wrap:wrap; }
.resource [data-prop="tmp:outlineSearch"] div span:not(.lang),
.resource .data#outline div h2,
.resource .data#outline div span:not(.lang) { font-size:14px; font-weight:400; color:#343434; }
.resource .data#outline .root >  span { width:auto;display:inline-block;} /*{ width:calc(100% - 25px); display:block; }*/
.resource .data#outline .top.is-root .ulink a
/* 
.resource .data#outline .on h2 span, 
.resource .data#outline .on > span:not(.pType) span 
*/
{ font-weight:700 !important;  }
.resource .data#outline span a { color: #343434; display:flex; line-height:20px; }
.resource .data#outline .root > .parTy + .on:not(#a) a span { 
   color:#343434;
}
.resource .data#outline .root > .parTy + .on:not(#a) a:hover span,
.resource .data#outline span a:hover { border:none; 
   /* text-decoration: underline; text-decoration-color: #343434;  */
   color:black ;
}
.resource .data#outline span .lang { display:none; }
.resource .data#outline div img.xpd { width:7px; height:5px;margin-right:0px;  cursor:pointer; opacity:1; padding:5px; top:0;left:0;}
/* .resource .data#outline div svg.xpd { width:20px; margin-right:5px;  cursor:pointer; opacity:0.75;} */
.resource .data#outline div span.xpd { position:absolute; margin-left:-15px;font-size:20px;font-weight:900;opacity:0;pointer-events:none;margin-top:-4px;
   cursor:pointer;
}
.resource .data#outline div span.xpd svg { width:16px;}
.resource .data#outline .root span.xpd { margin-top:-1px;}
/* .resource .data#outline .is-root > span.xpd { color: #d73449;} */
.resource .data#outline .root:hover span.xpd,
.resource .data#outline span.top:hover span.xpd { opacity:0.65;pointer-events:auto;} 

.resource .data#outline div::after { content:none !important;}
.resource .data#outline div h2:not(.on) .T { display:none; }
.resource .data#outline div .root { width:100%; display:flex; flex-wrap: nowrap;}

/* .resource .data#outline span.is-root .parTy + span a:hover { text-decoration-color:#d73449;} */

.resource .data#outline .parTy + span .ulink .before  { display:none !important; }


.resource .data#outline span.is-root #print,
.resource .data#outline span.is-root #cite,
.resource .data#outline .is-root h2 a span,
.resource .data#outline span.is-root a  { color:#d73449;  }


.resource .data#outline span.is-root #print svg,
.resource .data#outline span.is-root #cite svg { border-color:#d73449 !important;  } 

.resource .data#outline .is-root a:hover {
   text-decoration:none;
    /* text-decoration-color: #d73449; */
}

.resource .data#outline .root img.xpd { margin-top:3px; }
.resource .data#outline img.xpd.right { transform:rotate(90deg); }
/* .resource .data#outline .is-root svg.xpd { color:#d73449; margin-top:-2px; } */
.resource .data#outline .root + div > span { display:flex; position:relative; }
.resource .data#outline .root + div .xpd { position:absolute; left:-19px; top:3px; }
.resource .data#outline .root + div span{ position:relative; }

.resource .data#outline .loader { width: 100%; top: 50%; }


.resource .data#outline .root + div .ulink { width:auto; }
.resource .data#outline .root + div .pType a { pointer-events: none; }
.resource .data#outline .root + div .pType span { font-size:10px; font-weight:700; opacity:0.65; flex-shrink:0; }

.resource .data#outline .root + div .permalink::before,
.resource .data#outline .root + div .hasImg::before,
.resource .data#outline .root + div .pType span:before { content:'';width:1px;/*border-left:1px solid #343434;*/display: inline-block;height:16px;
   vertical-align:middle; /*padding-left:6px;*/margin-left:0px; margin-bottom:1px; 
}

.resource .data#outline .root + div #print,
.resource .data#outline .root + div #cite { margin-left:5px !important; display: inline-block; }
.resource .data#outline .root + div #print svg,
.resource .data#outline .root + div #cite svg { width:14px !important; height:14px; border:1px solid #343434; border-radius:50%; }

.resource .data#outline .root + div #print svg { border:none; width:19px! important; height:19px;}

.resource .data#outline .root + div #print,
.resource .data#outline .root + div #cite,
.resource .data#outline .root + div .permalink {  opacity:0; margin-left:0px; cursor:pointer; }
.resource .data#outline .root + div .top:hover #print,
.resource .data#outline .root + div .top:hover #cite,
.resource .data#outline .root + div .top:hover .permalink  {  opacity:0.8; }
.resource .data#outline .root + div #print svg,
.resource .data#outline .root + div #cite svg,
.resource .data#outline .root + div .permalink img { width:16px; vertical-align: middle !important; }
.resource .data#outline .root + div .top:hover #print:hover,
.resource .data#outline .root + div .top:hover #cite:hover,
.resource .data#outline .root + div .top:hover .permalink:hover { opacity:1; }

/*
.resource .data#outline .root + div .hasImg { opacity:0; }
.resource .data#outline .root + div .top:hover .hasImg { opacity:1; }
*/

.resource .data#outline h4:hover::before { content:none !important;display:none;}
/*
.resource .data#outline h4 .of + *,
.resource .data#outline h4 .of { display:none ; }
*/

.resource .data#outline .root + div .permalink::before,
.resource .data#outline .root + div .hasImg::before { opacity:0.65;}
.resource .data#outline .root + div .hasImg img { width:18px; opacity:0.8; vertical-align: middle !important; }
.resource .data#outline .root + div .hasImg.hasTxt img { width:17px; }

.resource .data#outline .root + div .hasImg.hasTxt[disabled] { cursor:auto; }
.resource .data#outline .root + div .hasImg.hasTxt[disabled] img { opacity:0.8 !important; }

.resource .data#outline .root + div .top.is-root .permalink img:first-child,
.resource .data#outline .root + div .top:not(.is-root) .permalink img:last-child,
.resource .data#outline .root + div .top.is-root .hasImg img:first-child,
.resource .data#outline .root + div .top:not(.is-root) .hasImg img:last-child { display:none;}

.resource .data#outline .root + div .top.is-root #print,
.resource .data#outline .root + div .top.is-root #cite,
.resource .data#outline .root + div .top.is-root .permalink,
.resource .data#outline .root + div .top.is-root #anchor,
.resource .data#outline .root + div .top.is-root #anchor img,
.resource .data#outline .root + div .top.is-root .hasImg img { opacity:1; }

.resource .data#outline .root + div .top.is-root .permalink img:last-child,
.resource .data#outline .root + div .top.is-root .hasImg img:last-child { display:inline;}

.resource .data#outline .root + div .top:not(.is-root) #anchor img { filter: grayscale(); }

.resource .data#outline .root + div .hasImg:hover img { opacity:1; }

.resource .data#outline  .parTy + span { margin-right:5px;}

.resource .data#outline div .id { padding-right:6px; font-size:14px !important; font-weight:600 !important; }
.resource .data#outline div .id::after,
.resource .data#outline div .id::before { content:'['; padding:0 2px; font-size:12px!important;vertical-align:1px;}
.resource .data#outline div .id::after { content:']'; }

.resource .data#outline .is-root .id { color:#d73449; }

/*
.resource .data#outline .is-root .abs img:not(#a):not(#b){ filter:grayscale(1); }
.resource .data#outline .is-root .abs svg:not(#a):not(#b):not(:hover),
.resource .data#outline .is-root .abs img:not(#a):not(#b):not(:hover) { opacity:0.75; }
*/

.resource .data#outline .root + div .abs { position:absolute; width:110px;}

.resource .data#outline .root + div span.top { display: block;  margin-top:5px; scroll-margin:calc(50vh - 50px); }

.resource .data#outline .root { position:relative;}

.resource .data#outline .root::before,
.resource .data#outline .root + div span.top::before { content:'';display:block;position:absolute;width:50px;height:20px;left:-50px;top:0; }
.resource .data#outline .root + div > .top:first-child { margin-top:3px; }
.resource .data#outline .root + div span.top :not(.lang) { display:inline; vertical-align:top; }


.resource .data#outline .is-root { scroll-margin: calc((100vh - 80px) /2); }

.resource .data#outline  .parTy.on { scroll-margin:calc((100vh - 2 * 60px) / 2); }

.resource .data#outline .root.is-root .parTy + span > h2 > a:hover,
.resource .data#outline .top:not(.is-root) .parTy + span > .ulink > a:hover { text-decoration: none; }
.resource .data#outline .top:not(.is-root) .parTy + span > .ulink > a:hover { text-decoration: none; color:black;}

.resource .data#outline .parTy div { text-transform: uppercase; font-size:11px; font-family:"Noto Sans SemCond","Noto Sans", "Noto Serif Tibetan", "Noto Serif Khmer", "Noto Sans Mongolian", "Roboto", "Helvetica", "Arial", sans-serif !important;  opacity:1; 
   /*padding:4px 1px 0 1px; text-align: center;  margin-top:-2px; */
}
/* .resource .data#outline .is-root .parTy:not(#a).on { border-color:rgba(215, 52, 73,1); } */
/* .resource .data#outline .is-root .parTy:not(#a):not(.on) { border-color:rgba(215, 52, 73,0.5); } */
/* .resource .data#outline .is-root .parTy div { color:#d73449; } */

.resource .data#outline .parTy:not(#a) { font-size:0;padding-bottom:3px; padding-left:3px; padding-right:3px;box-sizing: border-box; vertical-align: 2px;
   display:inline-flex; align-items: flex-end; justify-content: center; margin-right:7px; width:26px;height:20px; background: rgba(0,0,0,0.15); 
}
.resource .data#outline .parTy:not(#a).on { cursor:pointer; font-weight:700; background:rgba(0,0,0,0.15);}
.resource .data#outline .parTy.on:hover { text-decoration: underline; /*text-decoration-color: #d73449;*/ }
.resource .data#outline .parTy.on div { opacity:1.0;}


.resource .data#outline .root:not(.is-root) .parTy,
.resource .data#outline .top:not(.is-root) .parTy { text-decoration-color:rgba(0,0,0,0.65) !important; }

.resource .data#outline .root + div #anchor { margin-left:0px; opacity:0; cursor: pointer; }
.resource .data#outline .root + div #anchor img {  opacity:0.75; width:24px;  display:inline-block;margin-top:-2px;}
.resource .data#outline .root + div .top:hover #anchor { opacity:1; }
.resource .data#outline .root + div #anchor:hover img { opacity:0.9; }

.resource .data#outline .root + div .pType.on { cursor:pointer; }
.resource .data#outline .root + div .pType.on a { text-decoration:underline; text-decoration-color:rgba(0,0,0,0.65);}

.resource .data#outline .root + div .pType svg.details { width:14px; opacity:0.65; display:none; vertical-align:bottom;height:18px; }
.resource .data#outline .root + div .top:hover .pType.on svg.details { display:inline; }

.resource .data#outline .root + div { width:calc(100% - 25px - 50px - 35px); }
.resource .data#outline .root + div .top + .details { width:100%; background-color:#ffffff80; margin:5px 25px 7px 33px; padding:15px 15px 15px 20px; scroll-margin:calc(50vh - 30px);}


.resource .data#outline .root + div .top + .details .sub { margin:0;border:none;padding:0; display:flex; flex-wrap:wrap; /*align-items:baseline;*/}
.resource .data#outline .root + div .top + .details .sub + .sub { padding-top:10px; }
.resource .data#outline .root + div .top + .details .sub.hideT { padding-top:5px;}
.resource .data#outline .root + div .top + .details .sub.hideT h4.first { visibility: hidden; }
.resource .data#outline .root + div .top + .details .sub h4,
.resource .data#outline .root + div .top + .details .sub a,
.resource .data#outline .root + div .top + .details .sub h4 span { font-size:12px;line-height:16px; }
.resource .data#outline .root + div .top + .details .sub h4.first { width:120px; align-self: flex-start; }
.resource .data#outline .root + div .top + .details .sub h4.first span { font-weight:700;}
.resource .data#outline .root + div .top + .details .sub h4.first + * { width:calc(100% - 180px); }

.resource .data#outline .root + div .top + .details .sub h4.first ~ h4.multiple { width:calc(100%); margin-bottom:0; padding-left:155px; }
.resource .data#outline .root + div .top + .details .sub h4.first ~ h4.multiple:nth-child(2) { width:calc(100% - 180px); padding-left:0px; }

.resource .data#outline .root + div .top + .details .sub h4 { padding-right:20px; /* vertical-align: -100%; */ }
.resource .data#outline .root + div .top + .details .sub .lang { display:none; } 
.resource .data#outline .root + div .top + .details .sub h4:not(.first) a { color:#d73449;} 
.resource .data#outline .root + div .top + .details .sub h4:not(.first) a:hover { text-decoration-color: #d73449;} 

.resource .data#outline .root + div .top + .details .hover-menu { top:-4px; }

.resource .data#outline .root + div .top + .details .sub.view a { border:none;font-weight:700; }
.resource .data#outline .root + div .top + .details .sub.view a:hover { text-decoration:underline; }
.resource .data#outline .root + div .top + .details .sub.view::before,
.resource .data#outline .root + div .top + .details .sub.view span { font-size:14px; padding:0 5px; font-weight:700;color:#d73449;}
.resource .data#outline .root + div .top + .details .sub.view::before { content:">";padding:0 5px 0 0; }

.resource .data#outline .root + div .top + .details .sub.view a[disabled] { pointer-events: none; opacity:0.65; }

.resource .data#outline .root + div .top + .details .sub.loca .urilink { display:inline; }
.resource .data#outline .root + div .top + .details .sub.loca .urilink span { color:#d73449;}

.resource .data#outline .root + div .top + .details .sub h4.first + div { display:flex;}

.resource .data#outline .root + div .top + .details .sub h4.first + div h4:not(:first-child) { margin-top:5px;}

.resource .data#outline .root + div .top + .details .sub h4 .highlight { padding:0; background:none; padding:1px 3px; background-color: #fce08e;}
/*
.resource .data#outline .root + div .top + .details .sub h4 .highlight::before { background-color: #fce08e;font-weight:700; height:100%;
   border-radius:2px;padding:3px 3px; position:relative;z-index:-1; position:absolute; content:' ';display:inline-block; width:100%; left:-5px; top:-3px;
   margin:0 3px;
}
*/

.urilink[data-info*="+"]:not([data-info*=" "]) { word-break: break-all;}

.resource .data#outline .root + div .top + .details .sub h4 .highlight:first-child { margin-left:0; }
.resource .data#outline .root + div .top + .details .sub h4 .highlight:first-child::before  { margin-left:0; }

.resource .data#outline .outline-warn { color: #d73449; display: inline; vertical-align: middle;}
.resource .data#outline .outline-warn svg { vertical-align: middle;  font-size:19px; margin-left:5px;}

#outline a.no-bdrc img { vertical-align: -7px;}
#outline .hover-menu { display:none; }

#outline #same { cursor:pointer;}
#outline #same > span:not(:last-child) { padding-right:5px; }
#outline #same > span .img { font-weight:600; }
#outline #same > span.sat .img { color:green; }

#outline #same .provider::before { content:none; }


.resource .data#outline.etextrefs .is-root h2 a span { color: #343434; cursor:pointer;}


.resource .data#outline.etextrefs > .search:not(.on):not(#a) { display:none !important; }

/* .resource .data#outline.etextrefs > .search.on { background-color:#d73449; } */

.resource .data#outline.etextrefs .search + h2 { margin-top:70px;}

/* ------------------------------------------------------------------------------------------------------------------------ monlam*/

/* @import "https://monlamdictionary.com/files/css/basic.css"; */


.resource ~ .ETSBresults,
.resource ~ .monlamResults { width:0px; position:relative; flex-shrink: 0; } 


.resource ~ .ETSBresults:not(.visible),
.resource ~ .monlamResults:not(.visible) { overflow: hidden;}

.resource ~ .ETSBresults > div,
.resource ~ .monlamResults > div { width:0px; height:calc(100% - 2 * 80px); position:fixed; right:0; top:80px; background:white; overflow:auto; 
   box-shadow:-2px 0 3px rgba(0,0,0,0.15);
   /* background-image:url(/icons/monlam.png); background-repeat: no-repeat; background-size:100px; background-position: center; */
}

.resource ~ .monlamResults > div > a:first-child { position: absolute; right:5px; top:0; padding:0; opacity:0.5; transition: all 100ms ease-in-out;}
.resource ~ .monlamResults > div > a:first-child:hover { opacity:1; }

.resource ~ .monlamResults > div .monlam-no-result { display:flex; align-items: baseline; padding:0 15px;} 
.resource ~ .monlamResults > div .monlam-no-result > span { white-space: nowrap; max-width:200px; text-overflow: ellipsis; overflow: hidden;  }

.resource ~ .ETSBresults.visible,
.resource ~ .ETSBresults.visible > div,
.resource ~ .monlamResults.visible,
.resource ~ .monlamResults.visible > div { width:400px; }

.resource ~ .monlamResults .simplebar-content > h2,
.resource ~ .monlamResults .def { padding:15px; font-size:15px; }

.resource ~ .monlamResults .def b[lang='bo'] { line-height:32px; }

.resource ~ .monlamResults .react-tabs { padding-bottom: 20px; }

.resource ~ .monlamResults .react-tabs li { display: inline-flex; max-width:100%; box-sizing: border-box; font-size:15px; padding-left:15px; margin-bottom:10px; cursor: pointer;}
.resource ~ .monlamResults .react-tabs li.on { font-weight: 600; margin-bottom:0; text-decoration: underline;}
.resource ~ .monlamResults .react-tabs li:hover { text-decoration: underline; }
.resource ~ .monlamResults .react-tabs li.react-tabs__tab--disabled { pointer-events: none;}

.resource ~ .monlamResults .react-tabs__tab-panel { padding-left:15px;  }

/*
.resource ~ .monlamResults pre { white-space: pre-wrap; margin:0; }
.resource ~ .monlamResults pre:empty { display: none;}
*/

.resource ~ .monlamResults div .def  { margin-bottom:0px; line-height:24px; padding-bottom:15px; }
.resource ~ .monlamResults div .def > div {
   -webkit-user-select: none; /* Safari */
   -ms-user-select: none; /* IE 10+ */
   user-select: none;
}
.resource ~ .monlamResults div .def > b { /*color:#09b;*/ display: inline-flex; align-items: center; cursor: pointer; /*text-decoration: underline;*/ font-weight:500; color:#152631;}
.resource ~ .monlamResults div .def > b > svg { transform: rotate(270deg); margin-top:0px;}
.resource ~ .monlamResults div .def > b > svg.on { transform: rotate(0deg); margin-top:0px;}
.resource ~ .monlamResults div .def > b > span > .highlight,
.resource ~ .monlamResults div .def > b > span > .kw { background:rgba(252,224,141,0.65); }
.resource ~ .monlamResults div .def > b > span { font-size:115%; }
.resource ~ .monlamResults div .def > b[lang='bo'] > span { font-size:135%;}

.resource ~ .monlamResults div .def > b + div .kw { background:rgba(252,224,141,0.65); }


/*
.resource ~ .monlamResults div .def > b[lang='bo'] > svg { margin-top:-5px;}
.resource ~ .monlamResults div .def > b[lang='bo'] > svg.on { margin-top:-5px;}
*/

.resource ~ .monlamResults div .def .collapse-on { overflow:visible;  }

.resource ~ .monlamResults div .def b[lang="bo-x-ewts"] ~ div img[align="middle"] { vertical-align: text-bottom;}

.resource ~ .monlamResults div .def b[lang="bo-x-ewts"] ~ div .perna.ac-ac { line-height:24px; }

.resource ~ .monlamResults div .def .perna.ac-ac:first-child,
.resource ~ .monlamResults div .def br:first-child + .perna.ac-ac { margin-top: 0;}

.resource ~ .monlamResults div .def br:first-child,
.resource ~ .monlamResults div .def .spc { display:none; }
.resource ~ .monlamResults div .def img+.spc { display:inline; }

.resource ~ .monlamResults div .def p.gap { margin-top:12px; }  
.resource ~ .monlamResults div .def p.gap:first-child { margin-top:0px; }  

.resource ~ .monlamResults div .def > div > div > div > div > div > div { padding-left:15px; display: block; padding-top:12px; }

/* .resource ~ .monlamResults div .def:first-child { padding-top:30px; } */

.resource ~ .monlamResults div .def:last-child { padding-bottom:30px; }

.resource ~ .monlamResults div .def .numbers,
.resource ~ .monlamResults div .def .numbers2 { width:18px; height:18px; padding:0; display:inline-flex; justify-content: center; align-items: center; top:0; vertical-align: middle;}


#root > .withETSBresults,
#root > .withMonlam { display: flex; }

#root > .withETSBresults > .resource.etext-view,
#root > .withMonlam > .resource.etext-view { width:calc(100% - 400px - 30px * 2); }

#etext-nav #dico { margin-left:auto; position:relative; }
#etext-nav #dico img.ico { width:42px; display: inline-block; vertical-align: -15px;}

#etext-nav #dico .new { display: block; position: absolute; top:calc(50% - 5px); right:calc(100% + 7px); font-size:10px; text-transform: uppercase; line-height: 12px; 
   font-weight: 600; background:#d73449; border-radius: 2px; padding:2px 4px 2px 4px; box-sizing: border-box; width: max-content;
   font-family:"Noto Sans","Noto Serif Tibetan";
}
#etext-nav #dico:hover .new { text-decoration: none !important; }

[lang='bo'] #etext-nav #dico .new { padding:3px 3px 8px 3px; top:calc(50% - 8px); font-size:100%; }

.monlamPopup .ico { width:32px; padding-right:12px; align-self: flex-end;} 

.resource ~ .monlamResults .lobsangtooltip .lobsangtooltiptext { width: 290px; left:10px; top:30px; }
.resource ~ .monlamResults span .lobsangtooltiph { width:310px; padding-left:10px; box-sizing: border-box;}
.resource ~ .monlamResults .lobsangtooltip .lobsangtooltiptext::after { content: none; }

.resource ~ .monlamResults .perna,
.resource ~ .monlamResults .dhtmlgoodies_answer { font-family:"Noto Sans", "Noto Serif Tibetan", "Noto Serif Khmer", "Noto Sans Mongolian", "Roboto", "Helvetica", "Arial", sans-serif !important; }
   
.resource ~ .monlamResults .dhtmlgoodies_answer { box-sizing: border-box; padding:10px;}

.resource ~ .monlamResults .dhtmlgoodies_question { height:32px; box-sizing: border-box; }
.resource ~ .monlamResults .dhtmlgoodies_question img { vertical-align: middle; }

.resource ~ .monlamResults .dhtmlgoodies_question.on { font-weight:600; }
.resource ~ .monlamResults .dhtmlgoodies_question.on + .dhtmlgoodies_answer { display: block; }

.resource ~ .monlamResults div .def div div div .number { background:#eee; border-radius: 50%; width: 18px; height:18px; 
   display: inline-flex; align-items: center; justify-content: center;
   font-size:11px; color:#079; font-weight:500; margin-right:5px; 
}

.resource ~ .monlamResults  .simplebar-content > h2 { display: flex; align-items: center; padding-top:0; margin-bottom:0; font-size:20px; font-weight:700; text-align: left; } 
.resource ~ .monlamResults  .simplebar-content > h2 a { margin-right: 10px; color:#152631; text-decoration: none; max-width:288px;}
.resource ~ .monlamResults  .simplebar-content > h2:hover a { text-decoration: underline; }
/* .resource ~ .monlamResults h2 a:last-child { visibility:hidden; } */
.resource ~ .monlamResults  .simplebar-content > h2 svg { align-self: flex-start; cursor:pointer; display: block; position:absolute; right:5px; top:7px; }

/* ------------------------------------------------------------------------------------------------------------------------ header */

.resource .data#head { background-color:#ffffff; padding:35px; padding-bottom:45px; padding-top:35px;}
/* .resource .data .header:not(.firstImage):not(#map) { display:none;} */


.resource .data .header { width:100%; height:160px; background:#fce08d; display:flex;align-items:center;justify-content: center; position:relative; }
.resource.user .data .header .before,
.resource:not(.user) .data .header::before { content:' '; width:120px; height:120px; background:white;border-radius:50%; 
   background-size:auto;background-repeat:no-repeat; background-position: center;
}

.resource.user .data .header .before { display:flex;align-items: center; justify-content: center; overflow:hidden;}
.resource.user .data .header .before img { height:auto; flex-shrink:0;flex-grow:0; max-width:80px;}
.resource.user .data .header .before img:not([src$="header/user.png"]) { max-width:100%; object-fit: cover;  width:100%;  height:100%; }

.resource.user .data .FC { /*width:300px;*/ min-width:230px; }
.resource.user .data .FC .multiple-select > div  { white-space: normal;}
.resource.user .data .FC .multiple-select > div .chip { margin-bottom:4px;margin-right:3px; /* background-color: #fce08e; */ cursor:pointer;}
.resource.user .data .group > .FC.text { width:100%; }
.resource.user .data .group > .FC.text > .FC {  margin-right:0; }


.resource.user .data [data-shrink="true"],
.resource.user .data [class*="MuiInputLabel-shrink"] { display:none;}


.resource.user .data label + div::before,
.resource.user .data [class*="MuiInput-underline"]::before {  border:none; } 

.resource.user .data .FC > div { margin-top:0; }
.resource.user .data .FC > label { transform: translate(0, 7px) scale(1); }
.resource.user .data [data-props="tmp:cultural"][lang="en"] a span { display:inline-block; width:140px; }

.resource.user .data [data-props="foaf:mbox"] .FC > label { display:none; }

.resource.user .data #validate { position:absolute; right:0; display:inline-flex; align-items:center;}
.resource.user .data #validate > svg { opacity:0.55; margin-right:20px; color:#d73449; }
.resource.user .data #validate .error { position:absolute; top:100%;right:0;font-size:14px;font-weight:600;color:#d73449;text-align:center;
   margin-top:5px;
}

.resource.user .data .ulink#upd { width:auto; margin-top:0; line-height:42px; height:42px;  color:white; background-color: #d73449; border-radius:5px;display:inline-flex;align-items: center;justify-content: center; 
   padding: 0; border:none !important; font-weight:600; font-size:13px;min-width:150px; pointer-events: none;
}
.resource.user .data .ulink#upd.on {  cursor:pointer; pointer-events:auto; }

.resource.user .data .group .sub.otherI { border: none; display: flex; align-items: baseline;}
.resource.user .data .group .sub h4.first { width:195px; }
.resource.user .data .group .sub .FC { width:calc(100% - 15px); }

.profile-area a.ulink.on:hover { text-decoration: none !important;}

.resource.user .data input[disabled] { color:rgba(0,0,0,0.55); }

.resource.user .data > div[data-props="tmp:cultural"] + div::after { display:none;}
.resource.user .data#outline .widget { margin-left:190px; }
.resource.user .data#outline > .search { justify-content: flex-start; padding-left:37px;  }
.resource.user .data#outline > .search h3 { font-weight:600;}
.resource.user .data#outline .help { font-size:14px; margin-bottom:24px; margin-top:8px; font-weight:400;padding-left:2px;}


#root > div > .resource.user { width:1440px; padding:0; }

#root > div > .resource.user > .index { display:none; }

#root > div > .resource.user > .index + div { margin:80px auto; width:1280px; }


#root > div > .resource.user > .index + div .data#outline { padding: 0 60px 40px 60px; box-sizing: border-box; }

.resource.user .data#outline > div { padding:25px 35px;}

.resource.user .data#outline  div { width:100%; }

/* .resource.user .data#outline .help + .widget > label > span:last-child { max-width: calc(100% - 190px - 20px); display: block; }   */

.resource .data#outline .provider { padding-right:10px;  }
.resource .data#outline .provider img { height:14px; vertical-align: -2px !important; cursor:pointer;}



.resource .data#outline a.no-bdrc img { position:relative; margin-top:-8px; } 

.resource .data#outline .expand { position:absolute; margin:0;top:0; font-size:12px !important;font-weight:700 !important;
   text-transform:capitalize; padding-top:7px;
}
.resource .data#outline .expand svg { vertical-align: middle;}

.resource .data#outline .outlineCollapse.in-true { padding-top:10px;}
.resource .data#first-image .src,
.resource .data .header .src { position: absolute; right: 0; bottom: 20px; width: 34px;  height: 60px; background-color: #172832; line-height:58px; }

.resource .data#first-image .src.orig,
.resource .data .header .src.orig { cursor:pointer;}


.resource .data .header .src.SAT::before,
.resource .data#first-image .src.SAT::before,
.resource .data .header .src.SAT:not(#a)::after,
.resource .data#first-image .src.SAT:not(#a)::after { content:''; background:white;width:30px;height:30px; display:block;margin:15px 2px;position: absolute;}
.resource .data .header .src.SAT:not(#a)::after,
.resource .data#first-image .src.SAT:not(#a)::after { content:'SAT'; border:2px solid green;margin:16px 3px; width:24px;height:24px; font-size:12px; font-weight:700;
   display:flex; align-items: center; justify-content: center; color:rgba(0,0,0,0.75); border-radius: 4px;
}

.resource .data#first-image { position:relative; }

.resource .data#first-image .src:not(#a) { margin-right:35px; margin-bottom: 70px; z-index:10; display:block !important;}

.resource .data#first-image.simple { position:relative;}

.resource .data#first-image .src img,
.resource .data .header .src img { background-color: white; width:calc(100% - 3px * 2 - 2px * 2 );padding:2px; margin-left:3px; display:inline-block; 
vertical-align:middle;}

.resource .data#first-image .src img[src^="/SBB."],
.resource .data .header .src img[src^="/SBB."] { padding:0; width:calc(100% - 3px * 2) ;}
.resource .data#first-image .src img[src^="/BL."],
.resource .data .header .src img[src^="/BL."] { padding:0;margin:0;width:100%;}
   /* background-size:90% auto;background-position:center;background-repeat:no-repeat; } */

.resource .data#first-image .outline-warn {
    position: absolute;
    right: 40px;
    bottom: 40px;
    background: white;
    line-height: 0;
    border-radius: 50%;
    width: 26px;
    height: 26px;
    align-items: center;
    justify-content: center;
}

.resource .data#first-image .outline-warn::after { content:none !important;  }

.resource .data#first-image .outline-warn svg { color:#d73449; margin-top:-5px; }


.resource .data.related .rel-or-crea.all .header.work::before,
.resource .data.related .header::before,
.resource.work .data .header::before { background-image:url("/icons/work.png");  }
.resource .data.related .rel-or-crea.all .header.instance::before,
.resource .data.related .header.instance::before,
.resource.instance .data .header::before { background-image:url("/icons/header/instance.svg"); background-size:64px auto; }
.resource .data.related .rel-or-crea.all .header.scan::before,
.resource.volume .data .header::before,
.resource.images .data .header::before { background-image:url("/icons/image.svg"); background-size:58px auto;  }
.resource .data.related .rel-or-crea.all .header.person::before,
.resource .data.related .rel-or-crea.person .header::before,
.resource.person .data > .header::before { background-image:url("/icons/person.png");  }
.resource .data.related .rel-or-crea.all .header.topic::before,
.resource.topic .data > .header::before { background-image:url("/icons/topic.png");  }

.resource .data.related .rel-or-crea .header.etext::before,
.resource.etext .data > .header::before,
.resource.etext .data > a > .header::before { background-image:url("/icons/etext.png");  }

.resource .data.related .rel-or-crea.all .header.lineage::before,
.resource.lineage .data > .header::before { background-image:url("/icons/lineage.png");  }
.resource .data.related .rel-or-crea.all .header.place::before,
.resource.place .data > .header::before { background-image:url("/icons/search/place.svg");  }
.resource .data.related .rel-or-crea.all .header.corporation::before,
.resource.corporation .data > .header::before { background-image:url("/icons/search/corporation.svg");  }
.resource .data.related .rel-or-crea.all .header.role::before,
.resource.role .data > .header::before { background-image:url("/icons/search/role.svg"); background-size:58px auto;  }
.resource .data.related .rel-or-crea.all .header.product::before,
.resource.product .data > .header::before { background-image:url("/icons/search/product.svg"); background-size:58px auto;  }


.resource .data.related {
   overflow:hidden !important;
}

.resource .data .header.loading::before { background-image:none !important; }

.resource .data .header .access-icon { position: absolute; width: 20px; margin-left: 45px; margin-top: 55px; opacity: 0.8; }
.resource.etext .data .header .access-icon { margin-left: 65px; margin-top: 60px;  }

.resource .data.related .rel-or-crea.all { position:relative; min-height:250px;}
.resource .data.related .rel-or-crea.all .loader { width:calc(100% - 20px); position:relative; margin-top:50px; margin-bottom:110px;}

.resource .data.related .rel-or-crea.all.noAssoc { display:flex; align-items: center; justify-content: center;}

.resource .data.related[data-all="0"] h2 { margin-bottom:30px;}
.resource .data.related[data-all="0"] > div > div > .react-tabs > ul { display:none; }
.resource .data.related .rel-or-crea.all.noAssoc { padding:20px; min-height:0; }

/*
.resource .data [data-prop^="adm:seeOther"]:not(#a):not(.a):not(#b),
.resource .data [data-prop^="adm:original"]:not(#a):not(.a):not(#b),
.resource .data [data-prop^="owl:sameAs"]:not(#a):not(.a):not(#b) 
{
   display:none !important;
}
*/

/* ------------------------------------------------------------------------------------------------------------------------ title/tabs */

span[lang^='sa-']:not([lang$='-ewts']):not([lang$='-iast']) { text-transform: capitalize;}

.resource .nav + .title h2[lang=bo] span:not(.RID) { font-family:"Noto Sans", "Noto Serif Tibetan", "Noto Serif Khmer", "Noto Sans Mongolian", "Roboto", "Helvetica", "Arial", sans-serif !important;
   font-size:20px ; 
}

.resource .nav + .title h2[lang=bo] .RID { top:38px; }
.resource .nav + .title h2[lang=bo] .T::before { vertical-align: -28px; }

.resource .title h2:first-of-type:last-of-type { display:none; }

.resource .title { display:flex; position:relative; }

.resource .title h2:first-child.on { box-shadow:4px 0px 2px -2px rgba(0,0,0,0.125) ; }
.resource .title h2:first-child:not(.on) { box-shadow:4px 0px 2px -2px rgba(0,0,0,0.125) ; }

.resource .title h2:nth-child(2).on { box-shadow:-4px 0px 2px -2px rgba(0,0,0,0.125) ; }
.resource .title h2:nth-child(2):not(.on) { box-shadow:-4px 0px 2px -2px rgba(0,0,0,0.125) inset,5px 0px 0px -2px #efefef inset ; }

.resource .title h2:last-of-type { width:calc(100% - (100% / 3) * 2); }
/* .resource .title h2:last-of-type:not(:first-child).on { box-shadow:-4px 0px 2px -2px rgba(0,0,0,0.125) ; } */
.resource .title h2:last-of-type:not(.on) { box-shadow:4px 0px 2px -2px rgba(0,0,0,0.125) inset; }

.resource .title h2:first-child.on + h2 { box-shadow:4px 0px 2px -2px rgba(0,0,0,0.125) inset ; }
.resource .title h2:first-child.on + h2 + h2 { box-shadow:5px 0px 0px -2px #efefef inset ;}
/* .resource .title h2:not(.on) + h2:not(.on) {  box-shadow: 2px 0px 2px #efefef inset, -2px 0px 2px rgba(0,0,0,0.125) inset ; } */

.resource .title h2 { position:relative; width:calc(100% / 3); height:90px;display:flex; justify-content: center;align-items: center;
   font-weight:900; font-size:24px; }

.resource .data h2,
.resource .title h2 { /*font-family: "Noto Sans", "Roboto", "Helvetica", "Arial", sans-serif; */ margin:0; color:#343434; }

.resource .data.open-etext + .data { padding-bottom:60px; } 

.resource .data *,
.resource .related div { /*font-family: "Noto Sans", "Roboto", "Helvetica", "Arial", sans-serif;*/ }

.resource .data h2 { font-size:32px; letter-spacing: 0px; position:relative;}

.resource .title h2 .T { position:relative; letter-spacing:0px; display:flex; align-items: center;}

.resource .title h2 .T .space-fix { display: flex; flex-direction: column; }

html[lang='bo'] .resource .title h2 .T .space-fix span:not(.RID) { line-height: 220%; }

.resource .title h2 .T::before { width:50px;height:50px; content:'' ; display:inline-block; vertical-align:-22px; margin-right:35px; 
   background-size:contain;background-repeat:no-repeat;background-position:center right;
} 

.resource .title h2 .T.work::before { background-image:url("/icons/work_50g.png");  background-size: auto; }
.resource .title h2 .T.instance::before { background-image:url("/icons/instance_50g.png");  background-size: auto; background-position:top 11px right;}
.resource .title h2 .T.images::before { background-image:url("/icons/images_50g.png"); background-size: auto;}

.resource .title h2.on .T.work::before { background-image:url("/icons/work_50b.png"); }
.resource .title h2.on .T.instance::before { background-image:url("/icons/instance_50b.png"); }
.resource .title h2.on .T.images::before { background-image:url("/icons/images_50b.png"); }

.resource .title h2.catalogOnly { cursor:not-allowed; }
.resource .title h2.catalogOnly > * { color:rgba(0,0,0,0.35); }
.resource .title h2 .T.catalog::before { background-image:url("/icons/noimg.svg"); background-size: 45px auto; opacity:0.65;}

.resource .title h2 .T.etext::before { background-image:url("/icons/etext.png"); background-size: contain;}

/* .resource .title h2 .T { position:absolute;font-size:12px;text-transform:uppercase;top:8px;opacity:0.65;font-weight:100;} */

.resource .title h2.on { background-color:#ffffff; }
.resource .title h2:not(.on) { /*max-width: 30%;*/ text-overflow: ellipsis; white-space: nowrap; overflow: hidden; background-color:#e3e3e3; } 
/* .resource .title h2:not(.on) + h2:not(.on) { background-color:#fafafa; } */

.resource .data h2.on + h3 .lang,
.resource .data h2 .RID,
.resource .data h2.on .T { display:none; }
.resource .data h2.on + h3 { font-size:18px; margin-top:10px; }
.resource .data h2.on + h3 span { text-transform: none; }

.resource .data h2.on ~ h3.outline-link { font-size:18px; line-height:42px;}

.resource .title h2 a { color: #343434; text-decoration: none; width:100%;height:100%;display:flex;justify-content: center;align-items: center;}

.resource .title h2:not(.on) > a > :not(.T),
.resource .title h2.on > :not(.T) { display:none; }

.resource .title h2 .RID { display:block !important;/*position:absolute;*/top:32px;left:85px;color:rgba(0,0,0,0.5);font-size:11px;font-weight:400; letter-spacing:0; 
   white-space: nowrap;   max-width: 125px;   overflow: hidden;   text-overflow: ellipsis;
}


.resource h4 .nbTrans { font-size:14px; color:#555; font-weight:400;}

.resource  #outline h4 .nbTrans { display:none; }

/* ------------------------------------------------------------------------------------------------------------------------ permalink */

.resource .data#perma { background-color:#ffffff; padding:0 65px 50px 65px;}

/* #top-left { position:absolute; top:calc(100% + 40px);right:40px; } */
#perma > div { position:relative; background:#efefef; display:flex;align-items: center; justify-content:flex-start; margin-bottom:0px;
   padding:20px 35px; }
#perma > div button {color:rgba(0,0,0,0.54); }

#popDL.print #clipB,
#popDL.cite #clipB,
#perma #permalink { color:white; background-color: #d73449; border-radius:5px;display:flex;align-items: center;justify-content: center; height:40px;
   padding: 0 15px; border:none !important; font-weight:600; font-size:13px; cursor:pointer;
}


#popDL.print { margin-top:5px; }
#popDL.print > div { padding:16px !important; }

#popDL.cite { background:white; box-shadow: 0 1px 4px #cecece; }

#popDL.print #clipB,
#popDL.cite #clipB { display:inline-flex; /*margin-left: auto;*/ box-sizing: border-box;}

#popDL.print #clipB svg,
#popDL.cite #clipB svg,
#perma #permalink img { display:inline-block; margin-right:10px; }

#popDL.cite #export,
#perma #cite,
#perma #DL,
#perma #rid { font-size:13px;font-weight:600;display:inline-block;color:#343434; padding-right:16px;  }
   
#perma #rid { margin-left:57px;  /*border-right:1px solid rgba(0,0,0,0.35);*/ }

#popDL.print #export,
#popDL.cite #export,
#perma #cite,
#perma #DL { cursor:pointer; padding-left:16px; display:flex; align-items: center; border-left: 1px solid rgba(0,0,0,0.35);}

#perma #DL svg { vertical-align:-4px; font-size:18px; padding-left:0px;}

#popDL.print #clipB svg,
#popDL.print #export svg,
#popDL.cite #export .icon,
#popDL.cite #clipB svg,
#perma #cite svg,
#perma #DL img { 
   box-sizing:border-box; width:26px; height:26px; padding:5px; margin-right:10px; display:inline-block; vertical-align: middle; border-radius: 50%; box-shadow:0 0 0px 1.35px #343434; 
}

#popDL.print #export,
#popDL.cite #export { border:none; padding-right:0; }
#popDL.print #export .icon img,
#popDL.cite #export .icon img { width:15px; margin-left:2px; }

#popDL.print #clipB svg,
#popDL.cite #clipB svg { box-shadow:0 0 0px 1.35px white; }

#popDL.print #clipB.copied,
#popDL.cite #clipB.copied { background:none; color:#d73449; border:1px solid #d73449 !important; }
#popDL.print #clipB.copied svg,
#popDL.cite #clipB.copied svg { box-shadow:0 0 0px 1.35px #d73449; }

#perma #cite {  padding-left:16px;  }

#perma #cite svg { color:#343434; padding:3px; }

#popDL.cite  { display:flex; margin-top:5px; z-index: 100 !important; }
#popDL.print > div,
#popDL.cite .output { width: 480px; padding:16px; display: flex; flex-direction: column; justify-content: center; align-items: center; box-shadow:none; }
#popDL.print > div .main,
#popDL.cite .output .main { max-height: 135px; width:100%; margin-bottom:16px; text-align:left; font-size:14px; padding:0 16px 8px 16px; box-sizing: border-box; }
#popDL.print > div .main > div,
#popDL.cite .output .main > div {
   padding: 15px;
   /* box-shadow: 0 0 3px 0px #a4a4a4; */
   border: 1px solid #cccccc;
   max-height: 85px;
   overflow-y: auto;
}

#popDL.print > div .actions,
#popDL.cite .output .actions { width:100%; display:flex; justify-content: space-between; padding:0 16px; box-sizing: border-box;}

#popDL.cite .selected-style { background:#ddd !important; }

#popDL.export { background:white; box-shadow: 0 1px 4px #cecece;
   margin-top:0px; 
   z-index: 1000000!important;
}
#popDL.export li { max-width:260px; white-space:normal; }


#popDL.cite li { max-width:150px; white-space:normal; }




#popDL.print > div { max-width:470px; width:470px; }

#popDL.print .main p + div { font-style: italic; }
#popDL.print > div .main,
#popDL.print > div .actions { padding:0; }
#popDL.print > div .main p { margin-top:0; }

#popDL.print > div .actions a:last-child { color:#343434 !important; }

#popDL.print #export svg { padding:4px; }


#popDL.cite,
#popDL.print { z-index:1000000!important;}


#popHowTo { z-index:100100;  width:700px; box-sizing:border-box; padding:15px 30px; position: absolute; top:calc(100% + 35px); }
#root > .AppSK > .search #popHowTo { width: 630px; }

#popLang { z-index:100100; }
#popLang.black > div:last-child { margin-top:0px; }
#popLang > div:last-child li { min-width:150px; display:block; height:16px;}
#popLang > div:last-child li.is-locale { font-weight:900; }

#popLang.black > div:last-child li { padding-left:10px; }


#popLang.DL > div:last-child { margin-top:-10px;margin-left:-10px; }

#popHoverM .resource .data {/* width:100%;*/margin-left:0; }

#popHoverM #anchor { position:absolute; right:57px; margin-top:-3px; cursor:pointer; z-index:1;}

/* #popHoverM .resource .data > div[data-prop] > h3 { margin-top:-4px;} */
/* #popHoverM .resource .data > div[data-prop] > .sub:first-child { margin-top:-4px;} */

#popHoverM .resource .data > div[data-prop='bdo:note'] h4.first { display:none; }
#popHoverM .resource .data > div[data-prop='bdo:note'] h4.first + div:not(.subsub) h4 { color:#777777; }
#popHoverM .resource .data > div[data-prop='bdo:note'] .sub { display:block; }

#popHoverM .resource .data > div[data-prop] > .sub:not(:first-child) > h4.first { width:160px; font-size:15px; }
#popHoverM .resource .data > div[data-prop] > .sub:not(:first-child) > h4.first a { font-size:15px; }

#popHoverM .resource .data .sub > .subgroup > h4 { font-weight:400;}

#popHoverM .resource .data  .urilink .svg { background-color:transparent;}

#popHoverM .resource .data .sub { width:calc(100%); display:flex;align-items: baseline; }
#popHoverM .resource .data h3 + .sub { width:calc(100% - 190px); }


#popHoverM .resource .data .sub > h4.first { font-size:16px; width:175px; font-weight:600;}
#popHoverM .resource .data .sub > h4.first a { font-size:16px; }

#popHoverM .resource .data .sub > .subgroup { width:calc(100% - 190px); display:flex;align-items: baseline; }

#popHoverM .resource .data .sub > .subgroup > h4 { width:160px; text-transform:capitalize; font-size:15px; } 
#popHoverM .resource .data .sub > .subgroup > .subsub { width:calc(100% - 175px); display:inline-block; }

#popHoverM .resource .data h4[data-prop="bdo:role"] + h4 { width:170px;}

#popHoverM .resource .data .sub:not(:first-child) > .subgroup > h4.first { width:150px; font-weight:400; font-size:14px;}
#popHoverM .resource .data .sub:not(:first-child) > .subgroup > h4.first a { font-weight:400; font-size:14px;}

#popHoverM .resource .data h4 .lang { display:none; /*inline-block;*/}

#popHoverM .resource .data .sub > .subgroup > .subsub > h4,
#popHoverM .resource .data .group h4 { width:auto !important; padding-right:0 !important;}

#popHoverM .resource .data > div[data-prop='bdo:note'] .sub::before,
#popHoverM .resource .data .sub > .subgroup > .subsub > h4::before,
#popHoverM .resource .data .group h4::before { opacity:1 !important; }

#popHoverM .resource .data .inPart .lang { display:none; }
#popHoverM .resource .data .inPart .ulink:not(:first-child)::before { content : " / "; }


#popHoverM > div:last-child { width:953px; box-shadow:0 3px 4px #cecece,0 -1px 4px #cecece; max-width:1000px;
   left:calc((100vw - 1440px)/2 + 324px) !important; /* TODO fix when width under ~1250px */
}

#popHoverM[data-class="in-etext"] > div:last-child { width:min(90vw,1100px);}
#popHoverM[data-class="in-etext"] > div:last-child .data { padding-right:30px; }
#popHoverM[data-class="in-etext"] > div:last-child .data .react-tabs { flex-grow: 10; flex-shrink: 0; max-width: 1000%; width: 100%; }

#popHoverM > div:last-child .resource .data { padding-left:53px; padding-bottom:35px; padding-top:27px; margin-left:0; }
#popHoverM > div:last-child .resource .data .react-tabs { background-color:#fcf5e2; min-width:898px; margin-left:-25px;margin-top:20px; min-height:150px; font-size:0;}
#popHoverM .react-tabs__tab { border:none;border-radius:0; width:calc(25% - 1px); font-size:10px; padding:0; text-align:center; line-height:40px; bottom:-1px !important; 
   background-color:#fce08d; border-right:1px solid white; 
}
#popHoverM .react-tabs__tab:last-child { width:calc(25%); border:none; }
#popHoverM .react-tabs__tab.react-tabs__tab--selected {background-color:#fcf5e2;  }
#popHoverM .react-tabs__tab.react-tabs__tab--disabled { color:rgba(0,0,0,0.25) !important; }
#popHoverM .react-tabs__tab.react-tabs__tab b { font-weight:900; }

#popHoverM .react-tabs__tab-list { margin-bottom:0;}
#popHoverM .react-tabs__tab-panel { padding:25px;}
/* #popHoverM .react-tabs__tab-panel > div { display:flex;align-items: baseline; } */
#popHoverM .react-tabs__tab-panel img { height:20px; vertical-align:-6px; display: inline-block; margin-right:5px;}
#popHoverM .react-tabs__tab-panel * { font-weight:400; margin:0 5px 0 0;padding:0; display:inline-block;}
#popHoverM .react-tabs__tab-panel > div { font-size:13px; display:block; line-height:32px; }

#popHoverM .react-tabs__tab-panel:first-of-type > div { font-size:13px; display:flex; line-height:32px; align-items: baseline;}
#popHoverM .react-tabs__tab-panel:first-of-type > div > span.first + span + span { line-height:20px; }

/* #popHoverM .react-tabs__tab-panel > div:before { content:'•'; padding-right:5px;} */
#popHoverM .react-tabs__tab-panel b { font-weight:700;}
#popHoverM .react-tabs__tab-panel a img { margin-right:0;}

#popHoverM .react-tabs__tab-panel span.first .propref span,
#popHoverM .react-tabs__tab-panel span.first + span,
#popHoverM .react-tabs__tab-panel span.first { font-weight:700; color:black;}

#popHoverM .react-tabs__tab-panel span .propref,
#popHoverM .react-tabs__tab-panel span .propref span { margin-right:0; text-transform: capitalize;  }
#popHoverM .react-tabs__tab-panel span .propref:hover { border:none; }
#popHoverM .react-tabs__tab-panel span .propref:hover span { text-decoration: underline;}

#popHoverM .react-tabs__tab-panel span.first ~ div { display:inline-block; width:calc(100% - 120px - 30px); vertical-align: top; line-height:22px;
   padding-top:5px; 
}
#popHoverM .react-tabs__tab-panel span.first ~ div span { display:inline; } 

#popHoverM .react-tabs__tab-panel span.first ~ div span.lang { display: none; }

#popHoverM .react-tabs__tab-panel span.first { /*text-decoration: underline;*/ margin-right:0;}

#popHoverM .react-tabs__tab-panel span.label { margin-right:0;}
#popHoverM .react-tabs__tab-panel span.label:not(:last-child)::after { content:";";padding:0 5px 0 0;}

#popHoverM .react-tabs__tab-panel .ontolink:hover { border:none; text-decoration:underline; }

#popHoverM + #popHoverM {display:none !important; }

#popHoverM { top:-53px !important;left:63px !important; }
#popHoverM.fixW { left:403px !important; }
#popHoverM.fixW[data-id*='/workHasInstance'] { left:399px !important; }

#popHoverM.etext { left:-50px !important; }

#popHoverM[data-id$="etextMoreInfo"] svg,
#popHoverM[data-id$="etextMoreInfo"] .lang { display:none; }

#popHoverM[data-id$="etextMoreInfo"] .multiple,
#popHoverM[data-id$="etextMoreInfo"] .ulink,
#popHoverM[data-id$="etextMoreInfo"] .urilink { margin:0; }

#popHoverM[data-id$="etextMoreInfo"] .multiple { cursor:auto; pointer-events: none; display:block; }

#popHoverM[data-id$="etextMoreInfo"] .multiple a span { cursor:pointer !important; }
#popHoverM[data-id$="etextMoreInfo"] .multiple a span:hover { text-decoration: underline;}

#popHoverM[data-id$="etextMoreInfo"] .multiple a { pointer-events: auto;}

#popHoverM[data-id$="etextMoreInfo"] h4 h5 { display:inline-block; padding-right:0; font-size:14px; font-weight: 400; margin:0; }

#popSame a li emph { padding:0 5px; font-weight:800; }

#popSame { margin-top:-10px; z-index:1000000; }
.poPdf,
#popDL { margin-top:30px; z-index:10000;}

body > [id^="menu-"] { z-index:10010; }
body > [id^="menu-cita"] { z-index:1001000; }
#menu-interest ul li { padding-left:48px}
#menu-interest ul li::after { content:'';display:inline-block;position:absolute;background: url(/icons/check-blank.svg); width:20px; height:20px; 
   left:16px; opacity:0.65;
}
#menu-interest ul li.selected::after { background: url(/icons/check-black.svg); }


#popHowTo > div:last-child {
   text-align:left;
}


#popHowTo > div:last-child > button {
   position: absolute;
   top:0;
   right:0;
}

body > [id^="menu-"] > div:last-child,
.collapse.sortBy > div:last-child,
#popLang > div:last-child,
#popSame  > div:last-child,
#popDL > div:last-child { box-shadow:0 1px 4px #cecece;  }

#popSame a,
#popDL a { text-decoration: none; }

#menu-citationLang li,
#popHoverM li,
#popLang li,
#popSame a li,
#popDL a li { font-family:"Noto Sans", "Noto Serif Tibetan", "Noto Serif Khmer", "Noto Sans Mongolian", "Roboto", "Helvetica", "Arial", sans-serif;color:#4a4a4a;font-weight:600;font-size:12px;  }

#popSame a b { font-weight:900; }
#popSame a img { display: inline-block; margin-bottom:2px;margin-left:2px;}

#perma #DL ~ #same { margin-left:auto; }

#perma #copyright { margin-left:auto;  /*border-right:1px solid rgba(0,0,0,0.35);padding:2px 16px; */ }
#perma #copyright:not(:last-child) { padding-right:15px; }
#perma #copyright img { height:20px; vertical-align: middle;}
#perma #copyright img[src$="/.png"] { visibility:hidden;}

#perma #same { display:flex; align-items: center; padding-left:16px; cursor:pointer; padding:0 10px; border-radius: 5px;background:white;
   border:1px solid  rgba(0,0,0,0.35);
}

#perma #same > span:first-child { font-weight:600;font-size:13px;padding-right:5px;}

#perma .provider { display:inline-block; vertical-align: middle; line-height:0; pointer-events:none; }
#perma #same .provider { margin-left:0; height:40px; background:white;display:inline-flex;align-items: center;justify-content: center; padding:0 5px;}

#otherR .provider img,
#perma #same .provider img { height:22px; display:inline-block;} 

#otherR .provider .img,
#perma #same .provider .img { height:22px; display:inline-flex;align-items: center; padding:5px 5px;background-color:rgba(255,255,255,0.75); border-radius:4px;
   border:2px solid #4d4d4d; font-size:12px; font-weight:700; color:#4a4a4a;
}

#perma #same.PE0 { display:none; }

#otherR .provider.loc img,
#perma #same .provider.loc img { height:16px;}

/* #perma #same .provider.tol { background:white;border-radius:50%;} */

.otherRlink img,
#otherR .provider.tol img,
#perma #same .provider.tol img { width:28px;height:28px;}



.otherRlink .provider.wd img,
#otherR .provider.wd img { transform: scale(1.25); }

.otherRlink .provider img:not(#a),
#otherR .provider img:not(#a) { width:24px; height:24px; object-fit: contain;}

.otherRlink .provider,
#otherR .provider { margin-right:10px; display: inline-flex; }

.otherRlink .group h4,
#otherR .group h4 { margin-bottom:5px;}

#otherR .group { margin-top:-3px; }

.otherRlink:not(#a):hover {border: 1px solid transparent; text-decoration:underline; }

.group h4 a.otherRlink ,
#otherR .group h4 a { display: inline-flex; align-items: center; border: 1px solid transparent; }

.otherRlink:not(#a) .provider::before {
   content:none; 
}


.otherRlink:not(#a) + .hover-menu {
   margin-top:3px;
}

/* #perma #same .provider.tol:first-child:last-child img { width:42px;height:42px;} */


.result-box #cbct .sameAsLogo,
#perma #same .provider.cbct .img { border-color:rgba(0,0,238,1); }
.result-box #sat .sameAsLogo,
#perma #same .provider.sat .img { border-color:green;}
.result-box #src .sameAsLogo,
#perma #same .provider.src .img { border-color:#be1e23; }

/* #perma #same .provider:not(:first-child) { margin-left:10px;} */
#perma #same .provider.bdr { margin-bottom:2px; }
#perma #same .provider.bdr:not(:first-child) { margin-left:8px;}
#perma #same .provider.bdr img { height:28px;}

#perma #same .provider::before {  content:none; display:none; }
#perma #same .provider.eap img { height:36px;}

#perma #same .provider.bdr:not(:last-child) { display:none; }

/* #perma #rid + * { display:none;} */

.pdfLoader .loader { margin-top:30px; }
.pdfLoader { vertical-align:-3px; }
.pdfLoader img { opacity:0.55; }
/* .pdfLoader > button { width:auto !important; } */
.pdfLoader .loader, .loadedContent { display:inline-block;pointer-events:none; }

.poPdf a { cursor:pointer;}

.poPdf > div:last-child { max-height:30vh; box-shadow:0 1px 4px #cecece; }
.poPdf > div b,
#feedback .mustLogin.tooltip a,
.poPdf > div div.mustLogin,
.poPdf > div a { font-size:12px; font-family: "Noto Sans", "Noto Serif Tibetan", "Noto Serif Khmer", "Noto Sans Mongolian", "Roboto", "Helvetica", "Arial", sans-serif; color:#4d4d4d; white-space: nowrap;}
.poPdf > div li { padding:6px 17px; }
.poPdf > div a { padding:0 6px; display:flex;}
.poPdf > div a + a { border-left:1px solid rgba(0,0,0,0.35); }
.poPdf > div a[href] { color:#d73449;}

.pdfMenu a { position:relative;}
.pdfMenu b { display:inline-block; white-space: nowrap;}
.pdfLoading:last-of-type,.zipLoading:last-of-type,
.pdfLoading + span,.zipLoading + span { padding-right:25px; }
.pdfSpinner,.zipSpinner { left: calc(100% - 20px) !important;  top: 45% !important; }

.poPdf > div a li:hover,
#popDL a li:hover { background-color:#f5f5f5;}



.pdfMenu > a > span { display: inline-flex;  align-items: center; }
.pdfMenu > a > span.on { padding-right:14px;  }
.pdfMenu > a > span > svg { font-size: 20px; margin-left: 2px; color: black; position:absolute; right:0px; }

.pdfMenu > a > span > emph { text-decoration: underline; color:#d73449;}
.pdfMenu > a > span > span { cursor: auto; padding-left:4px; display: inline-flex; }
.pdfMenu > a > span > span > input { width:35px; border:none; border-radius:0; border-bottom: 1px solid #777; margin:0 4px; outline:none; padding:0;}
.pdfMenu > a > span > span > button { background:#d73449; color:white; border:none; font-weight: 600; font-size: 10px; padding:2px 7px; border-radius: 2px; text-transform: uppercase; 
   cursor: pointer;
}
.pdfMenu > a > span > span > button+svg { position: absolute; right: 0; margin-top: -2px; font-size: 20px; color:black; cursor: pointer;}



a.mustLogin:hover { text-decoration: underline;}

div.mustLogin a { display:inline; padding:0; color:#d73449; line-height:24px;}
div.mustLogin a:hover { border:none; text-decoration: underline;}

.popHowTo.bg,
#feedback .tooltip .bg { background:none; position: fixed; left:0; right:0; top:0; bottom:0; z-index: -1; }
#feedback .tooltip { background:white; padding:10px 15px; position: absolute; right:calc(100% + 10px); cursor: auto; 
   box-shadow: 0 1px 4px rgba(107, 107, 107, 0.5);
}
#feedback .tooltip a { cursor: pointer; }
#feedback .tooltip:not(.on) { display: none; }


.popHowTo.bg { z-index:1000; background-color:rgba(0,0,0,0.25)}

/* ------------------------------------------------------------------------------------------------------------------------ hover panel */



/* .hasTogHovM { cursor:help;} */

#outline .hasTogHovM,
.sub.hasTogHovM .subsub h4,
.hasTogHovM span { cursor:auto;}

.resource .data:not(.legal-props) span > .before,
.resource .data:not(.legal-props) span > .sameAs > .sameAs { display:none; }

.resource .data:not(.legal-props) h4.sameAs .ulink.sameAs { margin-left:0 !important; }

.resource .data [data-prop="bdo:note"] .noteCollapse.in-true { overflow: visible;}

/* added for #364 (cf git blame) then removed for #421 */
/* .resource .data.ext-props [data-prop="bdo:note"] { display:flex !important; align-items: flex-start !important;} */

.resource .data h4:hover .svg { background-color: #ededed; }

/*
.resource .data.ext-props .has-collapse.custom[data-prop="bdo:note"] .expand { 
   position:absolute;right:35px;top:8px; width: calc(100% - 260px);
}
.resource .data.ext-props .has-collapse.custom[data-prop="bdo:note"] .propCollapse .expand { 
   display:none
}
*/


/* full width when hovering... */

.resource .data .urilink { text-decoration: underline; text-decoration-color:transparent; border:none; }
.resource .data .urilink:hover  {  text-decoration-color:#d73449; border:none;}

.resource .data [data-prop="tmp:propHasEtext"] h4 > .urilink:hover { text-decoration: none;}

.resource .data .images-thumb { margin:0px 0px; width:145px; height:145px; background-size:cover;background-position:center;background-repeat:no-repeat; 
   border:1px solid #ccc;display:inline-block;vertical-align: top;
}
.resource .data .images-thumb:not(.no-thumb) {
   background-size: auto 100%;
   background-color: black;
}
.resource .data > [data-prop="bdo:workHasParallelsIn"] > h3 ~ .group > h4.multiple > * { vertical-align: baseline !important; line-height:23px; }
.resource .data > [data-prop="bdo:workHasParallelsIn"] a.no-bdrc img { position:relative; vertical-align: middle; opacity:1; }

.resource .data [data-prop="bdo:workHasInstance"] .images-thumb { width:100px;height:100px;z-index:1;position:relative;}
.resource .data [data-prop="bdo:workHasInstance"] .images-thumb-links { margin-left:0px;}

.resource .data [data-prop="tmp:propHasScans"] .images-thumb-links + span[lang],
.resource .data [data-prop="tmp:propHasEtext"] .images-thumb-links + span[lang],
.resource .data [data-prop="bdo:workHasInstance"] .images-thumb-links ~ .provider { display:none;  }

.resource .data > [data-prop="bdo:workHasInstance"] > h3 ~ .group > h4.multiple { display:inline-block; margin-top:4px;}

.resource .data > [data-prop="bdo:workHasInstance"] .provider::before { content:none;}

.resource .data > [data-prop="bdo:workHasInstance"] .inst-prov { position:absolute; width:40px;height:40px; left:65px; top:65px;
   display:flex; align-items: center; justify-content: center; cursor:pointer; z-index:1;
}
.resource .data > [data-prop="bdo:workHasInstance"] .inst-prov img { flex-shrink:0; height:65%; }

.resource .data > [data-prop="bdo:workHasInstance"] .inst-prov.sat { background-color: white; left:67px; top:67px; width:28px; height:28px; display:flex; align-items: center; justify-content: center;}

.resource .data > [data-prop="bdo:workHasInstance"] .inst-prov.sat { border:2px solid green; border-radius: 4px;}

.resource .data > [data-prop="bdo:workHasInstance"] .inst-prov.sat::before { font-weight: 700; color:#555; font-size:12px; line-height: 12px;}

.resource .data > [data-prop="bdo:workHasInstance"] .inst-prov.sat::before { content:'SAT'; }

.resource .data .images-thumb.no-thumb { background-size:60px auto; /*border-color:transparent;*/}
/* .resource .data .images-thumb.no-thumb + .ulink { display:inline-block;margin-left:28px; width:auto;} */


.resource .data [data-prop="bdo:workHasInstance"]  .images-thumb { float:left; margin-right:10px; }


/* .resource .data [data-prop="tmp:propHasEtext"]  .images-thumb.no-thumb {margin-right:28px; } */

.resource .data .images-thumb:hover { border-color:#ccc;}

.resource .data .images-thumb-links { display:inline-block; display:inline-flex;justify-content: flex-start; flex-direction: row;
   margin-left:28px;vertical-align:top; align-items: baseline; flex-wrap: wrap;
}

#etext-nav a#DL {
   display:flex; align-items: center; width:210px;
}

#etext-nav a#DL img {
   flex-grow:0;
   flex-shrink: 0;
}

#etext-nav a#DL:not(.on) img {
   margin-left:10px;
}

.etext-top-links a#DL:not(.on) { opacity:0.5; pointer-events: none;  }

.etext-top-links a {cursor: pointer;}

.etext-top-links a:hover { text-decoration: underline !important; }

.etext-top-links a + a,
.resource .data .images-thumb-links a + a { margin-top:0px;display:inline-block;margin-left:30px; position: relative;}

.resource .data .images-thumb-links > div { width:100%; } 

.resource .data .images-thumb-links > div > div > div a {
   cursor: pointer;
   text-decoration:none;
   border:none;
}

.resource .data .images-thumb-links > div > div > div a:hover {
   text-decoration: underline;
}

.resource .data .images-thumb-links > div > div > div.ellipsis {
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
   padding-right:50px;
   position: relative;
}
.resource .data .images-thumb-links > div > div > div.ellipsis a {
   position:absolute;
   right:0;
   width:40px;
}

.resource .data .images-thumb-links > div > div > div.expanded {
   white-space: normal;
   padding-right:0;
   position: relative;
}

.resource .data .images-thumb-links > div > div > div.expanded a {
   position:relative;
   margin-left:10px;
   display: inline-block;
}

[data-prop="tmp:containingOutline"] a.containing-outline {
   font-size:14px;
}

[data-prop="tmp:containingOutline"] a.containing-outline + a.containing-outline {
   margin-left:30px;
}


.resource [data-prop] .data.access .fairuse-IA-link + .fairuse-IA-link::before,
[data-prop="tmp:containingOutline"] a.containing-outline + a.containing-outline::before,
.etext-top-links a + a::before,
.resource .data .images-thumb-links a + a::before { 
   content:"";
   border-left:1px solid black;
   pointer-events: none;
   position: absolute;
   display: block;
   left:-15px; 
   top:5px;
   height:15px; width:1px;
}


[data-prop="tmp:containingOutline"] a.containing-outline + a.containing-outline::before {   
   top:3px;
}

.etext-top-links { line-height: 23px; }
.etext-top-links a { padding:0; border:0 !important; }

.resource .data .images-thumb-links a.disable { opacity:0.5; pointer-events:none; }

.resource .data.ext-props .subgroup { width:calc(100% - 215px + 40px ); }

.ulink { width:100%;}

.data:not(.legal-props) > [data-prop='bdo:creator'] .subsub:not(:first-child) { width:calc(100% - 180px); }

.resource .data [data-prop="bdo:note"] .sub,
h4:not(.first) 
{ position:relative; display:inline-block;z-index:1; width:calc(100%);}


.data:not(.legal-props) > [data-prop='bdo:creator'] .subsub:not(:first-child) h4.first + h4::before,
.resource .data [data-prop="bdo:note"] .sub::before,
.data:not(.legal-props) > :not(#etext-scroll):not([data-prop='bdo:note']):not([data-prop='bdo:creator']) h4:not(.first)::before
{     
   content:' ' !important;position:absolute;background-color:rgba(220,220,220,0.5); z-index:-1;
   width:100%; height:100%;top:-4px;left:-10px;  padding:4px 10px;
   opacity:0  ; pointer-events: none;
}


.resource .data [data-prop="bdo:note"] .sub:hover::before,
.data:not(.legal-props) h4:not(.first):not(.page):hover::before 
{
   opacity:1 !important;
}

.resource .sub > h4.label:not(:first-of-type) + .label { margin-left:175px;}


/* perfect narrow highlighting of property values */
/*

.resource .data .urilink,
.resource .data [data-prop="bdo:note"] .sub,
.resource .data:not(.legal-props) .group > h4.multiple,
.resource .data:not(.legal-props) > :not([data-prop="bdo:note"]) h4:hover .urilink > [lang=""],
.resource .data.ext-props h4:not(.first):hover > [lang],
.resource .data .group > h4:not(.first):not(.multiple),
.resource .data .group > h4:not(.first) > [lang],
.resource .data .subsub h4.first + h4,
.resource .data h4 .ulink,
.resource .data h4.label,
.resource .data h4.multiple > [lang]  { position:relative; display:inline-block;z-index:1; }



.resource .data [data-prop="bdo:note"] .sub:hover::before,
.resource .data:not(.legal-props) .propCollapse > div > div > h4:not(.first):hover::before,
.resource .data:not(.legal-props) .propCollapseHeader > h4:not(.first):hover::before,
.resource .data.ext-props h4:not(.first):hover > [lang]::before,
.resource .data .subsub:hover h4.first + h4::before,
.resource .data:not(.legal-props) .group:hover > h4:not(.first):not(.multiple)::before,
.resource .data:not(.legal-props) > :not([data-prop="bdo:note"]) h4:hover .urilink > [lang=""]::before,
.resource .data:not(.legal-props) > :not([data-prop="bdo:note"]) h4:hover > .ulink >.urilink::before,
.resource .data .sub:hover h4.label::before,
.resource .data h4.multiple:hover > [lang]::before,
.resource .data:not(.legal-props) > :not([data-prop="bdo:creator"]):not([data-prop="bdo:kinWith"]) .group:hover > h4:not(.multiple):not(.first) ::before,
.resource .data.ext-props [data-prop="bf:identifiedBy"] .sub:hover ::before,
.resource .data > [data-prop="bdo:creator"] .subgroup:hover .subsub:last-child ::before,
.resource .data > [data-prop="bdo:kinWith"] .subsub:hover h4.first + .subsubgroup ::before
{ 
   opacity:1 !important; 
}


.resource .data [data-prop="bdo:note"] .sub::before,
.resource .data:not(.legal-props) .propCollapse > div > div > h4:not(.first)::before,
.resource .data:not(.legal-props) .propCollapseHeader > h4:not(.first)::before,
.resource .data.ext-props h4:not(.first) > [lang]::before,
.resource .data .subsub h4.first + h4::before,
.resource .data:not(.legal-props) .group > h4:not(.first):not(.multiple)::before,
.resource .data:not(.legal-props) > :not([data-prop="bdo:note"]) h4 .urilink > [lang=""]::before,
.resource .data:not(.legal-props) > :not([data-prop="bdo:note"]) h4 > .ulink >.urilink::before,
.resource .data h4.label::before,
.resource .data h4.multiple > [lang]::before  
{  
   content:' ';position:absolute;background-color:rgba(220,220,220,0.5); z-index:-1;
   width:100%; height:100%;top:0px;left:-10px; opacity:0; padding:0 10px;
}

.resource .data:not(.legal-props) > :not([data-prop="bdo:note"]) h4 > .ulink > .urilink::before,
.resource .data .propCollapse > div > div > h4:not(.first) > [lang]::before,
.resource .data .propCollapseHeader > h4:not(.first) > [lang]::before,
.resource .data.ext-props .propCollapse > div > div > h4:not(.first) > [lang]::before,
.resource .data:not(.legal-props) .has-collapse h4 .ulink::before { padding:5px 10px; top:-5px; }

*/

.resource .data > :not([data-prop="bdo:volumeHasEtext"]) .propCollapseHeader h4:not(.first)::before,
.resource .data > :not([data-prop="bf:identifiedBy"]):not([data-prop="bdo:volumeHasEtext"]) .propCollapse h4:not(.first)::before
/* .resource .data.ext-props > :not([data-prop="bf:identifiedBy"]) .propCollapse > div > div > h4:not(.first)::before  */
{ padding:8px 10px !important; top:-8px !important; }


.resource .data > [data-prop="adm:logEntry"]:not(#a) .propCollapse h4:not(.first)::before 
{ padding:4px 10px !important; top:-4px !important; }

/*
.resource .data.ext-props > :not([data-prop="bf:identifiedBy"]) .propCollapse { padding-left:0; }
.resource .data.ext-props > :not([data-prop="bf:identifiedBy"]) .expand { display:none; }
.resource .data.ext-props > :not([data-prop="bf:identifiedBy"]) { align-items: baseline;}
*/

.resource .data > :not([data-prop="bdo:volumeHasEtext"]) .propCollapseHeader h4:not(.first) .hover-menu,
.resource .data > :not([data-prop="bf:identifiedBy"]):not([data-prop="bdo:volumeHasEtext"]) .propCollapse h4:not(.first) .hover-menu,
.resource .data.ext-props > :not([data-prop="bf:identifiedBy"]) .propCollapse > div > div > h4:not(.first) .hover-menu { top:-4px; right:-6px; }

.resource .data.ext-props > [data-prop="adm:logEntry"]:not(#a) .propCollapse  h4:not(.first) .hover-menu { top:-1px; }



.resource .data.ext-props > [data-prop="bf:identifiedBy"] h4:not(.first)::before { padding-right:5px !important; }
.resource .data.ext-props > [data-prop="bf:identifiedBy"] .propCollapse { padding-right:0; /*width:calc(100% + 5px);*/ }
.resource .data.ext-props > [data-prop="bf:identifiedBy"] h4 .hover-menu { right:-3px;  }

.resource .data .group > h4 { max-width:calc(100% - 10px - 20px); padding-right:20px;}

.resource .data.ext-props [data-prop] /*.propCollapse*/ ::before { background-color:white !important;}

.resource .data .propCollapseHeader,
.resource .data .propCollapse.in-true { overflow:visible; position:relative; }



.hover-menu { position:absolute; /*width:50px;*/ right:-7px; top:-1px; display:block; z-index:10; text-align:right; pointer-events: none;opacity:0; height:28px;
line-height:12px; } 
.hover-menu img { height:26px; vertical-align: super;  flex-shrink:0; cursor:pointer; }
.hover-menu div span#nb { position:absolute;color:#d73449;font-weight:900;font-size:10px; right:0; bottom:2px;cursor:pointer;}
.hover-menu span#anchor { display:inline-block; position:relative;}

.hover-menu span#anchor a:hover { border:none;}

:not(#etext-scroll) > h5:last-of-type { position: relative; padding-right:20px; }

.etextPage:hover .overpage + .hover-menu,
:not(#etext-scroll) > h5:hover .hover-menu,
.hover-menu.on,
.resource .data [data-prop="bdo:note"] .sub:hover .hover-menu,
:not(#etext-scroll) > h4:hover .hover-menu {  opacity:1 !important; pointer-events: auto !important; }

.resource .data:not(.legal-props) .group:hover > h4:not(.first):not(.multiple) + .hover-menu { opacity:1; }

.overpage + .hover-menu {
   top:5px;
   position: absolute;
   right:5px;
}

#popHoverM .resource .data h4[data-prop="bdo:role"] + h4 .hover-menu,
#popHoverM .resource .data h4[data-prop="bdo:creator"] + .subgroup > h4.first,
#popHoverM .resource .data h4[data-prop="bdo:creator"],
#popHoverM .resource .data h4[data-prop="bdo:role"],
.resource .data.data.legal-props > [data-prop="bdo:creator"] .subgroup .subsub:first-child .hover-menu,
.resource .data > [data-prop="bdo:creator"] .subgroup .subsub:first-child .hover-menu { display:none !important; }

#popHoverM + [role=tooltip]:not(#popHoverM) { z-index:9999 !important;}

a.no-bdrc img { position:absolute; margin-top:-2px; opacity:0; } 
h4:hover a.no-bdrc img { opacity:1; }

/* ------------------------------------------------------------------------------------------------------------------------ properties/misc */

.resource .data.access { text-align:center; padding-bottom:80px;}
.resource .data.access h3 { display:flex ; margin:auto; margin-bottom:15px; max-width:740px; justify-content: center; align-items: center; }
.resource .data.access h3 span { padding:0 10px; }
.resource .data.access h3::before { content:''; width:50px; height:50px; background-image:url("/icons/unknown.svg"); background-size:contain; background-repeat:no-repeat;flex-shrink:0;
   background-position: center right;  
}
.resource .data.access.hasIA h3 { align-items:flex-start; }
.resource .data.access.hasIA h3::before { margin-top:10px;}

.resource .data.access.fairuse h3::before,
.resource .data.access.hasIA h3::before { background-image:url("/icons/fair_use.svg");  }

.resource .data.access.notyet h3::before { background-image:url("/icons/noimg.svg");  }

.resource .data.access.sealed h3::before { background-image:url("/icons/sealed.svg");  }

.resource .data.top-props .has-collapse:not(.custom):last-child { margin-bottom:-25px; }

.resource .data { text-align:left ; background-color:#ffffff; padding:20px 100px;}
.resource .title + .data { padding-top:35px;padding-bottom:35px; }
.resource > .data:last-child { padding-bottom:50px; }
.resource .data + .data { padding-top:1px; }
.resource .title + .data + .data { padding-bottom:60px; }


.resource .data.images-title { display:flex; /*align-items: end;*/ position:relative; flex-wrap:wrap;}

.resource .data.etext-title { padding-right:270px; display:flex; align-items: flex-start;position:relative;}

.etext-header h2,
.resource .data h2.intro,
.resource .data h2.intro + h2 span,
.resource .title + .data + .data h2.on .T + span { font-size:32px; font-family:'Noto Sans SemCond', "Noto Sans", "Noto Serif Tibetan", "Noto Serif Khmer", "Noto Sans Mongolian", "Roboto", "Helvetica", "Arial", sans-serif !important;font-weight:bold; }

.resource .data h2.intro { padding-right:8px; flex-shrink: 0; line-height:42px;}

.resource .data h2.intro + h2 { line-height:42px;}

[lang="bo"] .resource .data h2.intro,
.resource .data h2.intro + h2[lang=bo],
.resource .data h2[lang=bo] { line-height:64px; }

.ETSBresults,
.monlamResults,
.resource .data h2.intro + h2[lang=bo] > a > span:not(.T,.lang),
.resource .data h2[lang=bo] > span:not(.T,.lang) { font-family: "Noto Sans", "Noto Serif Tibetan", "Noto Serif Khmer", "Noto Sans Mongolian", "Roboto", "Helvetica", "Arial", sans-serif !important; }

.resource .data h2.intro,
.resource .data h2.intro + h2,
.resource .data h2.intro + h2 span { display:inline;vertical-align: baseline; position:relative;}

.resource .data h2.intro + h2 a  { border:none; }
.resource .data h2.intro + h2 a span { border-bottom:1px solid transparent;}
.resource .data h2.intro + h2 a:hover span { border-bottom:1px solid #d73449;}

.resource .data h2.intro + h2 span { max-width:440px;overflow:hidden ;text-overflow: ellipsis;white-space: nowrap; display:inline-block;}


@media(min-width:769px) { 
   .resource .data.images-title h2.intro + h2 span { max-width:680px;}
}

.resource .data.images-title h3 { width:100%;}

.resource .data h2.intro + h2 .lang,
.resource .data h2.intro + h2 .T { display:none; }

/* .resource .data > div:not(.firstImage) { display:flex;align-items:baseline;flex-wrap:nowrap;box-sizing: border-box;width:100%;position:relative;} */
.resource .data > div:not(.firstImage):not(.browse):not(.header) { display:block; position:relative; }
.resource .data:not(#perma) > div:not(.firstImage):not(.browse):not(.header)::after { content:''; width:calc(100% - 190px);; height:1px;margin:25px 0 25px 190px;display:block;}
.resource .data.mid-props > div:not(.firstImage):not(.browse):not(.header)::after,
.resource .data > div:not(.firstImage):not(.browse):not(.header):not(:last-child)::after { background:rgba(0,0,0,0.25);}
.resource .data.top-props > div:last-child::after { margin-bottom:20px !important; margin-top:0 !important; }

.resource .data > div > h3 { width:calc(190px - 20px); padding-right:20px; /*line-height:24px;*/  vertical-align: top; }
.resource .data > div.parent { flex-wrap:wrap; }
.resource .data > div.parent > h3 { flex-shrink:0 }
.resource h3,.resource h4 { display:inline-block; margin-bottom:0px; font-size:14px; font-weight:normal; 
   overflow-wrap: break-word;  word-break: break-word; /*margin-right:5px; line-height:30px;*/ }
.resource h3 span { /*text-decoration: underline;*/ font-weight:600;  text-transform:capitalize; }
.resource h3 { font-size:14px;margin-top:-1px; }
.resource .sub { width:calc(100% - 190px); /*padding-left:20px;*/ display:inline-flex; /*flex-wrap:wrap;*/ box-sizing: border-box; position:relative;
   align-items:baseline;
}
.resource .sub h4.first { /*font-size:0.85em; font-style:italic;*/ text-transform:capitalize; flex-shrink:0; /*line-height:1.6em;*/  margin-right:15px;
   vertical-align: top; font-size:15px; ;
}

.resource .data > div > h3 { line-height:24px; }


span.in { font-weight:500;padding-left:0px; padding-right:10px; margin-left:36px; }
.resource h4 .over-in { white-space:pre;}
/* .resource h4 .in { vertical-align: middle; } */

.resource .group > h4.multiple > span:not(.lang):not(.in):not(.hover-anchor):not(.ulink.provider),
.resource .group > h4.multiple > span .urilink, 
.resource .group > h4:first-child:not(.first),
.resource .group > h4:first-child:not(.first) .ulink { vertical-align: top;}

.resource .group > h4.multiple > span .over-in .urilink { vertical-align: baseline;}

/* .resource .group > h4.multiple, */
/* .resource .group > h4.multiple > span, */
.resource .group > h4:first-child:not(.first),
.resource .group > h4.multiple .urilink, 
.resource .sub > h4.label,
.resource .sub h4.first,
.resource .subsub { line-height:23px;  }

.resource [data-prop="skos:altLabel"] .group > h4:first-child:not(.first) + h4.multiple { margin-top:-4px; }

 .resource .sub .sub h4.first.type,
.resource .sub h4.first.prop { font-style:normal;/*font-size:0.8em; line-height:20px;*/ }

/* .resource .sub h4.first + h4:not(.multiple) { margin-left:5px;} */

.resource .subsub { /*width:100%; padding-left:5px; display: flex;*/align-items: baseline;flex-wrap:wrap;position:relative; box-sizing: border-box;
   padding-right:5px;}

/* .resource .subsub h4 { flex-shrink:0 ; } */
/* .resource .subsub:first-child:before, */
.resource div .sub.here:before { content:"•";position:absolute; width:10px;margin-left:-10px;top:11px; }
/* .resource .subsub:first-child { margin-top:10px; } */
.resource .subsubsub.full,
.resource .subsub.full { flex-wrap:wrap; }
.resource .subsubsub.full > h4.first,
.resource .subsub.full > h4.first { width:100%; }
.resource h3 + .sub .subsub:first-child { margin-top:0px; }
.resource .subsub h4:not(.first):not(:last-child):after,
/* .resource h4:not(:last-child):not(.first):not(.last):not(.page):after { content:" | "; pointer-events:none; padding-right:30px; } */
.resource .subsubsub { width:100%; padding-left:20px; display: flex;align-items: baseline;flex-wrap:nowrap;position:relative; box-sizing: border-box;}
.resource .subsubsub .subsubsub h4.first.prop,.resource .subsub.full .subsubsub h4.first.prop,
.resource .sub .sub h4.first.type { font-style:normal; }
.resource .subsub.full .subsub h4.first.prop, .resource .subsubsub h4.first.prop, .resource  .subsub .subsubsub .subsubsub h4.first.prop,
.resource  .sub .sub .subsub h4.first.prop { font-size:0.7em;font-style:italic; }
.resource  .sub .sub .subsub h4.first.prop { font-style:normal;}
.resource .sub .sub { padding-left:60px;}
.resource .subsub.full .subsub .subsubsub h4.first.prop { font-style:normal; }

.resource .data > div:not([data-prop="bdo:note"]):not(.header):not(.firstImage):not(#perma)
{ display:flex !important; /*align-items: baseline;*/ flex-wrap:wrap;}

.resource .data#perma > div { flex-wrap:nowrap !important;}

.resource.user .data > div:not([data-prop="bdo:note"]):not(.header):not(.firstImage):not(#perma) 
{ align-items: baseline; }

/* ------------------------------------------------------------------------------------------------------------------------ properties */

.resource .data.bot-props > div[data-prop="bdo:placeLong"] .map,
.resource .data.bot-props > div[data-prop="bdo:placeRegionPoly"]:not(.youpi):not(#youpi) {display:none !important;}

.resource .sub {  width:100% ;}
.resource .data > :not([data-prop="bdo:note"]) .sub:not(:first-of-type) {border-top:1px solid rgba(0,0,0,0.25); padding-top:20px;}
.resource .data > :not([data-prop="bdo:note"]) .sub:not(:last-child) {  margin-bottom:20px; }

/* .resource .sub > h4 { line-height:22px;} */
.resource .sub > h4.label { max-width:calc(100% - 135px); vertical-align: top;  }


.resource .data .group { display:inline-block; width:calc(100% - 190px); /*line-height:24px;*/ }

.resource .data > div > .group > .sub { display:inline-block; }

.resource .data .propCollapseHeader > .sub > h4.first,
.resource .data .propCollapse > div > div > .sub > h4.first, 
.resource .data > [data-prop="bdo:creator"] .subgroup .subsub:first-child,
.resource .data > div > .group > .sub > h4.first { width: 160px;  }
.resource .data > [data-prop="bdo:creator"] .subgroup .subsub:first-child { padding-right:0; margin-right:15px;}

.resource .data > div > .group > .sub > .subgroup { display:inline-block; vertical-align: top; width: calc(100% - 160px - 15px); }

.resource .sub > h4.label:not(:first-of-type) { max-width:calc(100% - 160px - 15px - 10px - 20px); padding-right:20px;}

.resource .data > div[data-prop="bdo:corporationHasMember"] .sub > .subgroup { flex:auto;}
.resource .data > div[data-prop="bdo:corporationHasMember"] .sub > .subgroup > .subsub > h4.first + * { 
  width: calc(100% - 190px ); 
}

.resource .data > div .sub > .subgroup > .subsub > h4.first { width:150px; }
.resource .data > div:not([data-prop="bdo:creator"]):not([data-prop="bf:identifiedBy"]):not([data-prop="bdo:corporationHasMember"]) .sub > .subgroup > .subsub > h4.first + * { 
   /*max-*/ width: calc(100% - 150px - 15px - 5px ); 
}

.resource .data.ext-props > div[data-prop="adm:logEntry"]:not(#a) .sub > .subgroup > .subsub > h4.first + * 
{ width:calc(100% - 150px - 15px - 25px); padding-right:25px; }

.resource .data.ext-props + .ext-props > a:last-child { display:none; }
.resource .data.ext-props + .ext-props > div:empty { padding-bottom: 0 !important; }

.resource .data.ext-props > div[data-prop="adm:logEntry"] .propCollapseHeader,
.resource .data.ext-props > div[data-prop="adm:logEntry"] .propCollapse ~ * { display:none !important; }
.resource .data.ext-props > div[data-prop="adm:logEntry"] .propCollapse { column-count: 1;}

.resource .data.ext-props > div[data-prop="adm:logEntry"]:not(#a) .sub > .subgroup > .subsub:not(last-child) { margin-bottom:5px; }

.resource .data > div:not([data-prop="bdo:creator"]):not([data-prop="bf:identifiedBy"]) .sub > .subgroup > .subsub > h4.first + * h4 { 
   padding-right:20px; box-sizing: border-box;
}
.resource .data > div[data-prop="bdo:workHasTranslation"] .sub > .subgroup > .subsub > h4.first + * { 
   width: calc(100% - 160px - 15px - 5px ) !important; 
}

.resource .data [data-prop="bdo:creator"] + div div h4[data-prop="bdo:creationEventType"] + h4>span { font-style: italic; }

.resource .data > [data-prop="bdo:creator"] .subgroup > .subsub { display:inline-block; }

#popHoverM[data-id*="core/agent-"] .resource .data .sub > .subgroup { width:100%; }
#popHoverM[data-id*="core/agent-"] .resource .data .sub > .subgroup > .subsub  { width:100%; }

#popHoverM [data-prop="bdo:creationEventType"] .subsub:nth-child(n-2) { display: none;}

.data:not(.legal-props) > [data-prop='bdo:creator'] .subsub:not(:first-child) + .subsub { width:calc(100% - 5px); }


.resource .data:not(.top-props,.mid-props) > [data-prop]:last-child::after { content:none !important;display:none; } 

.resource .data.mid-props { padding-bottom:0; }

.resource .data > [data-prop="bdo:instanceReproductionOf"] > h3 { margin-right:20px; box-sizing: border-box; width:170px;}

/* see #423 */
.resource .data > [data-prop="bdo:workHasTranslation"] > h3 { display:none; }
.resource .data > [data-prop="bdo:workHasTranslation"] > .group { width:100%; }

.resource .data > [data-prop="bdo:workHasTranslation"] > .group > .sub > h4.first {  width: calc(190px - 15px);  padding-right: 15px; margin-right:0; font-size:16px;  }
.resource .data > [data-prop="bdo:workHasTranslation"] > .group > .sub > h4.first a.propref { color: #343434;  font-size: 16px; letter-spacing: 0px;
   /*line-height: 23px;*/ width: calc(190px - 15px);  padding-right: 0px; margin-right:0px; word-spacing:50px; font-weight:600;
}
.resource .data > [data-prop="bdo:workHasTranslation"] > .group > .sub > .subgroup { width:calc(100% - 190px);}
.resource .data  > [data-prop="bdo:workHasTranslation"] .sub .subsub a.propref { font-size: 15px;  font-weight: 600; letter-spacing: 0px;
    font-style: normal; 
}
.resource .data >  [data-prop="bdo:workHasTranslation"] .sub:not(:first-of-type) { border:none; }
.resource .data > [data-prop="bdo:workHasTranslation"] > .group > .sub:not(:last-child) > .subgroup { padding-bottom:20px; 
   border-bottom:1px solid rgba(0,0,0,0.25);}
.resource .data > [data-prop="bdo:workHasTranslation"] > .group > .sub:not(:last-child) { margin-bottom:0; }
.resource .data > [data-prop="bdo:workHasTranslation"] > .group > .sub > .subgroup > .subsub:not(:last-child) { margin-bottom:20px;
   padding-bottom:20px;  border-bottom:1px solid rgba(0,0,0,0.25);
} 
.resource .data > [data-prop="bdo:workHasTranslation"] > .group > .sub > .subgroup > .subsub > h4.first { width:160px; margin-right:15px; }

.resource .data > [data-prop="bdo:workHasTranslation"] .subsubgroup h4.multiple:not(:last-child),
.resource .data > [data-prop="bdo:workHasTranslation"] .subgroup .subsub:not(:last-child) { margin-bottom:10px;} 

h4 .svg { background-color:white; padding-right:15px;  display:inline-block;}
h4 svg {  display:inline-block; width:24px; opacity:1; vertical-align: -5px;}
h4:hover svg:not(.help-workid) path { color:#d73449; stroke:#d73449; }
h4:hover svg.image path { fill:transparent;}
h4:hover svg.image polygon {fill:#d73449; }
h4:hover svg.etext path { fill: #d73449; }

h4 svg.image { height:21px;}
/* .urilink:hover svg { opacity:1 } */

/*
TODO 
+ props/sous-props cf WorkW-01 (sous-props/titre en 14 et letterspacing à (-)0.3)
+ alignements vers le haut des titre (WA4CZ15645/canonical+P1583/initiatory)
+ see more en 2 colonne à droite à la place dernier element
+ related <Works>
+ aligner "see more" 2è colonnes sur val au dessus
+ fix manifest bdr:W2CZ8081
+ meme taille bloc meta/extended/related/header
= icones (instance ?)
- ouvrir onglets work quand pas ambiguité 
- author/creator > main info
*/



/* // deprecated "inline-table" etc


.resource .sub { width:calc(100% - 190px); }


.resource .data .group .sub {  line-height:24px; display:table-row;border: 1px solid blue; }

.resource .data .group .sub:not(:last-child) {  margin-bottom:6px; }
.resource .group .sub > h4 { display:table-cell; border: 1px solid red; }

.off .resource .group .sub:not(:first-child) > h4 { border-top:1px solid rgba(0,0,0,0.25);  } 
.resource .data > div > h3 + .group h4:not(.multiple) { vertical-align:top; padding-top:0px; line-height:24px; }


.resource .data > div > .group > .sub > h4.first { width:120px; padding-right:10px;}
.resource .data > div > .group > .sub > h4.first + h4 { vertical-align:bottom;}
.resource .data > div > .group > .sub > .subgroup { display:inline-table; vertical-align:top;}
.resource .data > div > .group > .sub > .subgroup > .subsub { display:table-row; }
.resource .data > div > .group > .sub > .subgroup > .subsub > h4.first { display:table-cell; width:100px;}

*/

.resource .data .sub.keep { border-top:none !important; padding-top:0 !important; margin-top:-15px; display:block !important;  }
.resource .data .sub.keep > h4.first { height:0; overflow:hidden; }
.resource .data [data-prop="bf:identifiedBy"] .sub.keep > h4.first { display:none;}

.resource .data .sub.willK:not(.keep) > h4.first { position:absolute; }

.resource .data [data-prop="bf:identifiedBy"] .sub.keep > h4.first + .subgroup,
.resource .data .sub.willK:not(.keep) > h4.first + .subgroup,
.resource .data .sub.willK:not(.keep) > h4.first + h4.label { margin-left:calc(160px + 15px); }

.resource  .data [data-prop="bdo:personEvent"] .sub.keep  { margin-top:-10px; }

.resource  .data .subsub  [data-prop="bdo:onDate"] , 
.resource  .data .subsub  [data-prop="bdo:onDate"] + *,
.resource  .data .subsub  [data-prop="bdo:notAfter"] , 
.resource  .data .subsub  [data-prop="bdo:notAfter"] + *,
.resource  .data .subsub  [data-prop="bdo:notBefore"] , 
.resource  .data .subsub  [data-prop="bdo:notBefore"] + *,
.resource  .data .subsub  [data-prop="bdo:onYear"] , 
.resource  .data .subsub  [data-prop="bdo:onYear"] + * { display:none; }

.resource .data > div > .sub { line-height:28px; }
.resource .data > div > .sub + .sub { width:calc(100% - 190px); margin-left:190px; } 
.resource .data > div > .sub:empty { display:none; }

/* .resource .data > [data-prop="bdo:workIsAbout"] h4.multiple { width:auto;margin-left:0;} */

/* .resource .data > div > .sub > .subsub + .subsub::before,  */           /* too generic */
/*.resource .data > div > .sub > .subsub:not(:last-of-type)::after,*/      /* better not perfect */
/*
.resource .data > [data-prop="bdo:workIsAbout"] h4.multiple + h4::before
{ content:" ";padding:0 5px 0 10px; font-size:15px; }
*/
#popHoverM .resource .data h4[data-prop="bdo:role"] + h4 .lang,
.resource .data > [data-prop="bdo:creator"] h4[data-prop="bdo:role"] + h4 .lang,
.resource .data > [data-prop="bdo:creator"] h4[data-prop^="bdo:"],
.resource .data > [data-prop="bdo:lineageHolder"] h4[data-prop^="bdo:lineageHolder"] 
{ display:none; }

html[lang="en"] #popHoverM .resource .data h4[data-prop="bdo:role"] + h4 a::after,
html[lang="en"] .resource .data > [data-prop="bdo:creator"] h4[data-prop="bdo:role"] + h4::after { content:':'; /*padding-right:5px;*/ }


#popHoverM .resource .data h4[data-prop="bdo:role"] + h4 a,
.resource .data > [data-prop="bdo:note"]  .sub > h4.first,
.resource .data > [data-prop="bdo:lineageHolder"] .sub .subsub h4[data-prop^="bdo:lineageWho"] a,
.resource .data > [data-prop="bdo:creator"] h4[data-prop="bdo:role"] + h4 a { text-transform:capitalize;color:#343434;font-size:15px;font-weight:600;
   letter-spacing:0.63; border-color:#343434;}


.resource .data > [data-prop="bdo:creator"] > .group > .sub > .subgroup { width:100%;}

/* .resource .data > [data-prop="bdo:creator"] .subgroup { display:flex;} */

/* Lineage option 1
.resource .data > [data-prop="bdo:lineageHolder"] .subgroup { display:flex;}
.resource .data > [data-prop="bdo:lineageHolder"] .subgroup { flex-wrap:nowrap; }
.resource .data > [data-prop="bdo:lineageHolder"] .subgroup .subsub { display:inline-flex; flex-wrap:nowrap;}
*/

/* Lineage option 2 */
.resource .data > [data-prop="bdo:lineageHolder"] .sub .subgroup:not(#a) { width:calc(100%);}
.resource .data > [data-prop="bdo:lineageHolder"] .sub .subsub { width:calc(100% - 5px);}
.resource .data > [data-prop="bdo:lineageHolder"] .subgroup .subsub + .subsub { margin-left:calc(170px + 15px); width:calc(100% - 170px); }
.resource .data > [data-prop="bdo:lineageHolder"] .subgroup .subsub > h4.first { width:170px !important;} 
.resource .data > [data-prop="bdo:lineageHolder"] .subgroup .subsub + .subsub > h4.first { width:150px !important;} 
.resource .data > [data-prop="bdo:lineageHolder"]:not(#a) .sub > .subgroup > .subsub > h4.first + * {  width: calc(100% - 150px - 15px - 20px ); }
.resource .data > [data-prop="bdo:lineageHolder"] .propCollapseHeader h4:not(.first)::before,
.resource .data > [data-prop="bdo:lineageHolder"] .propCollapse h4:not(.first)::before {  top: -4px !important;   padding: 4px 10px !important; }
.resource .data > [data-prop="bdo:lineageHolder"] .propCollapseHeader h4:not(.first) .hover-menu,
.resource .data > [data-prop="bdo:lineageHolder"] .propCollapse h4:not(.first) .hover-menu { top:-2px;}

.resource .data > [data-prop="bdo:lineageHolder"] .propCollapseHeader,
.resource .data > [data-prop="bdo:lineageHolder"] .propCollapse { padding-right:0; width:calc(100% - 190px); }

[data-prop="bdo:corporationHasMember"].has-collapse.custom .propCollapse,
[data-prop="bdo:corporationHasMember"].has-collapse.custom .propCollapseHeader,
[data-prop="bdo:volumeHasEtext"].has-collapse.custom .propCollapse,
[data-prop="bdo:volumeHasEtext"].has-collapse.custom .propCollapseHeader,
[data-prop="bf:identifiedBy"].has-collapse.custom .propCollapse,
[data-prop="bf:identifiedBy"].has-collapse.custom .propCollapseHeader,
[data-prop="bdo:lineageHolder"].has-collapse.custom .propCollapse,
[data-prop="bdo:lineageHolder"].has-collapse.custom .propCollapseHeader { column-count:1; column-gap:0; }

.resource .data > [data-prop="bf:identifiedBy"] .sub [data-prop="rdf:value"] { display:none; }

.resource .data > [data-prop="bdo:note"] > div {  width:calc(100% - 190px); display:inline-block; }

.resource .data > [data-prop="bdo:note"]  .sub { display:inline-block; line-height:22px; width:calc(100% - 10px); padding-right:20px;}
.resource .data > [data-prop="bdo:note"]  .sub > h4 + div:not(.subsub) { display:inline /*-block*/; /*letter-spacing:0.25px;*/color:#777;font-size:13px; }
.resource .data > [data-prop="bdo:note"]  .sub > h4 + div + .subsub h4 { font-size:14px; /*letter-spacing:0.5px;*/}
.resource .data > [data-prop="bdo:note"]  .sub > h4.first { font-size:13px; padding-right:5px;}
.resource .data > [data-prop="bdo:note"]  .sub > h4 + div.subsub,
.resource .data > [data-prop="bdo:note"]  .sub > h4 + div.subsub h4 { display:inline;}
.resource .data > [data-prop="bdo:note"] > .group > div:not(.no-collapse) .sub:last-child,
.resource .data > [data-prop="bdo:note"]  .sub:not(:last-child) { margin-bottom:25px; }
.resource .data > [data-prop="bdo:note"] .expand { top:-10px; display:inline-block; margin-left:0; line-height:15px;} 
.resource .data > [data-prop="bdo:note"] .expand .toggle-expand { margin-top:-2px;  } 
.resource .data > [data-prop="bdo:note"]  .sub h4 { display:inline; }

.resource .data .sub.hoy { flex-wrap: nowrap;  }
/* .resource .data .sub .subsub:last-child { margin-bottom:6px; } */
.resource .data .subsub.hoyoh { flex-wrap: nowrap; display:flex; align-items: flex-start; margin-bottom:0px; }


.resource .data > [data-prop="bdo:volumeHasEtext"]:not(.has-collapse) > .group  > .sub > .subgroup,
[data-prop="bdo:volumeHasEtext"].has-collapse.custom .propCollapse .subgroup,
[data-prop="bdo:volumeHasEtext"].has-collapse.custom .propCollapseHeader .subgroup { width:100%; }

/* #popHoverM .sub > h4.first, */
[data-prop="bdo:volumeHasEtext"]:not(.has-collapse) .sub > h4.first,
[data-prop="bdo:volumeHasEtext"].has-collapse.custom .propCollapse .sub > h4.first,
[data-prop="bdo:volumeHasEtext"].has-collapse.custom .propCollapseHeader  .sub > h4.first { display:none; }


/* #popHoverM .resource .data .sub:not(#a):not(.b) > .subgroup > h4.first a, */
[data-prop="bdo:volumeHasEtext"]:not(.has-collapse) > .group  > .sub > .subgroup > .subsub > h4.first a,
[data-prop="bdo:volumeHasEtext"].has-collapse.custom .propCollapse .subsub > h4.first a,
[data-prop="bdo:volumeHasEtext"].has-collapse.custom .propCollapseHeader .subsub > h4.first a { font-weight:600; font-size:15px;}

/*
.resource .data .sub .subgroup,
.resource .data > :not([data-prop="bdo:lineageHolder"]) .sub .subgroup a.propref { line-height:22px; }
*/

/*
.resource .data .sub .subgroup { line-height:22px;margin-bottom:0px; }
.resource .data .subsub.hoyoh:not(:last-child) { margin-bottom:0px; }
.resource .data .subsub .subsubgroup h4.multiple:not(:last-child) { margin-bottom:0px; }
*/

.resource .data .subsub .subsubgroup > h4 { vertical-align: top; }

.resource .data .subsub .subsubgroup { display:inline-block; vertical-align: top; /*line-height:20px;  line-height:20px; margin-bottom:6px;*/ }
.resource .data .subsub .subsubgroup h4.multiple { margin-bottom:0px; display:block; }
.resource .data .subsub .subsubgroup h4.multiple::after { content:none; }
/* .resource .data .subsub .subsubgroup h4 { line-height:22px; } */

.resource .subsubgroup .subsubsub { padding-left:0; }
.resource .subgroup .subsub > h4.first { width:auto;}
.resource .data .sub .subsubgroup .subsubsub > h4.first,
.resource .subsubgroup .subsubsub > h4.first a.propref { font-weight:300; font-size:14px;  }

.resource .data h2 .lang, .resource .data h4 .lang { display:none;}

/* ------------------------------------------------------------------------------------------------------------------------ "normal" properties */

.resource .data.bot-props > div[data-prop="bdo:placeLong"] .map,
.resource .data.bot-props > div[data-prop="bdo:placeRegionPoly"]:not(.youpi):not(#youpi) {display:none !important;}
.resource .data.bot-props:last-child { padding-bottom:60px; }

/* ------------------------------------------------------------------------------------------------------------------------ related */

.resource .data.related { background-color:#ffffff; padding:35px; padding-bottom:0;}
.resource .data.related > div:not(.a):not(#b):not(.c) { background-color:#f5f5f5;padding:5px 35px 45px 35px; position:relative; display:block !important;}
.resource .data.related h2 { line-height:44px;margin:45px 0; }
.resource .data.related > div::after { content:none !important; }

.resource .data.ext-props > a:first-child,
.resource .data.related h2 + a { position:absolute;text-transform:uppercase;font-weight:500;border-bottom:2px solid black; 
   height:24px; right:35px; cursor:pointer; padding-top:14px; color:#343434;}

.resource .data.ext-props > a:first-child { bottom: 70px; right: auto;left: 100px; z-index: 1;}

.resource .data.ext-props + .ext-props { position:relative; }

.resource .data.ext-props > a:first-child:hover,
.resource .data.related h2 + a:hover { color:#d73449;border-color:#d73449; }

.resource .data.related > div > div:first-child {display:flex;align-items: center;}
.resource .data.related > div div.rel-or-crea { display:flex; overflow:hidden; }
.resource .data.related > div div.rel-or-crea > div {display:inline-block; width:185px;margin-right:35px; flex-shrink:0;}
.resource .data.related > div div.rel-or-crea > div .header { height:185px; }
/* .resource .data.related > div div.rel-or-crea > :nth-child(n+5) { display:none; } */
/* .resource .data.related > div div.rel-or-crea > :nth-child(4n+4) { margin-right:0; } */
.resource .data.related > div div.rel-or-crea > :last-child { padding-right:22px; } 
/*
.resource .data.related > div div.rel-or-crea > div a { color:#343434; font-weight:700; font-size:13px; border-color:#343434;}
.resource .data.related > div div.rel-or-crea > div > div { color:#343434; font-weight:500; font-size:15px; margin:30px 0 20px 0; 
   position:relative; }
*/
.resource .data.related > div div.rel-or-crea > div a span  { color:#343434; font-weight:500; font-size:15px; margin:30px 0 20px 0; 
   position:relative; display:inline-block;
}
.resource .data.related > div div.rel-or-crea > div a:hover { border:none; }
/* .resource .data.related > div div.rel-or-crea > div a:hover span { text-decoration: underline;} */

.resource .data.related > div div.rel-or-crea > div > div .lang { display:none; position:absolute !important; top:calc(100% - 7px); }
/* .resource .data.related > div > div:last-child > div > div:hover .lang { display:inline-block; } */

.resource .data.related .react-tabs .rel-or-crea { background: rgba(255,255,255,0.65); padding:20px;}
.resource .data.related .react-tabs .rel-or-crea > div { margin-right: 22px !important; }
.resource .data.related .react-tabs__tab-list { margin-bottom:0; font-size:0;}

.resource .data.related .react-tabs__tab,
.resource .data.related .react-tabs__tab.react-tabs__tab--selected,
.resource .data.related .react-tabs__tab:focus { box-shadow:none; border:none; border-radius:0;padding:15px 25px; text-align:center;
    font-size:16px; overflow: hidden;  text-overflow: ellipsis;  white-space: nowrap;
}

.resource .data.related .react-tabs__tab:last-child { min-width:80px;}


.resource .data.related .react-tabs__tab-list { border:none; font-weight:600; position:relative;display:flex;} 

.resource .data.related .header.thumb:before { content:none; }
.resource .data.related .header.thumb { background-position:center; background-size:cover; border: 1px solid #ccc;
   background-size: auto 100%;
   background-color: black;
   background-repeat:no-repeat;
}

/* .resource .data.related .react-tabs .react-tabs__tab-panel .rel-or-crea { overflow-x:scroll;} */

.resource .data.related > div div.rel-or-crea > div { scroll-margin:20px;}

.resource .data.related .react-tabs .rel-or-crea { padding-bottom:60px; }

.resource .data.related .rel-or-crea .search { position:absolute; right:55px; bottom:65px; text-transform: uppercase; font-weight:700; 
   color:white; background-color: #d73449; padding:5px 10px !important; font-size:12px; border-radius:4px; border:none !important;
}

/* ------------------------------------------------------------------------------------------------------------------------ extended properties */


.resource .data:not(.legal-props):not(.ext-props):not(#a):not(.b) > [data-prop="adm:metadataLegal"]:not(#a):not(.b) { display:none !important; } 

.resource .data.legal-props { background-color:#ffffff; padding:35px 65px; padding-bottom:0;}
.resource .data.legal-props:last-child { padding-bottom:45px; }
.resource .data.legal-props > div { background-color:#172832;padding:25px 35px; position:relative; display:flex;align-items: center;}
.resource .data.legal-props > div h3 { width:105px; padding-right:60px; }
.resource .data.legal-props > div h3,
.resource .data.legal-props > div h3 span a { color:white; } 
.resource .data.legal-props > div::after { content:none !important; }
.resource .data.legal-props > div h4 { pointer-events:none; line-height:20px !important;margin-bottom:10px; font-size:13px; }
.resource .data.legal-props > div h4:last-of-type { margin-bottom:0px; }
.resource .data.legal-props > div h4 span a { color:white; }
.resource .data.legal-props > div h4 > span:not(.ulink) { display:none; }

.resource .data.legal-props > div h4 > .ulink { display:flex; align-items: center; }

.resource .data.legal-props > div h4 > .ulink > span { margin-right:20px; vertical-align: middle; }
.resource .data.legal-props > div h4 > .ulink > a:first-child { padding-left:24px; }
.resource .data.legal-props > div h4 > .ulink > span .before { padding:0;background-position:center;width:25px;height:25px; border-radius:13px;
 }
.resource .data.legal-props > div h4 > .ulink.eftr { margin-left:-21px; }

/* .resource .data.legal-props > div h4 { min-height:27px; } */
.resource .data.legal-props > div h4 a {  display:flex; align-items: center; }


.resource .data.legal-props > div h4 > .ulink > a[href*="LD_SAT_metadata"]::before,
.resource .data.legal-props > div h4 > .ulink > a[href*="LD_LULDC_metadata"]::before,
.resource .data.legal-props > div h4 > .ulink > a[href*="LD_SBB_metadata"]::before,
.resource .data.legal-props > div h4 > .ulink > a[href*="LD_IDP_metadata"]::before,
.resource .data.legal-props > div h4 > .ulink > a[href*="LOC_metadata"]::before,
.resource .data.legal-props > div h4 > .ulink > a[href*="mbingenheimer"]::before,
.resource .data.legal-props > div h4 > .ulink [href*="LD_SRC_Metadata"]::before,
.resource .data.legal-props > div h4 > .ulink [href*="LD_WD_Metadata"]::before,
.resource .data.legal-props > div h4 > .ulink [href*="LD_DILA_BSADP"]::before,
.resource .data.legal-props > div h4 > .ulink [href*="TOLLegalData"]::before,
.resource .data.legal-props > div h4 > .ulink [href*="LD_IA_Metadata"]::before,
.resource .data.legal-props > div h4 > .ulink [href*="LD_CUDL_metadata"]::before,
.resource .data.legal-props > div h4 > .ulink [href*="LD_GRETIL"]::before,
.resource .data.legal-props > div h4 > .ulink [href*="LD_rKTs"]::before ,
.resource .data.legal-props > div h4 > .ulink [href*="LD_BDRC"]::before { background-image:url("/logo.svg"); background-position:center;
   background-size:19px auto; content:' ';display:inline-block;width:25px;height:25px;  background-color:white;border-radius:13px; 
   background-repeat:no-repeat; margin-bottom:2px; margin-left:-45px;margin-right:20px;
    /* vertical-align:middle; position:absolute;  */
   flex-shrink:0;
}

.resource .data.legal-props > div h4 > .ulink.mbbt > span { margin-right:0; opacity:0; pointer-events:none;}

.resource .data.legal-props > div h4 > .ulink.src .bdr { display:none; }

.resource .data.legal-props > div h4 > .ulink > a[href*="LD_SAT_metadata"]::before { content:'SAT'; background-image:none; color:green; font-weight:700; font-size:12px;
   display:flex; align-items: center; justify-content: center; margin-top:4px;
}
.resource .data.legal-props > div h4 > .ulink > a[href*="LD_LULDC_metadata"]::before { background-image:url("/LULDC.png"); background-size:auto 24px;}
.resource .data.legal-props > div h4 > .ulink > a[href*="LD_SBB_metadata"]::before { background-image:url("/SBB.png"); background-size:auto 19px;}
.resource .data.legal-props > div h4 > .ulink > a[href*="LD_IDP_metadata"]::before { background-image:url("/IDP.jpg"); background-size:auto 21px;}
.resource .data.legal-props > div h4 > .ulink > a[href*="LOC_metadata"]::before { background-image:url("/LOC.svg"); background-size:23px auto;}
.resource .data.legal-props > div h4 > .ulink > a[href*="mbingenheimer"]::before { background-image:url("/MB-icon.jpg"); background-size:15px auto; }
.resource .data.legal-props > div h4 > .ulink [href*="LD_SRC_Metadata"]::before { background-image:url("/SRC.svg"); background-size:15px auto; }
.resource .data.legal-props > div h4 > .ulink [href*="LD_WD_Metadata"]::before { background-image:url("/WD.svg"); background-size:21px auto;}
.resource .data.legal-props > div h4 > .ulink [href*="LD_DILA_BSADP"]::before { background-image:url("/DILA-favicon.ico"); background-size:17px auto; }
.resource .data.legal-props > div h4 > .ulink [href*="TOLLegalData"]::before { background-image:url("/ToL.png"); background-size:23px auto; }
.resource .data.legal-props > div h4 > .ulink [href*="LD_rKTs"]::before { background-image:url("/RKTS.png"); background-size:14px auto; }
.resource .data.legal-props > div h4 > .ulink [href*="LD_GRETIL"]::before { background-image:url("/GRETIL.png"); background-size:14px auto; }
.resource .data.legal-props > div h4 > .ulink [href*="LD_CUDL_metadata"]::before { background-image:url("/CUDL.svg"); background-size:14px auto; background-position: top 5px center;}
.resource .data.legal-props > div h4 > .ulink [href*="LD_IA_Metadata"]::before { background-image:url("/IA.png"); background-size:15px auto; }
/* TODO exhaustive metadata provider list */

/* ------------------------------------------------------------------------------------------------------------------------ extended properties */

.resource .data.ext-props { background-color:#ffffff; padding:35px 65px; padding-bottom:0;}

.resource .data.ext-props:last-child { padding-bottom:45px; }
.resource .data.ext-props > div { background-color:#f0f0f0;padding:0px 35px 5px 35px; position:relative;}
.resource .data.ext-props:last-child > div:last-child { padding-bottom:80px; }
.resource .data.ext-props + .data.ext-props { padding-top:0; }
.resource .data.bot-props + .data.ext-props > div::after { content:none !important;}
.resource .data.ext-props h2 { line-height:44px;margin:45px 0; }

/*
.resource .data.ext-props h2 + span { position:absolute;text-transform:uppercase;font-weight:500;border-bottom:2px solid black; 
   height:24px; right:35px; cursor:pointer; padding-top:58px; color:#343434;}
.resource .data.ext-props h2 + span:hover { color:#d73449;border-color:#d73449; }
.resource .data.ext-props  > div { align-items: center; }
*/

/*
.resource .data.ext-props .has-collapse.custom h3 { order:-2; display:flex; align-items: center; height:42px; line-height:20px;  }
.resource .data.ext-props .has-collapse.custom h3 span a.propref { line-height:20px; }
*/

/*
.resource .data.ext-props .has-collapse.custom .expand { order:-1; color:transparent;width:calc(100% - 190px);top:-5px;
   margin-top:0 !important; border-color:#c2c2c2;margin-left:0; }
.resource .data.ext-props .has-collapse.custom .expand .toggle-expand { color:black; right:0;top:-5px; }
.resource .data.ext-props .propCollapseHeader { display:none !important; }
*/

/* .resource .data.ext-props .propCollapse { margin:5px 0 0px 0 !important; } */

/* .resource .data.ext-props .propCollapse { padding-left:190px; } */

/* .resource .data.ext-props .propCollapse.in-true { margin:25px 0 20px 0 ; } */

.resource .data.ext-props > div:last-child .propCollapse.in-true { margin-bottom:0px; }

/* .resource .data.ext-props .propCollapse { column-count: 1; } */

/* ------------------------------------------------------------------------------------------------------------------------ map */

.resource .data#map { padding-left:35px;padding-right:35px;padding-bottom:45px;}

.resource .data#map .header > div:not(:last-child),
.resource .data#map .header h3,
.resource .data#map .header h4,
.resource .data#map .header::before { display:none; }


.resource .data#map .header { position:relative; padding:35px; height:auto; width:calc(100% - 35px * 2); }

.resource .data#map .header > div,
.resource .data#map .header .map,
.resource .data#map .header .map .placeMap  { width:100%;height:auto;}

.resource .data#map .header .map .placeMap  { width:100%; height:500px;}



/* ------------------------------------------------------------------------------------------------------------------------ etext view */


#root > div > .resource.etext-view { padding:155px 30px; width:90%; margin:0 auto; position:relative; }

.preview-etext .resource.etext-view .data,
#root > div > .resource.etext-view .data { width:100%; padding:30px; box-sizing: border-box; position:relative; }

.preview-etext .resource.etext-view .data { padding:10px; background:#f5f5f5;
   max-height:230px; padding-bottom:50px;
}

.preview-etext .resource.etext-view .data #etext-scroll { 
   overflow: hidden; max-height: 180px; cursor:pointer;  padding-bottom:0px;
}

.preview-etext .resource.etext-view .data #etext-scroll > * { pointer-events: none; } 

.resource.etext-view > :not(.index) { width:100%; padding:0; }

#root > div > .resource.etext-view > .loadedContent { display:none; }

.preview-etext .resource.etext-view .data .etextPage,
#root > div > .resource.etext-view .data .etextPage { display:block; width:100%; }

.preview-etext .resource.etext-view .data  .etextPage:first-child,
#root > div > .resource.etext-view .data .etextPage:first-child { border:none; margin-top:0; padding-top:0;}

.scans-viewer-loader,
.preview-etext .etext-viewer-loader { transform:scale(0.5); transform-origin: 20px 20px; }

.preview-etext  .loader .etext-viewer-loader { left:-30px !important; }

.images-thumb-links .loader .scans-viewer-loader { left:-30px !important; top:-20px !important;}

.images-thumb-links .loader,
.scans-viewer-loader { position: relative;}

.etext-top-links:not(#a) { font-size:14px;  padding-bottom:10px; }

.preview-etext .resource.etext-view .data .etext-continue::after,
.preview-etext .resource.etext-view .data #etext-scroll::after,
.preview-etext .resource.etext-view .data .etextPage:not(#a) h4::before { content:none !important; }

.preview-etext .resource.etext-view .data .etext-continue:not(#a) { position: absolute; bottom:5px; right:5px; }

.preview-etext .resource.etext-view .data .etext-continue { justify-content: flex-end; width:100%; margin-top:10px; }

.preview-etext .resource.etext-view .data .etext-continue a:not(:hover) { border-bottom:1px solid transparent; }

.preview-etext .resource.etext-view .data .etext-continue a { font-size:14px; font-weight: 400;}

.withMonlam .resource.etext-view .etextPage .overpage .page .hover-menu { display:none !important; }

.resource.etext-view .etextPage .overpage{ padding-top:5px;}

.preview-etext .resource.etext-view .etextPage .overpage{ padding:0px; }
.preview-etext .resource.etext-view .etextPage{ border:none;}

.overpage .page [lang="bo"]:empty { display: none; }

.resource.etext-view .etextPage {
   line-height:25px;
}

.resource.etext-view .etextPage h4 [lang=bo],
.resource.etext-view .etextPage h4 [lang=bo] > span,
.resource.etext #etext-scroll [lang=bo] > span:not(.startChar) { font-family:'Tibetan Machine Uni','Noto Sans', "Roboto", "Helvetica", "Arial", sans-serif !important; 
   line-height:36px; 
}


/* .resource.etext-view .etextPage [lang=bo] .highlight { margin-top:-4px;padding:4px 5px 0 5px ; line-height:20px; display: inline;} */
/* .resource.etext-view .etextPage .highlight { font-weight:800; background:none; padding:0;}  */

.resource.etext:not(#a) .data.bot-props #etext-scroll { display:none !important; }

#etext-scroll > .etextPage:first-child { margin-top:20px; }

.preview-etext .resource.etext-view .data #etext-scroll > .etextPage { margin-top:0px; padding-top:30px; }

.preview-etext .resource.etext-view .data #etext-scroll > .etextPage:first-child { padding-top:0; }

.preview-etext .resource.etext-view .data #etext-scroll > .etextPage.unformated { padding:0;padding-top:30px; }
.preview-etext .resource.etext-view .data #etext-scroll > .etextPage.unformated:first-child { padding-top:0; }


.resource .data #etext-scroll,
.resource .data .chunk, .etextPage { display:block; position: relative;}
.resource .data .chunk ~ h4, .etextPage h4.page { display:inline; }

.resource .data .chunk ~ h4 .startChar,
.resource .data h4.page .startChar { display:block; }

.resource .etext-data .startChar { display:none !important;}

.resource .etext-data > div > h4 > span[lang] > span { padding-right:15px; display: block;  }

/* .resource .data .chunk ~ h4 [lang=bo-x-ewts], .etextPage h4.page [lang=bo-x-ewts] { text-align:justify; display:inline-block; } */

.resource .data h3.chunk { margin-bottom:15px;}
.resource .data h3.chunk ~ .multiple { padding-left:0; }

.etextPage:not(.unformatted).hasSeq { overflow: hidden ;}

.etextPage.unformated h4 .hover-menu { top:-24px; right:0px; }
.etextPage:not(.unformated) h4 .hover-menu { top:-5px; right:0px; }

.etextPage { border-top:1px solid #999; padding-top:25px; line-height:24px;  }
.etextPage h4.page  { display:inline-block; padding-left:0; margin-bottom:0;  
   white-space: nowrap;margin-right:0;
}

/* .resource .data .chunk ~ h4 [lang=bo], .etextPage h4.page [lang=bo] { font-size:24px;line-height:40px;  } */
/* .etextPage h4.page [lang=bo-x-ewts] { font-size:calc( 22 * 100vw / 2500) ;  line-height:calc( 50 * 100vw / 2500) ;  } */
/* .etextPage h4.page  [lang=bo] { font-size:calc( 30 * 100vw / 2500) ;  line-height:calc( 60 * 100vw / 2500) ; } */
.etextPage h4.page  [lang=bo] { font-size:1.4em; line-height:1.4em; }

/* .etextPage h4.page:last-child { padding-bottom:25px; } */
.etextPage:not(.unformated) .overpage { overflow-x: auto;}
.etextPage.unformated.hasSeq,
.etextPage:not(.unformated) .overpage { padding-bottom:25px}
.etextPage.unformated:not(.hasSeq)  { padding-bottom:25px; }
.etextPage.unformated h4.page { white-space: normal;  }
.etextPage h5 { margin-top:0; color:#777; display:inline-block; margin-bottom:10px;}

.preview-etext .etext-data .etextPage h5 { display:none; margin-bottom:12px;}

.etextPage h4 + h5 { margin-top:15px;margin-bottom:30px;}
.etextPage img.page, .etextPage h5 a, .etextPage .small a { cursor:pointer; } 
.etextPage img.page { box-shadow:1px 1px 7px rgba(0,0,0,0.6); display:block; max-width:calc(100% - 14px); margin-bottom:25px; margin-top:3px;
   margin-left: auto; margin-right: auto; max-height:80vh;}
.etextPage button { margin-left:-8px !important; }
.etextPage button img { height:28px;transform:rotate(-90deg); opacity:0.75;}
.etextPage span.button { margin-right:8px; display:inline-block; vertical-align: middle; cursor:pointer; }
.etextPage span.button img { height:24px;opacity:0.75; }
.etextPage .button:hover img,
.etextPage button:hover img { opacity:1;}
.etextPage.manifest-error h5, .etextPage.manifest-error button { opacity:0.5;pointer-events:none; }
.etextPage .small { padding:0 20px 0 50px; text-align:center;  margin-bottom:25px;margin-top:10px; position:relative;display:inline-block;}
.etextPage > div { position:relative; }
.etextPage > .imagePage { position:relative; text-align:right; }
.etextPage > div .lang { position:absolute;line-height:24px; display:none; }
/* .etextPage .toggle-volume { font-size:12px; } */
/* .etextPage button.hide { position:absolute; bottom:6px;left:80px;opacity:0}  */
.etextPage .close {  margin-top:0px; margin-left:10px; opacity:0.54;}
.etextPage > div .close svg { font-size:30px; }
.etextPage:hover > div .hide,.etextPage > div .hide.show, 
.etextPage:hover > div .close,.etextPage > div .close.show { opacity:0.54; cursor:pointer;  }
.etextPage:not(:hover) .close:not(.show) { opacity:0; }
.etextPage .button.hide { position:absolute; left:10px;top:0px;opacity:0; }
.etextPage > .imagePage:hover .button.hide { opacity:0.54; }
.etextPage.lang-bo h4.page { font-family:"Tibetan Machine Uni";}



@media(min-width:1225px) {
   /* #807 image + text */
   .etextPage > .imagePage.portrait { float:left; width:50%; }
   .etextPage > .imagePage.portrait a img { margin-right:25px; }
   .etextPage > .imagePage.portrait ~ .overpage { width:50%; float:right;}


   /* text + image */
   .etextPage > .imagePage.portrait.right { float:right; width:50%; }
   .etextPage > .imagePage.portrait.right a img { margin-left:0px; }
   .etextPage > .imagePage.portrait.right ~ .overpage { width:50%; float:left;}
}

@media(min-width:1025px) {
   /* #807 image + text */
   #root > :not(.withMonlam) .etextPage > .imagePage.portrait { float:left; width:50%; }
   #root > :not(.withMonlam) .etextPage > .imagePage.portrait a img { margin-right:25px; }
   #root > :not(.withMonlam) .etextPage > .imagePage.portrait ~ .overpage { width:50%; float:right;}


   /* text + image */
   #root > :not(.withMonlam) .etextPage > .imagePage.portrait.right { float:right; width:50%; }
   #root > :not(.withMonlam) .etextPage > .imagePage.portrait.right a img { margin-left:0px; }
   #root > :not(.withMonlam) .etextPage > .imagePage.portrait.right ~ .overpage { width:50%; float:left;}
}

.etextPage h5 a.unpag { color: #343434; cursor:auto;}
.etextPage h5 a.unpag:hover { border:none; }

.etextPage h5 + .copyrighted { font-size:14px; font-weight:600; padding-left:10px ;} 

.etextPage .overpage + div { margin:20px 0 5px 0; }

.etext-nav-parent {
   position:sticky;
   top:0px;
   z-index:100;
   background:transparent;
   height:120px;
   box-sizing: border-box;
   margin-bottom:20px;
}
.etext-header-breadcrumbs,
.etext-header.sticky > div,
.etext-header {
   width:1440px;
   max-width:100%;
   margin:auto;
   height:60px;
   background:#efefef;
}


.etext-header.sticky {
   height: 40px;
   background:#dddddd;
   width:100%;
   max-width:100%;
}

.etext-header:not(.sticky) {

   height:auto;
   padding-top:40px;
}


.etext-nav-parent.someClass .etext-header.sticky {
   max-width:calc(100% - 200px);
}

.etext-nav-parent.someClass .etext-header.sticky,
.etext-nav-parent.someClass .etext-header.sticky > div {
   background: white;
}

.etext-nav-parent:not(.someClass) .etext-header.sticky > div > * {
   visibility: hidden;
}

.etext-nav-parent .etext-header.sticky > div  h2 {
   margin:0;
   font-size: 18px;
}

.etext-nav-parent.someClass .etext-header.sticky > div {
   width: 1224px;
   margin:auto;
   
   display: flex;
   align-items: center;
}

.etext-header-breadcrumbs > div,
.etext-header:not(.sticky) > div {
   width: 1280px;
   margin:auto;
   background:white;
   padding:15px 100px;
   box-sizing: border-box;
   max-width:100%;
}
.etext-header:not(.sticky) > div h2 {
   margin:0;
   font-size:32px;
}

.etext-header-breadcrumbs {
   height:85px;
   display: flex;
}

.etext-header-breadcrumbs ~ .etext-header {
   padding-top:0;
}

.etext-header-breadcrumbs > .ariane {
   background:none;
   padding-top:0;
   padding-bottom:0;
}


.resource .data .back-anchor {
   position: relative;
} 

.resource .data .back-anchor .urilink ,  
.etext-header .urilink {
   color:#d73449;
   position: absolute;
   right:calc(100% + 20px);
   display: flex;
   text-decoration: none;
   font-weight:500;
   align-items: center;
   margin-top:-3px;
}


.resource .data .back-anchor .urilink {

   margin-top:7px;
}

.etext-header > div > span {
   position:relative;
   width:100%;
}

.etext-header.sticky .urilink {
   margin-top:7px;
}

#etext-nav { 
   /* position: fixed; */
   left: 0;
   bottom: 0;
   width: 1440px; 
   max-width:100%;
   margin:auto;   
   height: 80px;
   background: #172832;
   z-index: 100001;
   /* box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.5); */
   display: flex;
   align-items: center;
   justify-content: center;
   /* transition:all 0.2s ease-in-out; */
   
}

.etext-nav-parent.someClass {
   background:white;
}

.etext-nav-parent.someClass #etext-nav {
   height:60px;
   width:100%;
   max-width:100%;   
}  


.etext-nav-parent.someClass .etext-header {
   height:60px;
}


#etext-nav > div { justify-content: flex-start; align-items: center; }
#etext-nav #control { margin-left:auto; margin-right:65px; display:flex; }
#etext-nav #control img {   height:22px; display:inline-block;  }
#etext-nav #control #lang img { width:28px; height: auto; padding-bottom:0px; }
#etext-nav #control > * { padding:0px 10px ; display:inline-flex; height:40px; align-items: center; border-left:1px solid rgba(255,255,255,0.51); cursor:pointer;}
#etext-nav #control > *:first-child { border-left:none; }
#etext-nav #control > #lang {  }
#etext-nav #control span:not(#lang):not(.on) { pointer-events:none; }
#etext-nav #control span:not(#lang):not(.on) img { opacity:0.5; }
#etext-nav a { color:white; font-size:14px; text-decoration: none; font-weight:500; cursor:pointer;}
#etext-nav a:not(.on) { opacity:0.35; pointer-events: none; }
#etext-nav a img { vertical-align:middle; display:inline-block;margin-left:20px; }
#etext-nav a#DL img { width:42px;}
#etext-nav a:hover { text-decoration:underline; } 
#etext-nav a #check { margin-left:10px;margin-right:20px; display:inline-block;vertical-align: middle; min-width:19px;}
#etext-nav a span#check { width:19px;height:19px;background:white;border-radius:10px;}


#etext-nav .X { display:none; }



@media(max-width:1024px) {
   #etext-nav { height:60px; transition:left 400ms ease-in-out; }
   #etext-nav:not(.on) { left:calc(100% + 10px); }


   #etext-nav .X { 
      display:block;
      width: 15px;
      height: 60px;
      background-color: #d73449;
      position: absolute;
      right: 0;
      padding: 0 !important;
   }

   #etext-nav .X::before {
      content: ' ';
      position: absolute;
      top: calc(50% - 6px);
      left: 5px;
      width: 0;
      height: 0;
      border-top: 6px solid transparent;
      border-bottom: 6px solid transparent;
      border-right: 6px solid white;
      transition: all 400ms ease-in-out;
      transform: rotate(180deg);
   }
}


@media(max-width:750px) {
   /* #root .nav #lang, #root .nav #login span { margin-left:25px; } */
   .nav #login > div > span:first-child { max-width: 120px; }
   /* .nav #logo a:first-child span { font-size:0; } */
}

@font-face {
   font-family: 'Tibetan Machine Uni';
   src: url('/Tibetan_Machine_Uni.woff2') format('woff2'),
       url('/Tibetan_Machine_Uni.woff') format('woff');
   font-weight: normal;
   font-style: normal;
 }

 
/* .imageVolumePopup .lang { display:none; } */
.imageVolumePopup a { color:rgba(229, 74, 53,1); text-decoration: none; pointer-events: none;}

.resource .data h3 { font-size:16px;}
.resource .data h3 a.propref,.resource .data h4 a.propref { color:#343434; font-size:16px; letter-spacing: 0px;/*line-height:24px; word-break: break-all;*/ 
   font-weight:700; vertical-align:top;
}
.resource .data .sub a.propref { font-size:15px; font-weight: 600; letter-spacing: 0px;font-style:normal; }
.resource .data .sub .subsub h4.first { font-size:14px;font-weight:400;color:#343434; }
.resource .data .sub .subsub a.propref { font-weight:400;letter-spacing:0px; font-size:14px;}
.resource .data h3 a.propref:hover,.resource .data h4 a.propref:hover { border-bottom:1px solid black;}

.tradition .propref span { color:#343434; font-size:16px; font-weight:600; }

div[role="tooltip"]#popHoverM > div > div { color:black; }

div[role="tooltip"]:not(.cite) { margin:0px;  opacity:1;  }
div[role="tooltip"]:not(.cite) > div { font-weight:500;font-size:12px; background-color:white; border-radius:0; color:#4d4d4d;padding:0; max-width:450px;
   box-shadow: 0 2px 4px rgba(187, 187, 187, 0.5),0 -2px 4px rgba(187, 187, 187, 0.5); margin-bottom:5px;margin-right:5px;}
div[role="tooltip"]:not(.cite) > div > .over > span,
div[role="tooltip"]:not(.cite) > div > span:not(.over) { padding:12px 16px; display:block; white-space: nowrap; vertical-align: middle;}
div[role="tooltip"]:not(.cite) > div > span + span { padding-top:6px;}
div[role="tooltip"]:not(.cite) > div > span img { height:20px;vertical-align: middle; display:inline-block; margin:0 4px 2px 8px;} 
div[role="tooltip"]:not(.cite) > div b { font-weight:700; }

.commentP,.commentT {max-width:700px !important; }
.commentT { padding:10px !important;}
[role=tooltip]:not(.commentP,.cite) > div { padding:1px !important;}

.person #person, .place #place { display:none;}

.etext-match .uri-link { font-weight:700; }

#matches span:empty + br,
#matches span:empty + br + br,
#matches br[data-last=true], 
#matches br[data-last=true] ~ br { display:none; }

.hasSeq .page > span:last-of-type > span:last-of-type br[data-last=true] { display:none; } 

.toggle-note span { border-bottom:1px solid transparent;}

.toggle-note,
.uri-link { cursor:pointer; }

.toggle-note { font-weight:700; }

.toggle-note,
.uri-link,
.instance-link .urilink,
.match .urilink,  
.match .multi a,
.resource .data a, a.login { text-decoration: none; color: #d73449; /*color:rgba(229, 74, 53,1);*/}

a.login { text-decoration:underline; cursor:pointer; }


.toggle-note:hover span,
.uri-link:hover,
.instance-link .urilink:hover,
.match .urilink:hover, 
.match .multi a:hover,
.resource .data a:hover { border-bottom:1px solid #d73449; }

.toggle-note a:hover,
.resource .data#first-image a:hover { border:none; }

.urilink { position:relative; }
/*h4:not(.sameAs)*/ .urilink.hasIcon, h4.hasIcon { padding-left:0px; }
/*h4:not(.sameAs)*/ .ulink.hasIcon .before, h4.hasIcon .before, h2 .before
{ content:''; position:relative;display:inline-block;width:13px;height:13px; background-color:white;  vertical-align: -4px;padding-right:8px; padding-bottom:3px;
   background-position:right 5px center;background-size:13px auto;background-repeat:no-repeat; 
}
h2 .before { height:16px; }
.before a {font-size:0; position:absolute;width:100%;height:100%;}
.before:hover a { border:none !important; }

h4:hover .urilink.nolink svg path:not([fill="none"]),
.urilink.nolink:not(#a) { 
   color:black;
   stroke:black;
}
.urilink.nolink:not(#a) {
   font-weight: 600;
}

.urilink.nolink > svg { margin-right:8px;}

.urilink.nolink { margin-bottom:5px;}

h4 .urilink.nolink svg path[fill="none"] {
   color:transparent;
   stroke:transparent;
}

.urilink.nolink:not(#a):hover {
   text-decoration: none;
}


/* ------------------------------------------------------------------------------------------------------------------------ sameAs etc. */

.ulink { position:relative; z-index:1;}
.ulink+span { z-index:0;}

.hoverlink { 
   position:absolute;display:inline-block;width:15px;height:15px; background-color:white;  vertical-align: 0px; padding:3px ; margin-top:-16px; 
   background-position:center;background-size:15px auto;background-repeat:no-repeat; 
   background-image:url("/logo.svg"); background-size:20px auto; opacity:0;pointer-events:none; margin-left:15px;
}

.hoverlink:hover { border:none !important; }
div:hover > h4:not(.multiple) > .hoverlink,
div:hover > h4:not(.multiple) > span .hoverlink,
h4:hover .hoverlink { opacity:1;pointer-events: auto;}

.hover-anchor { display:none } /* inline-block; }*/
.hover-anchor ~ .hover-anchor { display:none; }
:not(.lang) + .hover-anchor .hoverlink { margin-left:5px;}

h2 .bdr .before { background-image:url("/logo.svg"); background-size:17px auto; background-position:top 1px right 5px; }
h2 .bnf .before { background-image:url("/BNF.svg"); background-size:19px auto;background-position:top 4px left;}
h2 .dila .before { background-image:url("/DILA-favicon.ico"); background-position:top 1px right 5px;}
h2 .idp .before { background-image:url("/IDP.jpg"); }
h2 .eap .before { background-image:url("/BL.gif"); background-size:auto 13px; background-position:left 4px top; }
h2 .gretil .before { background-image:url("/GRETIL.png"); background-size:auto 13px; background-position:top 1px right 6px;}
h2 .ia .before { background-image:url("/IA.png"); background-size:auto 13px;background-position:top 0px left 2px;}
h2 .mbbt .before { background-image:url("/MB-icon.jpg"); background-position:top 1px right 6px;} 
h2 .ola .before { background-image:url("/OL.png"); }
h2 .viaf .before { background-image:url("/VIAF.png"); }
h2 .eftr .before { background-image:url("/84000.svg"); background-size:16px auto; background-position:top 4px left 0px;}
h2 .rkts .before { background-image:url("/RKTS.png"); background-position:top 2px left 2px; }
h2 .wd .before { background-image:url("/WD.svg");  background-size:18px auto; background-position:top 2px left 2px;}


.sameAs.generic:not(.ulink):not(h4) .before, h4 .generic .before { background-image:linear-gradient(rgba(0,0,0,0.25),rgba(0,0,0,0.25));
   background-size:15px 15px; background-position:right 4px top; }

.sameAs.bdr:not(.ulink):not(h4) .before, h4 .bdr .before { background-image:url("/logo.svg"); background-size:17px auto; background-position:top 1px right 5px; }
.sameAs.bnf:not(.ulink):not(h4) .before, h4 .bnf .before { background-image:url("/BNF.svg"); background-size:19px auto;background-position:top 4px left;}
.sameAs.dila:not(.ulink):not(h4) .before, h4 .dila .before { background-image:url("/DILA-favicon.ico"); background-position:top 1px right 5px;}
.sameAs.idp:not(.ulink):not(h4) .before, h4 .idp .before { background-image:url("/IDP.jpg"); }
.sameAs.eap:not(.ulink):not(h4) .before, h4 .eap .before { background-image:url("/BL.gif"); background-size:auto 13px; background-position:left 4px top; }
.sameAs.gretil:not(.ulink):not(h4) .before, h4 .gretil .before { background-image:url("/GRETIL.png"); background-size:auto 13px; background-position:top 1px right 6px;}
.sameAs.ia:not(.ulink):not(h4) .before, h4 .ia .before { background-image:url("/IA.png"); background-size:auto 13px;background-position:top 0px left 2px;}
.sameAs.mbbt:not(.ulink):not(h4) .before, h4 .mbbt .before { background-image:url("/MB-icon.jpg"); background-position:top 1px right 6px;} 
.sameAs.ola:not(.ulink):not(h4) .before, h4 .ola .before { background-image:url("/OL.png"); }
.sameAs.viaf:not(.ulink):not(h4) .before, h4 .viaf .before { background-image:url("/VIAF.png"); }
.sameAs.eftr:not(.ulink):not(h4) .before, h4 .eftr .before { background-image:url("/84000.svg"); background-size:16px auto; background-position:top 4px left 0px;}
.sameAs.rkts:not(.ulink):not(h4) .before, h4 .rkts .before { background-image:url("/RKTS.png"); background-position:top 2px left 2px; }
.sameAs.wd:not(.ulink):not(h4) .before, h4 .wd .before { background-image:url("/WD.svg");  background-size:18px auto; background-position:top 2px left 2px;}
.sameAs.tol:not(.ulink):not(h4) .before, h4 .tol .before { background-image:url("/ToL.png");  background-size:19px auto; background-position: center}

.provider.bdr .before { background-image:url("/logo.svg"); background-size:17px auto; background-position:top 1px right 5px; }
.provider.bnf .before { background-image:url("/BNF.svg"); background-size:19px auto;background-position:top 4px left;}
.provider.dila .before { background-image:url("/DILA-favicon.ico"); background-position:top 1px right 5px;}

.provider.ngmpp::before,
.provider.eap::before { content: ' '; width: 25px;  height: 25px; border-radius: 13px;  background:white;position:absolute;
   background-repeat:no-repeat; left:-20px;}

.provider.idp .before { background-image:url("/IDP.jpg"); }
.provider.eap::before { background-image:url("/BL.gif"); background-size:auto 21px; background-position:center; }

.provider.idp .before { background-image:url("/IDP.jpg"); }
.provider.eap .before { background-image:url("/BL.gif"); background-size:auto 13px; background-position:left 4px top; }

.provider.ngmpp::before { background-image:url("/NGMPP.svg"); background-size:auto 15px; background-position:center; }

.provider.gretil .before { background-image:url("/GRETIL.png"); background-size:auto 13px; background-position:top 1px right 6px;}
.provider.ia .before { background-image:url("/IA.png"); background-size:auto 13px;background-position:top 0px left 2px;}
.provider.mbbt .before { background-image:url("/MB-icon.jpg"); background-position:top 1px right 6px;} 
.provider.ola .before { background-image:url("/OL.png"); }
.provider.viaf .before { background-image:url("/VIAF.png"); }
.provider.eftr .before { background-image:url("/84000.svg");  background-size:16px auto; background-position:top 4px left 0px;}
.provider.wd .before { background-image:url("/WD.svg");  background-size:18px auto; background-position:top 2px left 2px;}
.provider.rkts .before { background-image:url("/RKTS.png"); background-size: 15px auto; }
.sameAs.src .before { background-image:url("/SRC.svg"); background-size: 15px auto; }

h4.sameAs .ulink.sameAs { margin-left:-21px; } 
h4.sameAs .ulink.mbbt.sameAs { margin-left:0px; } 

h4.hasIcon ~ .subsub { padding-left:40px; }

.uriTooltip { margin:10px; display:flex; align-items: center; align-content: center; margin-bottom:13px;}
.uriTooltip .logo { display:inline-block;vertical-align:middle;width:64px;height:64px; background-color:white; margin-right:15px; flex-shrink:0; background-size: auto 52px;
   background-repeat:no-repeat;background-position:center;}
.uriTooltip .logo.bdr  { background-image:url("/logo.svg");  background-size: 52px auto;}
.uriTooltip .logo.bnf  { background-image:url("/BNF.svg");  background-size: 52px auto; }
.uriTooltip .logo.dila  { background-image:url("/DILA-big.png"); }
.uriTooltip .logo.idp { background-image:url("/IDP.jpg"); }
.uriTooltip .logo.eap  { background-image:url("/BL.gif"); }
.uriTooltip .logo.gretil {  background-image:url("/GRETIL.png"); } 
.uriTooltip .logo.ia  { background-image:url("/IA.png"); }
.uriTooltip .logo.mbbt  { background-image:url("/MB-icon.jpg"); } 
.uriTooltip .logo.ola  { background-image:url("/OL.png"); }
.uriTooltip .logo.viaf  { background-image:url("/VIAF.png"); }
.uriTooltip .logo.wd  { background-image:url("/WD.svg"); }
.uriTooltip .logo.eftr  { background-image:url("/84000.svg"); background-size: 52px auto; }
.uriTooltip .logo.rkts  { background-image:url("/RKTS.png");background-size: 52px auto; }

.uriTooltip .logo, .uriTooltip .text { margin-top:30px;}

.uriTooltip .title { position:absolute; top:0;font-size:12px;text-decoration:underline; margin-top:10px; }

.resource button svg:hover { color:rgba(0,0,0,0.65); }

a.download { text-decoration:none; font-weight:bold; cursor:pointer;  }
a.download:hover { text-decoration:underline; }

.firstImage + br + div > .download { font-size:14px; text-transform: capitalize; } 

/* ------------------------------------------------------------------------------------------------------------------------ properties/collapse */

.propCollapseHeader { width:calc(100% - 190px); display:inline-block;vertical-align:top;padding-top:0px;/*line-height:24px;*/ column-count:2;  column-gap: 30px;}

.propCollapseHeader .expand { margin:0 0 0 0  !important; top:-2px;bottom:initial;}

.propCollapseHeader:last-child { column-count:1; }

h3 + .group h4.multiple,
h3 + h4.multiple,
h3 + .sub 
{ margin-top:0px; }


h3 + .propCollapseHeader 
{ margin-top:2px; padding-right:6px; box-sizing: border-box; }

h3 ~ .propCollapse.in-true
{ margin-top:2px; }

h4.multiple { margin-bottom:10px; vertical-align: top;}

h4.multiple:last-child { margin-bottom:0; }

.resource .data > div > h3 + h4:not(.multiple) { width:calc(100% - 190px - 10px - 20px); vertical-align:top; padding-top:0px; /*line-height:24px;*/ padding-right:20px; }

.resource .data > div > h3 ~ .group > h4.multiple,
.resource .data > div > h3 ~ h4.multiple { width:calc(100% - 190px);; /*line-height:24px;*/ vertical-align:top; }

.resource .data > div > h3 ~ .group > h4.multiple { display:block;width:100%;}

/*
.resource .data > div > h3 ~ .group h4.multiple + h4,
*/

.resource .data > div > h3 ~ h4.multiple + h4 { margin-left:190px;  }

/* .resource .data > div > h3 + h4.multiple { margin-top:0px;} */

.resource .data > div .sub .subsub > h4.multiple,
.resource .data > div .sub > h4.multiple,
/*.resource .data > div > h4,*/ /* « object type: manuscript » */
.resource .data > div > h4.multiple,
/*.resource .data > div > .sub > h4:not(.first),*/ /*  eg « title: biblio title: » */
/* .resource .data > div > .sub > .subsub > h4:not(.first),  */
/* :not(:nth-child(2):last-child), */
.propCollapse > div > div > h4,
.propCollapseHeader > h4 { width:calc(100% - 45px + 20px); padding-left:0px; padding-right:20px; }
.resource .data > div .sub .subsub > h4.multiple::after,
.resource .data > div .sub > h4.multiple::after,
.resource .data > div > h4::after,
.resource .data > div > .sub > h4:not(.first)::after,
.propCollapse > div > div > h4::after,
.propCollapseHeader > h4::after { display:none !important; }
/*h3 .expand { display:inline-block; cursor:pointer;}
h3 .expand svg { position:absolute; margin-top:-18px; }*/
.propCollapse.in-false h4:first-child { margin-top:0;transition:all 200ms ease-in-out; }
/* .propCollapse.in-true + .expand { margin-bottom:10px; } */
.propCollapse { width:calc(100% - 190px - 6px); /*margin-left:190px; margin-bottom:50px; line-height:24px;*/ column-count:2; column-gap: 30px; 
   padding-right:6px; display:inline-block;}

/* .propCollapseHeader+.propCollapse { margin-left:190px; margin-top:-27px; }  */

/* .propCollapse.in-true { padding-bottom:10px; margin-bottom:15px; } */

/* .propCollapse.in-false { height:0;}  */

.propCollapse.in-false h4 { opacity:0 ; transition:none !important;}
/* .propCollapseHeader { width:100%; } */

.propCollapseHeader.in-true { opacity: 0; height: 0; width: 0; transition-delay: 0ms;display:none;}
.propCollapseHeader.in-false { transition-delay: 0ms;}

.expand { margin-bottom:0px; cursor:pointer; margin-left:190px; border-bottom:1px solid transparent; font-size:13px; position:absolute;bottom:24px; margin-bottom:3px; 
   display:inline-block; margin-top:5px;}
.expand:hover { border-bottom:1px solid black;  }
/* .propCollapse.in-false + .expand { margin-top:0px;transition:all 300ms ease-in-out;}  */
/* .propCollapse.in-true + .expand  { margin-top:15px; margin-bottom:-5px; }  */

.has-collapse.custom.max-10 { min-height:150px; }
.has-collapse.custom .propCollapseHeader { column-count:2; }

.has-collapse.custom[data-prop="bdo:creator"] .propCollapse,
.has-collapse.custom[data-prop="bdo:creator"] .propCollapseHeader { column-count:1; }

/*
.has-collapse.custom[data-prop="bdo:workHasInstance"] .propCollapse,
.has-collapse.custom[data-prop="bdo:workHasInstance"] .propCollapseHeader { column-count:2; }
*/

.has-collapse.custom[data-prop="bdo:creator"] .propCollapse .subgroup,
.has-collapse.custom[data-prop="bdo:creator"] .propCollapseHeader .subgroup { width:100%; }

.resource .data :is(.withLinkTo,[data-prop="bdo:workHasInstance"]) .ellip { text-overflow: ellipsis; white-space: nowrap; overflow: hidden;
   width:36px; display:inline-block;vertical-align: bottom; margin-left:0px;
}

.resource .data :is(.withLinkTo,[data-prop="bdo:workHasInstance"]) .group { column-count:2; }
#popHoverM .resource .data :is(.withLinkTo,[data-prop="bdo:workHasInstance"]) .group { column-count:1; /*width:*/}
#popHoverM .resource .data :is(.withLinkTo,[data-prop="bdo:workHasInstance"]) .group h4 { width:100% !important;}


#popHoverM .resource .data :is(.withLinkTo,[data-prop="bdo:workHasInstance"]) .ellip { white-space: normal; width:auto; display:inline; margin-left:0;} 

#popHoverM .resource .data :is(.withLinkTo,[data-prop="bdo:workHasInstance"]) h4 .urilink:hover .ellip { text-decoration: underline;}

:is(.withLinkTo,[data-prop="bdo:workHasInstance"]) h4.multiple { line-height:20px; }
.resource .data :is(.withLinkTo,[data-prop="bdo:workHasInstance"]) .group h4 { top:0px; } /* TODO CSS column hover bug ... */
.resource .data :is(.withLinkTo,[data-prop="bdo:workHasInstance"]) .propCollapseHeader.in-false { margin-bottom:30px;}
.resource .data :is(.withLinkTo,[data-prop="bdo:workHasInstance"]) .propCollapseHeader .expand { position:absolute;left:0;bottom:-30px;top:auto;}

.resource .data :is(.withLinkTo,[data-prop="bdo:workHasInstance"]) .expand.linkToPlaces,
.resource .data :is(.withLinkTo,[data-prop="bdo:workHasInstance"]) .expand.linkToVersions,
.resource .data :is(.withLinkTo,[data-prop="bdo:workHasInstance"]) .expand + .expand { right:0; left:auto; text-align: right; position: absolute; bottom:-25px; border:none; cursor:auto;}

.resource .data .withLinkTo .expand.linkToPlaces,
.resource .data .withLinkTo .expand.linkToVersions,
.resource .data .withLinkTo .expand + .expand { bottom:-20px; z-index:1; }

.resource [data-prop="tmp:workHasInstance"] .other-versions-nav:not(#a) a,
.resource .data :is(.withLinkTo,[data-prop="bdo:workHasInstance"]) .expand.linkToPlaces a,
.resource .data :is(.withLinkTo,[data-prop="bdo:workHasInstance"]) .expand.linkToVersions a,
.resource .data :is(.withLinkTo,[data-prop="bdo:workHasInstance"]) .expand + .expand a { color:white; background-color: #d73449; font-weight: 500; padding:10px 15px; border-radius: 5px; border:none; }

.resource .data .withLinkTo .propCollapse + .expand + .expand { bottom:60px; }
.resource.work .data [data-prop="bdo:workHasInstance"] .propCollapse + .expand + .expand { bottom:22px; }

.resource .data :is(.withLinkTo,[data-prop="bdo:workHasInstance"]) .group + .expand.linkToPlaces:last-child,
.resource .data :is(.withLinkTo,[data-prop="bdo:workHasInstance"]) .group + .expand.linkToVersions:last-child { position: relative; margin-bottom: 30px; width:100%; margin-top:-22px;}

.resource .data :is(.withLinkTo,[data-prop="bdo:workHasInstance"]) span.in { margin-left:0;  }
/* .resource .data :is(.withLinkTo,[data-prop="bdo:workHasInstance"]) span.in::before { content:'';display:block;} */
.resource .data :is(.withLinkTo,[data-prop="bdo:workHasInstance"]) .over-in { white-space:normal; margin-top:10px; position: relative; display:block; z-index:0} 

.resource .data :is(.withLinkTo,[data-prop="bdo:workHasInstance"]) h4.multiple { margin-bottom:15px;}

/*
   position:absolute;background:white;top:40px;padding-top:5px; 
   display:block; left:112px;z-index:1;
*/

.resource .data :is(.withLinkTo,[data-prop="bdo:workHasInstance"]) .images-thumb::after { content:'';height:10px;display:block;top:100%; }

.has-collapse.custom .expand { position:relative; bottom:auto; color:#d73449; font-weight:700;font-size:13px;margin-top:10px; }
.has-collapse.custom .expand:hover { border-color:#d73449; }

.has-collapse.custom.max-6 .propCollapseHeader.in-true ~ .expand,
.has-collapse.custom:not(.max-10) .propCollapseHeader.in-true ~ .expand,
.has-collapse.custom .propCollapseHeader.in-true ~ .expand { margin-top:20px; }

.propCollapseHeader h4, .propCollapse h4 { position:relative;}

h2.on .lang, .propCollapseHeader .lang, .propCollapse .lang { position:absolute !important; top:calc(100% - 7px);}

/*
.resource .data > div .sub .subsub,
*/


.resource .data > div:not([data-prop="bf:identifiedBy"]) .sub .subsub > h4 { vertical-align:top; }

.resource .data > div .sub .subsub > h4.multiple { padding-left:25px; /*line-height:24px;*/ }

.toggle-expand { position:absolute; margin-top: 0px; cursor:pointer; }
.toggle-expand svg { font-size:20px; }


/* ------------------------------------------------------------------------------------------------------------------------ SidePane */


.resource .SidePane .viewAll { color:black; cursor:pointer; margin-bottom:10px;display:inline-block;border-bottom:1px solid transparent;}
.resource .SidePane .viewAll:hover { border-color:black !important; }

.resource .SidePane .data > p:first-child { margin-bottom:10px !important;}

.resource .SidePane .data .anno {text-decoration:none !important; }
.resource .SidePane .data .anno .propref { color:black; text-transform:capitalize;border-color:black; border-bottom:1px solid black;  }
.resource .SidePane .data .anno .propref + svg { display:none; }
.resource .SidePane .data .anno { background-color:#fdfdfd;width:100%;margin-left:0;padding-top:15px;border:1px solid #eee;
   border-bottom:none;border-right-color:#ccc;padding-right:60px;}
.resource .SidePane .data .anno:not(:first-of-type) { margin-top:15px; }
.resource .SidePane .data .anno.new { padding-right:10px;}
.resource .SidePane .data .anno svg { position:absolute;z-index:5;color:rgba(0,0,0,0.54);cursor:pointer;margin-top:-2px;  }
.resource .SidePane .data .anno svg:hover { color:rgba(0,0,0,0.65); }
.resource .SidePane .data .anno hr { height:1px;border:none;background-color:rgba(0,0,0,0.25);width:calc(100% + 50px);float:left;}
.resource .SidePane .data .anno.new hr { width:calc(100%); }

.resource .SidePane .data > .sub { padding-left:0;}/* :10px; background-color:#fdfdfd; margin-bottom:5px; }  */
.resource .SidePane .data > .sub > .sub { padding-left:20px; background-color:#fdfdfd;border-left:1px solid #eee;border-right:1px solid #ccc;}
.resource .SidePane .data > .sub > .sub > .subsub { padding-left:15px; flex-wrap: wrap;}
.resource .SidePane .data > div > h4, .SidePane .data > div > .sub > h4:not(.first) { width:auto;padding-left:0;}
.resource .SidePane .sub .sub .subsub h4.first.prop { width:100%;}
.resource .SidePane .sub .sub .subsub h4.first.prop + h4 { padding-left:15px;margin-right:0;padding-right:10px; } /* height:48px;overflow:hidden;margin-bottom:16px;} */
/* .resource .sub .sub .subsub h4.first.prop + h4:after { content:'(...)'; display:block;position:absolute;to:88px;} */
.resource .SidePane h3, .resource .SidePane h4 { font-size:12px;line-height:16px; }
/* .resource .SidePane h4.first.prop + h4 { height:48px; overflow:hidden;  text-overflow: ellipsis; display:inline-block; } */
.resource .SidePane .data .anno, .resource .SidePane svg.annoticon {  vertical-align: -12px; }
.resource .SidePane .sub .sub h4.first.type, .resource .SidePane .sub h4.first.prop { line-height:16px; }
.resource .SidePane .annoSepa:not(:first-child)
 { width:100%; height:1px;border:1px solid #ccc;padding-top:15px;background-color:#fdfdfd;border-top:none;border-left-color: #eee;}
.resource .SidePane .data > .sub > .sub:last-child {border-bottom:1px solid #ccc;padding-bottom:15px;margin-bottom:30px;}
.resource .SidePane .data .sub .annoticon { display:none ; }
.resource .SidePane .data .anno { color:black; text-decoration:underline;padding-left:10px;}
.resource .SidePane .data .subsub.new { padding-right:10px; }
.resource .SidePane .data .subsub.new > div { margin-top:10px; }
.resource .SidePane .data .subsub.new > div > label { font-size:14px;}
.resource .SidePane .data .subsub.new > div > label + div::before { border-color:rgba(0,0,0,0.15); }
.resource .SidePane .data .subsub.new > div > label + div { font-size:12px;}
.resource .SidePane .data .anno.new + .sub + .sub { padding-top:30px;}
/* .resource .SidePane .data .subsub.new label + div { margin-top:0; } */



.resource.user h2 + .browse { display:none; }
.resource.user h2 img { vertical-align:middle; display:inline-block;  width:80px; height:80px; object-fit:cover; object-position: center; }
.resource.user #avatar { position:relative; display:inline-block; margin-right:15px; width:80px; height:80px; overflow:hidden;display:inline-flex;vertical-align: middle;
   justify-content: center;align-items: center; box-shadow:0 0 5px rgba(0,0,0,0.45); margin-left:2px;}
.resource.user #avatar .hover { position: absolute;background:rgba(0,0,0,0.45); width:100%;height:35%;bottom:0;display:flex;justify-content: center;align-items: center;
   opacity:0; color:rgba(255,255,255,0.35);transition:all 75ms ease-in-out; }
.resource.user #avatar:hover .hover { cursor:pointer; opacity:1; }

.resource.user .data > div h3 { margin-left:-50px; padding-left:50px; }
.resource.user .data > div h4.multiple { margin-left:-50px; padding-left:70px; }

.userPopoverContent { padding:10px; min-width:350px;} 
.userPopoverContent > div > label:first-child { text-transform: capitalize; }
.userPopoverContent > div > p:last-child { font-style: italic; }
.userPopoverContent .buttons { position:absolute;right:6px; opacity:0; color:rgba(0,0,0,0.55); transition:all 75ms ease-in-out;}
.userPopoverContent /*.modified*/ .buttons { opacity:1;}
.userPopoverContent .buttons.top { top:4px; }
.userPopoverContent .buttons.bottom { bottom:2px }
.userPopoverContent .buttons svg { font-size:18px; margin-left:3px; cursor:pointer;}
.userPopoverContent .buttons a:first-child svg { color:green; }
.userPopoverContent .buttons a:last-child  svg { color:red; }

.resource.user #patch { background:#eee; border:1px solid #999; padding:20px; position:relative; margin-top:30px; overflow-x:auto; overflow-y:visible;
   /* display:none; */
}
/* .resource.user #patch::before { content:'RDF Patch:'; position:absolute;left:-1px; top:-20px;display:block; } */



/* .resource h3 { position:relative; } */
.resource .preprop .menu {  position:absolute; text-align:right; right:calc(100% /*- 50px*/); /*top:0px;*/ margin-top:-18px; height:15px; padding:3px; display:none;  width:80px;}
.resource h4 + .preprop .menu { right:calc(100% /*- 50px*/ - 20px); margin-top:-24px; }
.resource .preprop .menu a svg { font-size:18px; vertical-align:middle; cursor:pointer; margin-right:2px; color:rgba(0,0,0,0.55); }
.resource .preprop .menu a { border-bottom:none !important; }
.preprop:hover .menu,
.resource .data > div > h3:hover + .preprop .menu,
.resource .data > div > h4:hover + .preprop .menu { display:block; }

/* .resource .preprop .popover { position:relative; } */

[role=menuitem].current { text-decoration:underline !important; }

.goBack { text-decoration: none; }
/* .goBack:not(:first-child):not(.noML) { margin-left:35px !important; } */
.goBack button { min-width:0 !important;margin-left:0 !important; }

#iiif { padding:0;min-width:48px;min-height: 48px;border-radius:50%;}
#iiif img { margin-left:0;width:28px;}

.checkB,.checkedB { border-radius:50%; border:2px solid #666;width:20px;height:20px;display:inline-block; }
.checkedB {}

.placeMap {  width:400px;height:400px;overflow:hidden; }
.placeMap.large { width:100%;height:90vh; }
.leaflet-tooltip { padding:15px;}
.leaflet-tooltip.GIS { padding:7px; -webkit-user-select: auto;  user-select: auto; pointer-events: auto;}
.leaflet-tooltip h2 {  font-size:16px; padding-bottom:0;margin:0 !important;margin-bottom:5px !important;}
.leaflet-tooltip h2 svg { display:none;}
.placeMap .leaflet-bottom .leaflet-control { margin:0 !important;background: rgba(255, 255, 255, 0.7);}
.placeMap .leaflet-bottom > .leaflet-control { padding:3px; }
.placeMap .leaflet-bottom > .leaflet-control > .leaflet-control { background:none; }
.placeMap .leaflet-bottom.leaflet-right { margin-bottom:0px !important; }
.placeMap:not(.large) .leaflet-control { font-size:11px !important; }

#map .map { position: relative;}

.placeMap + .accu { 
   text-align: center;
   top:10px;
   position: absolute;
   width:100%;
   z-index:1000;
}

.placeMap + .accu > span {
   background:white;
   border: 1px solid #fff;
   border-radius: 3px;
   color: #222;
   box-shadow: 0 1px 3px rgb(0,0,0,0.4);
   display: inline-block;
   font-size:12px;
   text-align: center;
   padding:3px 10px;
}

.placeMap + .accu > span:empty { display: none;}

.placeMap .gmnoprint { display:none;}
.gm-style > iframe + div {  margin-bottom:20px; }
.placeMap.large .gm-style > iframe + div {  margin-bottom:25px; }

.resultsMap { width:100%; height:480px; margin-bottom:16px; margin-top:24px;}
.resultsMap[data-nb-markers='0'] { display:none; }
.resultsMap[data-nb-markers='0'] + .result-content { scroll-margin: 214px;}


.resultsMap a {
   color:#d73449;
 }

.resultsMap .leaflet-popup-content a {
   display:block;
   font-size:14px;
   line-height:16px;
}
.resultsMap .leaflet-popup-content a:hover :not(.RID) {
   text-decoration: underline;
}
.resultsMap .leaflet-popup-content a .RID {
   color:#888;
   font-size:11px;
}
.resultsMap .leaflet-popup-content span.highlight {
   background-color: #fce08e; padding:0 2px; font-weight:700;
}


.resultsMap + .result-content { scroll-margin:745px; }

body {overflow-x:hidden; }

/* .SearchPane .hidden { opacity:0; pointer-events:none; } */


/* .SearchPane > a:first-child img { transition: all 200ms ease-in-out; }  */

/* .SearchPane.resultPage { margin-top:110px; } */
.SearchPane.resultPage > a:first-child img { width:100px !important; } 
.SearchPane.resultPage #results, 
.SearchPane.resultPage #results > li,
.SearchPane.resultPage #results h4 { margin-top:0 !important; } 
.SearchPane.resultPage #results > h4 { margin-top:12px !important; } 
/* .SearchPane.resultPage #results > li + li { margin-top:-10px !important;}  */

#results > .uri-link:first-child { color: #152631; font-size:12px;font-weight:700; margin-left:22px;  }
#results > .uri-link:first-child > * { vertical-align:middle; }
#results > .uri-link:first-child > span { margin-left:10px; display:inline-block; border-bottom:1px solid transparent; }

#results > .uri-link:first-child:hover { text-decoration: none;border:none;}
#results > .uri-link:first-child:hover > span { border-bottom:1px solid #152631;}

.SearchPane h5 { margin-left:16px; font-size : 16px; margin-bottom:0px;margin-top:40px; }
.SearchPane .menu-categ h5 { margin-left:0; font-size : 16px; margin-bottom:0px;margin-top:0px; }
.SearchPane .menu-categ-collapse h5 { margin-left:16px; font-size : 13px; margin-bottom:0px;margin-top:0px; color:rgba(229, 74, 53,1); }
/* .menu-categ-collapse + .menu-categ-collapse { display:none; } */
.SearchPane .menu-categ-collapse h5:hover { text-decoration:underline; }
.menu-categ { padding:0;margin-bottom:0;margin-top:10px !important; } 
.menu-categ-collapse + .menu-categ-collapse.datatype { padding-left:0; } 
.menu-categ-collapse + li:not(.menu-categ) + a.result { margin-top:10px; }


.facet-count { font-style:italic; opacity:0.65; font-size:11px; font-weight:400; padding-right:3px;}
.facet-count-block { white-space:nowrap; }


@keyframes toggle {
   50% {
      /* filter:opacity(0.25); */
      background-color:rgba(220,220,220,1);
   }
}
.searchWidget.datatype:not(.disabled),
.searchWidget.datatype:not(.disabled) > span {  animation: 1s toggle 1; animation-delay:0.75s; }

/*
.searchWidget.datatype > span:not(.disabled),
.searchWidget.datatype > span:not(.disabled) + label {  animation: 1s toggle 3; }
*/

/*
@keyframes toggleOff {
   50% {
      filter:opacity(0);
   }
}
.searchWidget.datatype > span.disabled,
.searchWidget.datatype > span.disabled + label {  animation: 1s toggleOff 3; }
*/




.searchWidget.datatype { margin:5px 0;}
.searchWidget.datatype .img {  display:inline-block;width:30px;height:40px;background-size:contain;background-position:center;vertical-align: middle;
   background-repeat:no-repeat; margin-right:15px;margin-left:-10px; cursor:pointer;
}
.searchWidget.datatype.corporation .img { width:45px; margin-left:-17px; margin-right:7px;}

[lang$="-x-iast"]:not(.hasSpace) { word-break: break-all; }

.widget label { text-transform:capitalize; /*word-break: break-all;*/ }
.searchWidget > label > span,
.dataWidget label > span { font-size:12px;  }
.searchWidget > label > span svg { width:16px; height:16px; } 
.searchWidget.datatype > label > span svg { width:18px; height:18px; } 
.searchWidget > label > span svg.excl { width:12px;height:12px; vertical-align:-4px; }
.dataWidget > label > span svg { width:17px; height:17px; }
.searchWidget > label > span:first-child,
.dataWidget > label > span:first-child { width:22px;height:22px; color:rgba(0,0,0,0.7); margin-left:9px;margin-right:2px; }

body > .collapse .searchWidget > label > span:first-child,
body > .collapse .dataWidget > label > span:first-child { margin-top:-2px;}
body > .collapse .searchWidget:not(:last-child) { margin-bottom:3px; }

.SidePane .help { text-align:left;font-style:italic;margin-bottom:10px; font-size:12px; width:300px;line-height:16px; }
/* .subcollapse label span:last-child { max-width:120px;} */
.dataWidget ol { margin:0; font-size:12px;padding-left:16px; list-style: none; counter-reset: li; margin-top:4px; /*pointer-events:none; opacity:0.55;*/}
.dataWidget ol li {counter-increment: li; z-index:100;}
body > .ol-li-lang:last-child li::before,
.dataWidget ol li::before {content: "(" counter(li) ")";  display: inline-block; width: 1.75em; margin-left: -1.25em ; font-weight:700; font-size:13px; }
/* .collapse { width:390px;}*/
.ol-li-lang label { text-transform: none;}
.ol-li-lang svg { color:rgba(161, 161, 161, 0.95); font-size:17px; opacity:0;vertical-align:middle;margin-left:5px;cursor:pointer;display:inline-block;}
.ol-li-lang svg.drag { position:absolute; margin-left:-36px;font-size:17px;margin-top:-1px;  padding-right: 5px;}
.subcollapse.custom-lang:not(.sorting) .ol-li-lang.active svg,
.subcollapse.custom-lang:not(.sorting) .ol-li-lang:hover svg { opacity:1; }
.subcollapse.custom-lang:not(.sorting) .ol-li-lang.active svg.drag,
.subcollapse.custom-lang:not(.sorting) .ol-li-lang:hover svg.drag { opacity:1; cursor: row-resize; }
.subcollapse.custom-lang.sorting, .subcollapse.custom-lang.sorting * { cursor:row-resize; }
.ol-li-lang { z-index:100 !important;height:26px;color:rgba(0,0,0,0.87);position:relative;}
.ol-li-lang a { position:relative; }
.resource .ol-li-lang a:hover { border:none; }
/*
ol .ol-li-lang:last-child li:before { display:none; }
.ol-li-lang:last-child .add { position: absolute; margin-left: -18px; margin-top: -2px; opacity:1; }
.ol-li-lang:last-child  label { padding-left:6px;}
*/
/* custom language reordering */
body > .ol-li-lang:last-child { z-index:101 !important; position:relative; }
body > .ol-li-lang:last-child li { font-size:13px; list-style-type: none; }
body > .ol-li-lang:last-child svg { opacity:1;}
body > .ol-li-lang:last-child li::before { content:'(--)';}

#resetCustom { opacity: 0;  vertical-align: -3px !important; cursor:pointer; }
#resetCustom svg { font-size:20px;  }
.dataWidget:hover #resetCustom { opacity:0.55; }

#translitPopup li.selected { background-color:#eeeeee;}


#outline .widget { min-width:100%; }
#outline .widget .disabled + span { opacity:0.55;}
#outline .widget .ol-li-lang li label span:not(.a) { font-size:13px;}
#outline .widget > label {display:inline-flex;}
#outline  .dataWidget > label > span:first-child { margin-right:5px;}
#outline  .dataWidget:last-child > label { margin-right:5px;}
#outline .widget ol { margin-left:4px;}
body > .ol-li-lang label span:not(#a),
#outline .widget label span:not(#a) { font-weight:700; }
#outline .widget label > span > span { display:inline-flex; }
body > .ol-li-lang  label span:not(#a) .lang-info,
#outline .widget label span:not(#a) .lang-info { font-weight:400; text-transform:none; padding-left:4px; }
#outline .widget label > span:last-child > span + span::before { content:" + "; font-weight:400;padding:0 4px;}
#outline .widget label .checkbox:not(.disabled) { opacity:0.8;}

@media(min-width:666px) {

   #outline .widget { min-width:calc(100% - 210px); }
}

.collapse.sortBy > div:last-child { padding:20px;} 
.collapse.open { margin-bottom:20px; z-index:100000;}
/* .collapse.open div { overflow:visible hidden;} */
.searchWidget .subcollapse:not(.open) { width:250px; }
.searchWidget .subcollapse.open { width:auto; max-width:350px; }
.searchWidget > label + .subcollapse { width:auto !important; }
.searchWidget > label { margin-right:5px;}

.SidePane > div > .collapse > div > div > .searchWidget { padding-right:10px; }

.searchWidget { position:relative; }
.searchWidget > .exclude { position:absolute;margin-left:-17px;margin-top:2px; display:none; z-index:1;top:0;left:0;width:16px;padding-left:3px; }
.searchWidget > .exclude svg, svg.excl { font-size:12px; color:white;background-color:#d73449;border-radius:2px; cursor:pointer;}
.exclude:hover, .searchWidget label:hover ~ .exclude { display:inline-block;}

.widget > label { display:inline; padding-left:3px;padding-bottom:2px; z-index:0;  overflow:visible;     }
.widget > label > * { display:inline; vertical-align: text-top; }
.widget > label > .checkbox { display:inline-block; width:18px !important;height:18px !important; }
.widget > label > .checkbox > span:first-child { display:inline;  }
/* .widget > label > span:last-child:nth-child(2) { vertical-align:1px; line-height:20px;} */
.widget { padding-left:18px;} 
.widget > label > .checkbox { left:-18px;} 
.widget { line-height:12px; margin:6px 0;  }
.widget > label > span:last-child:nth-child(2) { margin-left:-18px;}
.widget > .subcollapse { padding-left:18px !important;margin-left:-18px;}

.widget > label > .checkbox.partial::before { content:'';width:0px !important;height:0px !important;display:inline-block;position:absolute;
   border-top:12px solid #d73449; border-right:12px solid transparent ; left:3px;top:2px;z-index:0;
}

.widget > * { vertical-align:text-bottom !important;}


.facetWidget.century .checkbox + span { text-transform: none;}

.facetCollapse .widget:first-child { margin-top:0;  }

span.subcollapse { opacity:0; transition:opacity 50ms ease-in-out ;  }
span.subcollapse svg { vertical-align:-6px; cursor:pointer; font-size:18px; }
.collapse.open > div > div > .widget > span.subcollapse, 
.collapse.open .subcollapse.open > div > div > .widget > span.subcollapse
{ position:absolute;  opacity:1; transition:opacity 150ms ease-in-out 50ms; } 

span.subcollapse.off { opacity:0.5 !important; }

.img.disabled,
.checkbox.disabled { opacity:0.5; pointer-events: none; }


h4 span.highlight,
h3 span.highlight,
div.match span.highlight { 
   /* border:1px solid rgba(229, 74, 53,1); padding:0px 4px ; background-color:rgba(229, 74, 53,0.15); border-radius:15px; } */
   background-color: #fce08e;font-weight:700;border-radius:2px;padding:5px 5px; position:relative;z-index:-1;
}
div.match span.highlight { padding:0px 5px; }
#matches div.match div.multi > span { display:block; }
/* div.match div.multi > span:not(:last-child)::after {  content:", "; } */

/* div.match span.highlight { background-color: rgba(229, 74, 53,1.0); color:white ; padding:0px 2px ;}  */

.urilink .highlight { color:rgba(179, 14, 0,1) ; } 

#UView { width:800px;height:480px;border:none; overflow:visible; }

#fond:not(.hidden) { position:fixed;width:100%;height:100%;background:rgba(0,0,0,0.5); left:0;top:0;z-index:150; }
#fond.hidden * { display:none !important; }

svg.hide { opacity:0;pointer-events:none; }

.uv:not(.hidden),.uv:not(.hide),uv.toggled:not(.hide) iframe { z-index:10 !important;position:fixed !important;width:100% !important;left:0 !important;height:calc(100vh) !important;top:0 !important;
   z-index:11;
}
.uv.hidden,.uv.hide { display:none !important;}

.data#first-image { padding-left:35px;padding-right:35px;}

.firstImage { /*box-shadow: 0 0 10px black;display:inline-block;*/padding:5px;background:rgba(0,0,0,1.0);position:relative; min-width:360px;min-height:100px;text-align:center; 
   margin-top:0px; width:100%;height:500px; display: flex; justify-content: center; align-items: center; box-sizing: border-box; margin-bottom:0px;}
.firstImage img {max-width:100%; position:relative;z-index:1; max-height:444px;}
.firstImage.loaded { font-size:0;  }
.firstImage.loaded #title { position:absolute;font-size:17px;left:20px;bottom:20px; z-index:2; }
.firstImage.loaded #title > div { background-color:rgba(229, 74, 53,1);color:white; display:inline-block;position:relative;
   padding:5px 50px; border-radius:0px; border:1px solid rgba(229, 74, 53,1); cursor:pointer;margin-right:20px;
   width:200px;text-align:center; margin-top:10px;}
.firstImage.loaded #title > div * { vertical-align:middle; }
.firstImage.loaded #title > div:hover { color:rgba(229, 74, 53,1);background:rgba(255, 230, 220,1);border-color:rgba(229, 74, 53,1); }

.firstImage + .data { margin-top:15px; }

.title + .data .browse { display:inline-block !important ; width: auto !important; } 


.title ~ .data .browse a { font-size:14px; text-transform:capitalize; border:none !important;}

/* .resource .title + .data + .data .browse { display:none; } */

.uvLoader { z-index:0 !important;}

#diva-wrapper { top: 0;left: 0; position: fixed; width: 100%; height: calc(100% - 10px);
   background: white; padding-top: 10px; z-index:11;}
/*#diva-wrapper > div { top: 0;left: 0; position: fixed; width: 100%; height: calc(100% - 100px) !important; z-index: 10;
   background: white; padding-top: 10px; }*/
#diva-wrapper.hidden { display:none; }
#diva-wrapper .diva-tools { text-align:center; }
#diva-wrapper .diva-tools select#volume { display:inline-block; font-size:16px;height:28px; margin-top:10px;
   padding:0 10px;margin-left:15px;}
.diva-tools-right { width:400px; }
.diva-tools-left { padding-right:100px; width:300px;}

.resource .profile-container { background:white; padding:0; }
.profile-container { padding:40px 100px; }
.profile-area h1 { margin-top:0; }
/*
.profile-area img { height: 64px; vertical-align: middle;  box-shadow:0 0 5px rgba(0,0,0,0.45); display:inline-block; margin-right:16px;}
*/ /* border: 1px solid #ccc;*/
.profile-area h5,.profile-area a.ulink { font-size:14px; color:rgba(229, 74, 53,1); cursor:pointer; margin-top:50px; font-weight:700; text-decoration:none;}
.profile-area h5:hover,.profile-area a.ulink.on:hover { text-decoration: underline; }
.profile-area a.ulink:not(.on) { cursor:auto; opacity:0.5; }

.profile-area .FC { min-width:222px; margin-right:20px;}

.profile-area .checkbox { width:42px; height:42px;}
.profile-area .checkbox svg { color:rgba(0, 0, 0, 0.54); font-size:18px; }

.profile-area .FC + br + label { margin-top:4px; }

.ulink#upd { margin-top:30px; display:inline-block;}

#TestToken { padding:5px;  }

.FCTT { width:400px;  padding-top:5px;  }
.FCTT + h2 { margin:0 50px;padding:0;text-align:right;}
.FCTT + h2 pre { margin-bottom:0; }
.FCTT ~ pre { margin:50px ; max-width:calc(100% - 100px); overflow:auto ; padding:20px; box-shadow:0 0 5px rgba(0,0,0,0.4); margin-top:5px;}


.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

/*
.nav,
body {
   min-width:800px;
}
*/


/* specific font sizes for tibetan */

#matches > .match > span:not(.lang):not(.instance-link)[lang=bo],
#matches .match span[lang=bo],
#pagine[lang=bo] span,
.searchWidget .facet-count[lang=bo],
.searchWidget [lang=bo],
#res-header > div .widget-header .widget-title [lang=bo],
.SidePane.left .widget-title [lang=bo],
.active-filter[lang=bo] span { font-size:14px;}
.active-filter[lang=bo] { height:32px;}

a.result .contenu ul > div:last-child h3[lang=bo] span { font-size:18px;}






/* feedbucket */
.nav + #feedback:not(.on,.off),
feedbucket-app:not(.on)  { display:none !important; }

#root.recording ~ feedbucket-app  { display:block !important; }

.nav + #feedback { position:fixed; color:#d73449; background:white; display: flex; border-radius: 20px 20px; width:40px; height: 40px; box-sizing: border-box; 
   box-shadow: 0 2px 4px rgba(187, 187, 187, 0.5); bottom:calc(50% - 20px); right:10px ; cursor: pointer; justify-content: center; align-items: center; z-index:1000000;
}
.nav + #feedback svg { width:24px; height:24px; display:none; position: absolute;}
.nav + #feedback:not(.X) svg:first-of-type { display: block;}
.nav + #feedback.X svg:last-of-type { display: block;}

.nav + #feedback.X { top:calc(50% + 117px); right:10px; bottom:auto; }


.H100vh .nav + #feedback:not(.X) {
   right:20px;
}


/* better not hide Monlam data in etext viewer */
.nav.etext-nav + #feedback:not(.X) {
   left:15px; bottom:100px;
}


@media (max-width:1024px)
{
   .H100vh .nav + #feedback:not(.X) {
      bottom:calc(50% - 20px); right:20px;
   }

   .nav + #feedback {z-index:100000; }

   
   .nav + #feedback:not(.X) { right:10px; bottom:5px;    
      width: 52px;
      height: 52px;
      border-radius: 26px;
   }
   
   .nav.etext-nav + #feedback:not(.X),
   .nav + #feedback.top:not(.X) { bottom: 5px; left:5px;}
   
   .nav.etext-nav + #feedback:not(.X) { width:52px; height:52px; border-radius:26px; }
   
   .nav + #feedback svg { width:28px; height:28px; }

   .H100vh .nav + #feedback {z-index:1000000;display:flex !important; }
}

@media (max-width:800px)
{
   .nav + #feedback:not(.X) { width:50px; height:50px; border-radius:25px; }
   
   /*
   .H100vh .nav + #feedback:not(.X) {right:5px;top:5px; left:auto;}
   */
   .H100vh .nav + #feedback { display: none !important; }
}


/* TODO: when is video disabled?  */

/*
@media (pointer:coarse) {
   .nav + #feedback.X { top:calc(50% + 60px); }
}
*/







/* safari css fix for columns (#227) */
@media not all and (min-resolution:.001dpcm) { 
   @supports (-webkit-appearance:none) and (stroke-color:transparent) {

      .resource .data .propCollapseHeader,
      .resource .data .propCollapse.in-true { margin-top:-5px; }

      .resource .data .propCollapseHeader h4,
      .resource .data .propCollapse.in-true h4 { margin-top:8px; }

      .resource .data .propCollapseHeader .expand,
      .resource .data .propCollapse.in-true .expand { margin-top:10px !important; }

   }
}


@media (max-width:1600px)
{
   /* body { min-width:1400px; overflow:auto;} */
   .SearchPane { transition:all 400ms ease-in-out;}
   .SidePane.visible + .SearchPane { padding-left:300px; }
   .SidePane.visible + .SearchPane .mainloader { padding-left:200px; }
}

@media (max-width:1440px)
{

   .infoPanel + .resource > .ariane {
      padding-left:0;
      padding-right:0;
      width:1222px;
      box-sizing:border-box;
      margin-left:auto; 
      margin-right:auto;
      left:calc((100% - 1280px) / 2);
      padding-left:100px;
      padding-right:100px;
   }


   .open-etext { right:0px; }

   .resource > .ariane { margin-left:0; padding-left:16.7%; }

   #root > div > :is(.App.home:not(.guidedsearch),.resource) { overflow:visible ; width:100%; box-sizing: border-box; 
      padding-left:calc((max(100%, 1280px + 40px) - 1280px) / 2);
      padding-right:calc((max(100%, 1280px + 40px) - 1280px) / 2);
   }
   
   #root > div > .resource > div > .data { padding-left:100px; padding-right:100px; }  
   
   #root > div > .resource .data#perma,
   #root > div > .resource .data.legal-props,
   #root > div > .resource .data.ext-props {
      padding-left:65px;
      padding-right:65px;
   }

   /*
   .resource .data.ext-props > a:first-child {
      left:70px;
   }
   */

   /*
   #root .App .SearchPane,
   #root > div > .resource { padding:155px calc((100vw - (1440px - (140px * 2))) / 2); padding-bottom:0; } 
   */
   #root .App .SearchPane { padding-top:50px;}

   #root > div > .resource {   padding: 155px 30px; }
   #root > div > .resource > .index { z-index:0; }
   #root > div > .resource > .index + div { z-index:1; position:relative; }

   /* body { min-width:calc(100% - 500px);} */

   .SearchPane { width: calc(100% - 500px); }

   .SidePane:not(.visible) + .SearchPane { width:100%; }

}

#more-pages { display:none; margin-left:20px; cursor:pointer;}
#more-pages-menu li:not(#a) { height:auto; padding:0; }
#more-pages-menu li:not(#a) a { display:block; padding:12px 16px;}

@media (max-width:1320px)
{
   #latest .seeAll { margin-right:60px;}
   
   /*, #samples*/ 
   /* no need / sticky
   #res-header > div, #results { margin-left:calc((100% - 700px)/2);}
   .SidePane.left { position:absolute; left:0;top:0; z-index:0 ;} 
   #res-container { position:relative; } 
   */

   .nav { padding-left:24px;padding-right:24px; max-width:100%; box-sizing: border-box;} 

   #more-pages > span,
   #more-pages { display:flex; align-items: center; }


   #about, 
   #guide,
   #new-search
   /* #about + a:not(#collections)  */
   { display:none;}

   .nav .history { margin-left:auto;}


   footer#site-footer > div.foot, footer#site-footer > div.body { padding:40px 24px; width:100%; box-sizing: border-box; }


   #latest > div { padding:40px 20px;}
   #latest .slide-bg > div { width:calc((100vw - 60px * 2 - 25px * 3 - 5 * 20px) / 5);margin:0 10px; }
   #latest .slide-bg > div .header { height:calc((100vw - 60px * 2 - 25px * 3 - 5 * 20px) / 5); }
   #latest .slide-bg > div .header::before { background-size:40% auto; width:60%;height:60%}
   #latest .slide-bg > div p { overflow-wrap: break-word ;}
   #latest .slide-bg.slided { margin-left:calc(-100% - 8px); }
   #latest h3 { padding-left:30px;}

   /* .nav a > span {margin-left:50px;} */

   #results { 
      width:calc(100% - 24px - 300px); 
      margin-right:24px;
      box-sizing: border-box;
   }
}



@media(max-width:1280px) {


   .resource .data.etext-title { flex-wrap:wrap; }

   /*
   .resource .data h2.intro + h2 span {
      max-width: none;
      white-space: break-spaces;
   }
   */
}

@media (max-width:1200px)
{
   
   #res-header #pagine { padding-right:24px; }
   
   #res-header > div { width:100%; margin-left:0; }
   
   footer#site-footer > div.foot #t2::before { left: calc(-65 * (100vw / 1920)); }

}

@media (max-width:1280px)
{
    /* #pagine div a[data-i="3"][data-max="true"] ~ [data-i] { display:none; } */
}


#settings { display:none; }



@media (max-width:1024px)
{

   #root > div > .resource.etext-view { padding-top:100px; }
   .nav #back { margin-left:auto;}
   
   /* #res-header > div .widget-header {  margin-left: 98px; } */

   #res-header > div { margin-left:0px; width:100%; padding:0 24px !important;}

   .nav #donate { width:60px; color:transparent;padding-left:10px; font-size:0; }
   #results, #samples { width:100%;margin:0 auto; padding:30px calc((100vw - 700px) / 2);}

   .resource .title h2 .RID { max-width:80%; }
   
   #settings { display:block; }
   #settings img { vertical-align: middle; }

   .result-content { padding-left:0;padding-right:0; }
   .result-content #permalink { right:0; }

   #res-container > ul h4 { padding-left:0;}

   #res-header > div .widget-header {
      margin-right: auto;
   }

   #res-header #pagine,
   #res-header > div .widget-header { margin-top:0; }

   .SidePane.left { min-height:0; z-index:1000; margin-top:-10px;padding-top:10px;padding-left:24px;}
   .SidePane.left.closed { left:-500px;}

   .resource .title h2 .RID { max-width:60%; }

   #root > div > .resource { padding:155px 24px; padding-bottom:80px; margin:0; box-sizing:border-box;}

   #root > div:not(.withMonlam) > .resource { min-width:100%; }
   .resource ~ .monlamResults > div { top:0; height:100%; }
   
   .resource ~ .monlamResults.visible, .resource ~ .monlamResults.visible > div { width:min(400px,100vw - 75px); }

   #etext-nav #DL, 
   #etext-nav #control + a,
   #etext-nav #dico { font-size: 0;   white-space: nowrap;}

   #etext-nav #dico { display:flex; align-items: center;}

   #etext-nav #dico .new { display:none;}

   #etext-nav #control + a #check { margin-right: 0; }
   #etext-nav #control + a img { margin: 0 0 0 10px; }
   #etext-nav a #check { margin-right:10px; }
   #etext-nav #dico img.ico { margin-left:0; }

   .resource:not(.etext-view) > :not(.index,.ariane,.simplebar-track) { width:100%; margin:0;}

   .resource > .ariane { padding-left: 0; } 
   /* calc((100% - 700px) /2) ; } */


   footer#site-footer > .body #b2 #f1 { border:none; }
   footer#site-footer > div.foot #t2::before { display:none; }

   footer#site-footer > .body > div,
   footer#site-footer > .foot > div { display:block; margin:auto; width:600px;}


   footer#site-footer > .body > div > div,
   footer#site-footer > .foot > div > div { width:50%; }
   
   footer#site-footer > div.foot #t2,
   footer#site-footer > .body #b2 { margin-top:40px;}

   footer#site-footer > .body #b2 #f2 > div { padding-right:0; }

   footer#site-footer > .body #b2 #f2 #s1 { justify-content: flex-end;}
   footer#site-footer > .body #b2 #f2 #s1 > a { margin-right:0;margin-left:10px; }
   
   footer#site-footer > .body #b1 #f2 { text-align:right;}

   footer#site-footer > div.foot #t1 > #p2 { justify-content: flex-end;}

   /*
   footer#site-footer > div.foot #t2 { padding-left:150px;}
   footer#site-footer > div.foot #donate { margin-left:20px; float:none;}
   */

   
}



@media (max-width:865px)
{

   .nav { padding:0 calc((100% - 700px) / 2); }
   .nav #lang { margin-left:20px; }
   .nav #donate { width:40px; margin-left:15px; }

   #res-header > div .widget-header { 
      margin-left: 145px;
   }
   #res-header > div { margin-left:0; padding-left:calc((100% - 700px) / 2); box-sizing: border-box;padding-right:calc((100% - 700px) / 2);}

   #res-header #pagine { padding-right:0; }


   .SidePane.left { padding-left:calc((100vw - 700px) / 2 );}



}


#root.auto_rela > .auto_rela > .resource { padding:0; }
#root.auto_rela > .auto_rela > .infoPanel,
#root.auto_rela > .auto_rela > .resource > .auto_rela > :not(#viewer),
#root.auto_rela > .auto_rela > .resource > .index { display:none; }
#root.auto_rela > .auto_rela > .resource > .auto_rela { margin-left:0; width:100%;}

a.result + #matches.mobile,
.App #results .more-details,
.SidePane.left #closeSettings { display:none; }


@media (min-device-width:801px)
{
   .mobile-button.top
   /* .nav .close  */
   { display:none; }
}


@media (max-width:1100px)
{
   .App.guidedsearch.khmer .static-container.sticky .buttons { display:inline-flex; flex-direction: column; width:75px; justify-content: space-between; height:70px; align-items: flex-end;}

   .App.guidedsearch.khmer .static-container.sticky > div:first-child { top:0; }
   .App.guidedsearch.khmer .static-container.sticky > div:first-child h1 { padding-top:0; padding-bottom:5px;}
   .App.guidedsearch.khmer .static-container.sticky > div:first-child { display:flex; align-items: center; padding-top:10px;padding-bottom:25px;justify-content: space-between;
      padding-right:0;
   }

   .App.guidedsearch.khmer .static-container.sticky > div:first-child .selector [data-prop] { position: relative; top:auto; right:auto;}

}

#root .nav .close { font-size:40px; transform:rotate(45deg); margin-left:35px; cursor:pointer; display: block;}
.nav #back { margin-left:auto; }

#root .nav #donate + .close { display:none;}


#search-bar > div:first-child input { line-height:35px; }

   
@media (max-width:1024px)
{
   
   .nav #logo a img {  height:40px !important; }

   .nav .close { display:block; }
   
   /* ------------------------------------------------------------------------------------------------------------------------ home/topbar */ 
   #root .nav {  height:60px; padding:0 10px; top:0px; transition:all 400ms ease-in-out; 
      z-index: 100001; 
      /* padding-left:55px;  */
   }
   #root .App .SearchPane { margin-top:0px; }
   #root .nav .history,
   #root .nav #logo > :not(:first-child) { display: none;}
   #root .nav #donate {  margin-left:35px; 
      /* width:150px; color:white; padding-left:0; */
   }
   /* #root .nav #lang,
   #root .nav #login span { margin-left:35px;} */


   /* #root .nav:not(.on) { top:-100px; } */
   

   .fond-logo img { height:100vh; }

   /* .SearchPane #logo > div {
      transform: scale(0.75);
      transform-origin: 100% 50%; 
   }
   */

   .App:not(.home) #search-bar {padding-top:20px;}

   

   #root .App #res-container,
   #search-bar > div:first-child > div:first-child { width:100%; box-sizing: border-box;}

   

   .mobile-button.top {
      top: 5px;
      left: 5px;
      background-color: white;
      position: fixed;
      border-radius: 50%;
      font-size: 0;
      padding: 5px;
      z-index: 1000000;
      width: 40px;
      height: 40px;
      display: flex;
      transition: all 400ms ease-in-out;
      align-items: center;
      justify-content: center;
      box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.125);
      transition:all 400ms ease-in-out ;
   }
   .mobile-button.top.on {
      box-shadow:0 0 0px 0px rgba(0, 0, 0, 0);
      transform:rotate(180deg);
   }

   #root .App #data-checkbox { display:none; }

   #root .App #res-container { margin:0px auto; padding:30px;}


   #root .App #res-container h4,
   #root .App #res-container p { width:100%; max-width:100%;margin:0;padding:10px 20px; box-sizing: border-box; display: block;}

   .no-result.more-result #clear-filters { margin-left:0; display:flex ; }
   
   #root .App #latest h3,
   #root .App #res-container #samples h3 { font-size:24px; line-height:26px;}

   #root .App #res-container h4 { font-size:14px;}

   #latest,
   footer#site-footer > div.foot, footer#site-footer > div.body {
      padding: 40px ;
   }

   footer#site-footer > .body > div, footer#site-footer > .foot > div { max-width:100%; }

   #latest { padding-top:30px; }

   #latest .seeAll { top:33px; right:40px; margin-right:0;}
   #latest h3 { padding-left:0px; }

   #latest > div:first-of-type { margin-top:30px; padding:30px;  }

   #latest #syncs-nav { margin-top:30px; padding:0 0 30px 0; }

   #latest .slide-bg > div {
      width: calc((100vw - 2*40px - 30px - 2*15px) / 3);
      margin:0; margin-right:15px;
   }
   #latest .slide-bg > div .header {
      height: calc((100vw - 2*40px - 30px - 2*15px) / 3);
   }

   #latest .slide-bg.slided {
      margin-left: calc(-100% - 60px);
   }

   
   /*
   #latest .slide-bg:not(.slided) > div:nth-child(-n+3), 
   #latest .slide-bg:not(.slided) > div:nth-child(-n+3):not(:nth-child(-n+5)) { opacity: 1;}

   #latest .slide-bg.slided > div:nth-child(-n+3),
   */

   #latest .slide-bg:not(.slided) > div:not(:nth-child(-n+3)) { opacity:0; }

   #latest .slide-bg.slided > div:not(:nth-child(-n+3)) { opacity:1; }


   /* ------------------------------------------------------------------------------------------------------------------------ results */

   .resultsMap { height:75vh;}

   #root .App .resultPage #res-container { padding-top:0; }
   #root .App .resultPage #filters-UI > div { padding-left:40px;padding-right:40px; }
   #root .App .resultPage #res-header > div { padding-left:40px;padding-right:40px; }
   #root .App .resultPage #res-header > div .widget-header { margin-left:0; margin-right:auto;}
   #root .App .resultPage #res-container h4 { font-size:24px;}
   #root .App .resultPage .result-content { padding-left:20px;padding-right:20px;}
   .result-content #permalink { right:20px;}
   a.result  {  max-width: calc(100% - 30px); min-height:90px; }
   #matches .match { max-width: calc(100% - 40px);}
   a.result + #matches.mobile { margin-left:5px; display:block; padding-top:40px; }
   a.result + #matches.mobile .more-details { margin-left:-5px;margin-top:5px;}

   .noThumb + a.result + #matches.mobile { padding-top:0px; }

   a.result ~ #matches:not(.mobile) > .match + div:not(.match) > .match,
   a.result ~ #matches:not(.mobile) > .match { flex-wrap:wrap;  }

   a.result ~ #matches:not(.mobile) > div > .match > .instance-link,
   a.result ~ #matches:not(.mobile) > .match > .multi { width:100%; padding-left:15px; }

   a.result ~ #matches:not(.mobile) > div > .match > .instance-link { padding-top:3px; }

   #matches .match.by + .by > span.label, #matches .match.publisher + .publisher > span.hidden-en { display:none; }

   .person + a.result { min-height: 0;}
   .person #matches > .match.dates { padding-left:5px; }

   #settings { position:fixed;bottom:5px;right:5px; border-radius:50%; background:white;box-shadow: 0 0 5px 0px rgb(0 0 0 / 13%); 
      padding:5px; z-index:1;
   }

   .SidePane.left { position:fixed; right:0; left:auto; transition:right 400ms ease-in-out; padding:10px; height:100vh;overflow:scroll;}
   .SidePane.left.closed { right:-300px; left:auto;}
   .SidePane.left #closeSettings { display:block; position:absolute;right:0px;top:10px;}
   .SidePane.left .widget-header  { text-align: left;  }
   #root .App #res-container .SidePane.left p  { padding-left:0;  }
   #pagine div a { margin:0 8px; }

   .result-content #icon { width: 120px;}
   .result-content #icon > .thumb { width:100px; height:100px;}
   .result-content #icon > .thumb img {width:100px; height:100px;object-fit: cover; }

   .result-content > .result > .contenu { margin-left:125px;}
   a.result ~ #matches:not(.mobile) { margin-left:150px; }

   .match .inRoot + .sepa { display:block;border:none; }

   .result-content > #num-box,
   .result-content > #icon { float:left; position: relative;}

   .result-content #icon.wCopyR { margin-bottom:0;  }

   

   a.result {  max-width: calc(100% - 150px); }   
   .result-content > .result > .contenu { margin-left:0;max-width:calc(100% - 40px); padding-left:5px; }
   a.result ~ #matches:not(.mobile) { margin-left:0; padding-left:5px; clear:both;  }

   #root .App .resultPage .result-content .result-content,
   a.result ~ #matches:not(.mobile) > .match ~ .label,
   a.result ~ #matches:not(.mobile) > .match { padding-left:0px; }


   a.result ~ #matches:not(.mobile) > .match ~ .label { padding-bottom:10px; }

   
   #root .App .resultPage .result-content .result-content.etext {  padding-top:5px;padding-bottom:3px; padding-right:0;  }

   
   #root .App .resultPage .result-content .result-content.etext #icon + .result { display:none;  }

   #root .App .resultPage .result-content .result-content.etext #permalink { top:12px; right:0; }

   #root .App .resultPage .result-content .result-content.etext #matches .match { padding-left:10px; margin-top:0;  }

   #root .App .resultPage .result-content .result-content.etext #matches { display:inline-block; clear:none; padding-left:0; position:relative; max-width: calc(100% - 40px);
      margin-bottom:0;
   }


   /* .App #results .result-content .result-content .more-details { display:none; } */
   
   .result-content .result-content #icon { width: 80px; }
   .result-content .result-content #icon > .thumb { width:60px; height:60px; }

   a.result ~ div a.result { max-width: calc(100% - 110px); min-height:70px; }
   a.result ~ div a.result ~ #matches.mobile { padding-top:20px; }

   .result-content .result-content > .result > .contenu { padding-left:7px; }

   .App #results .more-details { display:inline-block; margin-top:10px;  cursor:pointer;}

   .App #results #matches.mobile.on + #matches:not(.mobile) .match.dates,
   .App #results #matches.mobile.on + #matches:not(.mobile) .match.inRootInst,
   .App #results #matches.mobile.on + #matches:not(.mobile) .match.by,
   .App #results #matches.mobile:not(.on) + #matches:not(.mobile),
   .App #results .more-details:not(.on) + #matches:not(.mobile) {display:none; }

   .App #results #matches.mobile.on + #matches:not(.mobile) .match.by ~ .match.by { display:flex; }
   .App #results #matches.mobile.on + #matches:not(.mobile) .match.by ~ .match.by .label { visibility: visible;}

   .result-content.wThumb { min-height:110px;}

   .result-content #icon img { max-height:60px; }

   
   .App #results a.result + .more-details + #matches:empty {
      display: block;
      height: 24px;
      margin-top: -32px !important;
      background-color: white;
      position: relative;
   }

   
   .result-content:hover a.result + .more-details + #matches:empty {
      background-color: #f5f5f5;
   }
   
   .result-content:hover .result-content:hover a.result + .more-details + #matches:empty {
      background-color: #f1f1f1;
   }
   
   

  
   /* ------------------------------------------------------------------------------------------------------------------------ resource */

   #root > div > .resource { padding:5px; padding-top:60px; }

   .resource > .ariane { left:75px; top:18px;} 

   #root > div > .resource > .index { display:none; }

   .resource:not(.etext-view):not(.static) > :not(.index,.ariane,.simplebar-track) { margin-left:0; width:100%;}
   
   .resource .data#first-image.simple,
   .resource .title + .data,
   .resource .data#head { padding:15px;}

   .resource .data#outline,
   .resource .data.related,
   .resource .data.ext-props,
   .resource .data.legal-props,
   .resource .data#perma,
   .resource .data { padding-left:15px; padding-right:15px;}

   .resource .data h2.on ~ .date,
   .resource .data#outline > div,
   .resource .data h2.on,
   .resource .data.related > div:not(#a):not(#b):not(#c),
   .resource .data.ext-props > div,
   .resource .data.legal-props > div,
   #perma > div { padding-left:20px; padding-right:20px;}

   .resource .data h2.on { padding-right:0;}

   .resource .data.top-props,
   .resource .data.bot-props { padding-left:35px; }

   .resource .data .header { height:120px;}

   .resource.user .data .header .before, .resource:not(.user) .data .header::before {width:100px;height:100px; background-size: 65%; }
   
   .resource:not(.user) .data .header.place::before { background-size:40%; }


   /* .firstImage { height: 100vh;} */
   
   .resource .data.related > div h2,
   .resource .data.ext-props > div h2 { margin:20px 0; }

   .resource .data > div > h3 { width:calc(155px - 15px); }

   .resource .data:not(#perma) > div:not(.firstImage):not(.browse):not(.header)::after { margin:25px 0 25px 155px; width: calc(100% - 155px); }
   .resource .data .group { width: calc(100% - 160px); }

   .resource .sub > h4.label:not(:first-of-type) {}

   .resource .data.legal-props > div h3 {  width: 105px; padding-right:30px;  }

   #perma #rid { margin-left:22px; }

   .resource .data .propCollapseHeader > .sub > h4.first, 
   .resource .data .propCollapse > div > div > .sub > h4.first, 
   .resource .data > [data-prop="bdo:creator"] .subgroup .subsub:first-child, 
   .resource .data > div > .group > .sub > h4.first { width:120px; }

   .resource .sub > h4.label:not(:first-of-type) { max-width: calc(100% - 120px - 15px - 10px); padding-right:0; }

   .resource .data > div .sub > .subgroup > .subsub > h4.first { width:100px; }

   .resource .data > div > .group > .sub > .subgroup { width: calc(100% - 100px - 15px - 20px)}

   .resource .subsub { padding-right:0; }

   .resource .data > div:not([data-prop="bdo:creator"]):not([data-prop="bf:identifiedBy"]):not([data-prop="bdo:corporationHasMember"]) .sub > .subgroup > .subsub > h4.first + * 
   { width:calc(100% - 100px - 15px )}

   .resource .data [data-prop="bf:identifiedBy"] .sub.keep > h4.first + .subgroup, .resource .data .sub.willK:not(.keep) > h4.first + .subgroup, .resource .data .sub.willK:not(.keep) > h4.first + h4.label {
      margin-left: calc(120px + 15px);
   }

   .resource .data [data-prop="tmp:propHasEtext"] .images-thumb.no-thumb { margin-right:20px; }

   .resource .data .group > h4 {
      max-width: calc(100%);
      padding-right: 0px;
  }

   #popHoverM { left:14px !important; }
   #popHoverM > div:last-child { width:calc(100vw - 30px); overflow:hidden; }
   #popHoverM > div:last-child .resource .data .react-tabs { min-width:calc(100% - 50px ); margin-left:0; }

   #popHoverM > div:last-child .resource .data { padding-left:25px;}

   .propCollapse,
   .has-collapse.custom .propCollapseHeader,
   .resource .data [data-prop="bdo:workHasInstance"] .group {  
      -webkit-column-count: 1; column-count: 1; 
      width:calc(100% - 150px - 5px); padding-right:0; 
   }

   .expand { margin-left:155px;}

   .resource .data .images-thumb,
   .resource .data [data-prop="bdo:workHasInstance"] .images-thumb { width:115px;height:115px; margin-right:17px;}


   .resource .data > [data-prop="bdo:workHasInstance"] .inst-prov {
      left: 80px;
      top: 80px;
   }

   .resource .data .images-thumb-links {margin-left:0;}

   .resource .data#outline > h2 { margin:0 0px 20px 20px; }

   .resource .data#outline > h2,
   .resource .data.related > div h2, 
   .resource .data.ext-props > div h2,
   .resource .data h2.on,
   .resource .data h2.on + h2 a span,
   .resource .data h2.on span:not(.T):not(.lang):not(#a) { font-size:28px;}

   .resource .data.images-title,
   .resource .data h2.intro + h2,
   .resource .data h2.on,
   .resource .data h2.on + h2 a span,
   .resource .data.related > div h2, 
   .resource .data.ext-props > div h2,
   .resource .data h2 { line-height:36px;  }

   .resource .data h2.intro + h2 { padding-left:20px; }

   .firstImage img { max-height:100vh; }
   
   /* ------------------------------------------------------------------------------------------------------------------------ topbar etc. */

   .resource .title h2 { font-size:22px;}

   .resource .title h2 .T::before { margin-right:20px; }

   .resource .title h2 .RID { left:70px; }

   .resource .data#outline div .root { flex-wrap:nowrap;}

   .infoPanel.inRes {  margin: 60px auto 0 auto!important; }
   
   #root > div > .infoPanel + .resource {  padding-top: 5px;  }

   #root .App .SearchPane, #root > div > .resource { position:static;}

   .infoPanel + .resource > .ariane { top:18px; }
   
   .nav #logo.khmer ~ #login > div.logged > span:first-child {
      margin-bottom: 0px;
   }
  .nav #logo.khmer ~ #login > div.logged > span {
      margin-left: 35px; 
  }

  .nav #logo.khmer ~ #login > div.logged { flex-direction:row; }

  .App.home.khmerServer section .abs div { padding-right: 0;}

  .App.home.khmerServer section .float img { object-position: center -14vw; }

  #root > div > .resource > .index { display:none; }


  .resource .data .firstImage { height:60vh; min-width:0vw; position:relative; }
  .resource .data .firstImage img { max-height:100%; max-width:100%;}

  .resource .data h2.intro + h2 span { max-width: calc(100%); }

  .resource .data h2.on + h3 { padding-left:20px; }


  #root .App .resultPage #res-header > div .widget-header { max-width:65%; margin-right:15px;}

  /* #root .App .resultPage #res-container { padding-left:0; padding-right:0; width:calc(100% - 40px) !important;} */
  #root .App .resultPage #res-header > div { padding: 0 20px!important; justify-content: center;}
  #res-header #pagine { flex-shrink: 0; }

  #res-header #pagine #nb:first-child { margin-left:0; padding-left: 0;}

  .App #results > .result-content .more-details,
  .App #results > .result-content > .mobile > .more-details,
  .App #results > .result-content > .more-details { width:100%; text-align: center; margin-top:0 ;}


  .SidePane.left > [data-simplebar] { padding:20px; }


  a.result ~ #matches:not(.mobile) > .match.Best > .label + span { padding-left:15px; } 

  .App #results .result-content.etext .result-content.etext > #icon,
  .App #results .result-content.etext .result-content.etext > .more-details { display:none; }

  .App #results .result-content.etext .result-content.etext { padding-left:15px; }

   #root > .H100vh .ariane { display:none; }

   .App #res-container > .loader:first-child:last-child { position:fixed; top:calc(50vh - 20px); background:white; padding:30px; border-radius:50%; }


   .popper .resource .data .group { max-width: calc(100% - 155px - 130px); }

   #popHoverM .resource .data .sub > .subgroup > h4,
   #popHoverM .resource .data .sub > h4.first { width:130px; }

   #popHoverM .resource .data .sub > .subgroup > .subsub {     width: calc(100% - 175px - 50px); }


   .hover-menu { display:none; }
}



@media (max-width:800px)
{

   /* .etextPage h4.page > div:not([class]) { display:none !important; } */

   /* #etext-nav #dico,
   .monlamResults.visible { display:none ; } */

   .resource ~ .monlamResults {
      position: absolute;
      height: 100%;
      z-index: 1;
      background: white;
   }
   .resource ~ .monlamResults.visible, .resource ~ .monlamResults.visible > div { width:min(400px,100vw - 50px); right:0; }
   .resource ~ .monlamResults, .resource ~ .monlamResults > div { transition:all 400ms ease-in-out; right:-400px; }

   #root > .withMonlam > .resource.etext-view { max-width:100%; }

   .App.home.khmerServer #search-bar > div { width:calc(100vw - 100px);}
   .App.home.khmerServer #search-bar > div > div { width:100% !important; }

   .App.home.khmer.static section.orange-gradient > div > div { height:150px; }
   .App.home.khmer.static section.orange-gradient > div > div > div { width:calc(100% - 135px - 40px); }
   .App.home.khmer.static section.orange-gradient > div > div > div > span { font-size:16px; }

   .App.home.khmer.static h1 { font-size:36px; }

   /*
   .resource .data#perma > div { display: block !important; text-align: center;}
   .resource .data#perma > div > :is(#permalink,#DL,#cite) { display: inline-flex; vertical-align: middle;}
   .resource .data#perma > div > #DL { padding-left:0;border-left:none;}
   
   
   */

   .resource .data#perma > div { flex-wrap: wrap !important; }
   
   .resource .data#perma > div > .NL.nl-same  { margin:5px; display: block; width:100%; }

   #perma #DL ~ #same { margin-left:0; }

   #perma #copyright {
      position: absolute;
      right: 20px;
   }


   .resource a.outL {
     display: block;
     width: 150px;
     margin: 20px 0 0 0;
   }

   .mirador-container .X { margin-left:24px; }


   .resource .data.related .react-tabs__tab-list { flex-wrap: wrap;  margin-bottom: 20px; }
   .resource .data.related .react-tabs__tab-list > li { width:100%; }


   .active-filter > span b span { max-width:45vw; }


   .resource .data.images-title h2.intro + h2 span { max-width:80vw;}


}

#about + a:not(#collections) svg,
#guide svg,
.not-logged svg,
.logged svg { display:none; }

@media (max-width:665px)
{

   #about + a:not(#collections) svg { display:block; }
   #about + a:not(#collections) > span { font-size:0; }

   /* .not-logged svg { display:block; }
   .not-logged > span { font-size:0; }

   .logged svg { display:block; }
   .logged > span { font-size:0; } */

   /* .nav #logo a#BDRC span, 
   .nav #logo a:last-child span
   { font-size: 0px; } */

   /* .nav #logo a:first-child span { display:none; } */

   .nav #logo.khmer + .links a { margin-left:0; margin-right:0px;}

   #more-pages { margin-left:0; width:70px; }
   
   
   .nav.khmerServer > div > #lang,
   .nav.khmerServer > div > #login > div > span, 
   .nav.khmerServer > div > :is(a,div,span) { margin:0 0 0 25px !important; }
   
   .nav.khmerServer > div > #login { margin-left:auto !important; }
   
   .nav.khmerServer > div > #logo { margin-left:10px !important;}
   
   .nav #logo.khmer + .links { width:130px; flex-shrink:0;   margin-left:15px !important;}

   footer#site-footer > .body > div > div, footer#site-footer > .foot > div > div { width: 100%; text-align:center;}

   footer#site-footer > .body #b1 #f2 { text-align:center; } 

   footer#site-footer > .body #b2 { margin-top:20px; } 
   footer#site-footer > .body #b2 #f2 { margin-top:20px; } 
   footer#site-footer > .body #b2 #f2 > div { float:none; }
   footer#site-footer > .body #b2 #f2 #s1 { justify-content: center; }
   
   footer#site-footer > div.foot #t1 > :is(#p1,#p2) { justify-content: flex-start; width:250px; display:flex; margin:auto; margin-bottom:40px;}
   footer#site-footer > div.foot #p2 img + div, footer#site-footer > div.foot #p1 img + div { width:100%; text-align: left;}

   footer#site-footer > div.foot #t2 #p1 { width:300px; margin:auto; display:block; margin-bottom:10px; max-width:100%;}
   footer#site-footer > div.foot #t2 #p1 > img { display:block; margin:auto; margin-bottom:20px;}

   footer#site-footer > div.foot #donate { float:none; margin-left:0; }

   .App.home.khmerServer .SearchPane #logo > div { padding-left:0; } 

   .SearchPane #logo > div > div { height:auto; padding:50px 50px;}

   #samples h3 { padding:0 20px;}

   #search-bar { position: relative;}
   #search-bar > div:first-child > div:first-child { height:85px !important;}
   #search-bar > div:first-child > div:first-child > div:first-child { margin-top:10px; width:calc(100% - 48px - 15px); margin-right:0;}

   .SearchPane .formControl {
      bottom: 40px;
      left: 56px;
      right: auto;
      margin-left: 0;
      width:auto;
   }

   .SearchPane .formControl > div > div > div { text-align: left;}

   #search-bar button { margin-top:20px;}

   .App.home.khmerServer #search-bar button { margin-top:6px; }

   .App.home.khmerServer section > div:not(#a), .App.home.khmer.static section.footnote > div, .App.home.khmer.static section > div {
      padding:30px;
   }

   .App.home.khmerServer section { padding:30px 0; }

   .App.home.khmerServer section p { max-width:100%; }

   .App.home:is(.khmerServer,.static.khmer) section .noteText:first-child { margin-top:0px; }

   .App.home.khmerServer section#learn > div { display: flex; flex-direction: column;}

   .App.home.khmerServer section .float { order:10; }
   .App.home.khmerServer section .float img { width:60vw;height:60vw; margin-left:0; object-position: center -18vw; }

   .App.home.khmerServer section .abs { order:11; margin-top:50px;}

   .App.home.khmerServer section > .imgs { text-align: center; }

   #uiLangPopup .bg,
   #uiLangPopup .fg { margin-left:0 !important; }

   .App.guidedsearch.khmer .static-container.sticky > div:first-child + div [data-prop="style"] .option { width:100%; }

   .App.guidedsearch.khmer .static-container.sticky > div:first-child { flex-direction: column; padding-left:0; }
   .App.guidedsearch.khmer .static-container.sticky > div:first-child::after { margin-left:0; }

   .App.guidedsearch.khmer .static-container.sticky .buttons { flex-direction: row; width:auto;height:auto;margin:10px 0;}

   .App.browse.khmer .SearchPane > div > div .param  .val.on + .param { width:fit-content;}
   .App.browse.khmer .SearchPane > div > div h1 + #samples { 
      max-width: none;
      width: fit-content; 
      min-width:100%; 
   }
   
   .App.browse.khmer .SearchPane > div > div .param.title { min-width:250px; }

   .App.browse.khmer .simplebar-content-wrapper { max-width:none; }

   .App.browse.khmer .simplebar-wrapper,
   .App.browse.khmer .simplebar-mask { min-width:330px; }

   .App.browse.khmer .SearchPane { overflow:auto; }
   

   .resource .data > div > h3 { width:100%; margin-bottom:5px;}

   .resource .data.ext-props > div[data-prop="adm:logEntry"]:not(#a) .sub > .subgroup > .subsub > h4.first + *,
   .resource .data .subgroup,
   .resource .data.ext-props .propCollapseHeader + div,
   .resource .data .propCollapse.in-true,
   .resource .data > div > h3 + * h4.first + *,
   .resource .data > div > h3 + * { padding-left: 20px; }

   .resource .data.ext-props > div[data-prop="bf:identifiedBy"] :not(.propCollapseHeader) .sub > .subgroup > .subsub > h4.first + *,
   .resource .data.ext-props .propCollapseHeader.in-false .subgroup {padding-left:0; }

   .resource .data:not(#perma) > div:not(.firstImage):not(.browse):not(.header)::after { width:100%; margin:25px 0 25px -10px; box-sizing: border-box;}

   .resource .data > :not([data-prop="bdo:note"]) .sub:not(:first-of-type) { border-top:none; }

   .resource .data > [data-prop="bdo:note"] > div,
   .resource .data > div .sub > .subgroup > .subsub > h4.first,
   .resource .data .propCollapseHeader > .sub > h4.first, 
   .resource .data .propCollapse > div > div > .sub > h4.first, 
   .resource .data > [data-prop="bdo:creator"] .subgroup .subsub:first-child, 
   .resource .data > div > .group > .sub > h4.first { width:100%; margin-bottom:5px;}

   .resource .data > [data-prop="bdo:creator"] h4 + .subgroup,
   .resource .data > [data-prop="bdo:creator"] .subgroup .subsub:first-child > h4 + * { padding-left:0; }

   .resource .data > :not([data-prop="bdo:note"]) .sub:not(:first-of-type) { padding-top:0; }
   
   .propCollapse, .has-collapse.custom .propCollapseHeader, 
   .resource .data [data-prop="bdo:workHasInstance"] .group,
   .resource .data.legal-props > div h3,
   .resource .data.ext-props > div[data-prop="adm:logEntry"]:not(#a) .sub > .subgroup > .subsub > h4.first + *,
   .resource .data.ext-props .subgroup,
   .data:not(.legal-props) > [data-prop='bdo:creator'] .subsub:not(:first-child),
   .resource .data .propCollapseHeader, 
   .resource .data .propCollapse.in-true,
   .resource .data > div:not([data-prop="bdo:creator"]):not([data-prop="bf:identifiedBy"]):not([data-prop="bdo:corporationHasMember"]) .sub > .subgroup > .subsub > h4.first + *,
   .resource .data .group,
   .resource .data > div > .group > .sub > .subgroup { width:100%; box-sizing: border-box; padding-right:0px;}

   .resource .data .subsub.hoyoh { flex-wrap: wrap;}

   .resource .data > [data-prop="bdo:note"] .sub > h4 + div:not(.subsub) { display: inline; padding-left:0; }

   /* .resource .data.top-props, .resource .data.bot-props { padding-left:30px;} */

   .browse-by { margin-left:20px; margin-top:20px; }

   .resource .title + .data + .data { padding-bottom: 30px; }

   .resource .data .sub.willK:not(.keep) > h4.first { position: relative; }
   .resource .data [data-prop="bf:identifiedBy"] .sub.keep > h4.first + .subgroup, 
   .resource .data .sub.willK:not(.keep) > h4.first + .subgroup, 
   .resource .data .sub.willK:not(.keep) > h4.first + h4.label { margin-left:0; }

   .resource .data .sub.keep > h4.first { display:none; }

   .resource .sub > h4.label:not(:first-of-type) { max-width:100%; box-sizing: border-box;}

   .resource .data.images-title, 
   .resource .data h2.intro + h2, 
   .resource .data h2.on, 
   .resource .data h2.on + h2 a span, 
   .resource .data.related > div h2, 
   .resource .data.ext-props > div h2, 
   .resource .data h2 { line-height:32px; }

   .resource .data#outline > h2, 
   .resource .data.related > div h2, 
   .resource .data.ext-props > div h2, 
   .resource .data h2.on, 
   .resource .data h2.on + h2 a span, 
   .resource .data h2.on span:not(.T):not(.lang):not(#a) { font-size:26px; }

   .resource .data h2 + .date { margin-top:10px; display: block; }

   .has-collapse.custom.max-6 .propCollapseHeader.in-true ~ .expand, 
   .has-collapse.custom:not(.max-10) .propCollapseHeader.in-true ~ .expand, 
   .has-collapse.custom .propCollapseHeader.in-true ~ .expand { margin-left:20px; margin-top:8px; }


   .resource .data#perma > div { justify-content: center; }

   .resource .data#perma > div > .NL.nl-copy,   
   .resource .data#perma > div > .NL.nl-dl  { margin:5px; display: block; width:100%; }

   .resource .data#perma > div > #DL { padding-left:0; border-left:none; }
   .resource .data#perma > div > #rid { padding-right:0; }
   .resource .data#perma #cite { padding-right:0; }
   .resource .data#perma #copyright { margin-left:0; padding-right:0; }

   #popDL.cite { flex-wrap: wrap; width:90vw; }
   #popDL.cite > div { width:100%; box-sizing: border-box; padding:0 0 16px 0 !important; }
   #popDL.cite li { max-width: 100%;}


   .resource .data.ext-props .propCollapseHeader + div .sub,
   .resource .data.ext-props .propCollapseHeader > .sub { flex-wrap: wrap;}


   .resource .data .images-thumb, 
   .resource .data [data-prop="bdo:workHasInstance"] .images-thumb { width:100px;height:100px;}


   .resource .data > [data-prop="bdo:workHasInstance"] .inst-prov {
      left: 65px;
      top: 65px;
   }

   .resource .data [data-prop="tmp:propHasEtext"] .group,
   .resource .data [data-prop="tmp:propHasScans"] .group,
   .resource .data [data-prop="bdo:workHasInstance"] .group,
   .resource .data [data-prop="bdo:workHasInstance"] .propCollapse,
   .resource .data [data-prop="bdo:workHasInstance"] .propCollapseHeader { padding-left:0;  }
   
   .resource .data.ext-props > a:first-child { left:35px; }

   .resource .title h2 .T::before { margin-right:10px; }
   .resource .title h2 .RID { left:60px; }
   


   #perma #copyright { position: relative; right:auto; }
   


   .resource .data.access.hasIA h3 { flex-direction: column; align-items: center;}
   .resource .data.access h3::before { background-position: center; margin-bottom:10px; }


   .resource .data#outline > div:last-child { overflow-x:auto; }
   .resource .data#outline .root + div span.top { min-width:75vw; margin-top:7px; }
   .resource .data#outline .root + div > .top:first-child { margin-top:5px;}
   .resource .data#outline .root + div .top + .details { min-width:90vw; }
   .resource .data#outline .root + div .abs { transform: scale(1.3);  transform-origin: 0 50%; }

   .resource .data#outline .root + div .top + .details .sub h4.first { width:80px; padding-right:0; }
   .resource .data#outline .root + div .top + .details .sub h4.first + * { width: calc(100% - 80px - 40px); padding-right:0; } 

   .resource .data#outline > .search input { width: calc(100vw - 170px); }



   #etext-nav { padding: 0 60px; }
   #etext-nav #control { margin-right:auto;}
   #etext-nav #DL,
   #etext-nav #control + a { font-size:0; white-space: nowrap;}
   #etext-nav #control + a #check { margin-right:0; }
   #etext-nav #control + a img { margin:0 0 0 10px;}
   #etext-nav a#DL img { margin-left:0; margin-right:0px;}

   #etext-scroll { align-items:center; }
   #etext-scroll h3:not(:nth-child(2)) { flex-shrink: 1;}
   #etext-scroll h3:nth-child(1) { text-align:left !important;}
   #etext-scroll h3:nth-child(2) { flex-shrink:1; text-align: center !important; padding:0 10px;}

   .nav #back { max-width:calc(100% - 200px); }

   .resource .data#head,
   .resource .title { order:-1; }
   #root > div > .resource.etext > .index + div { display:flex;  flex-direction: column; }
   .resource .data.etext-title { padding-right:15px; order:-1; padding-bottom:10px !important; }
   .resource .data + .data.open-etext { position: relative; padding-left:35px; order:0; padding-bottom:50px !important; z-index:0; }


   .App:not(.home) .SearchPane .formControl { bottom:15px; /*left: 44px;*/ }
   /* 
   .App:not(.home) #search-bar > div:first-child > div:first-child { width:calc(100vw - 40px) !important; } 
   .App:not(.home) #search-bar > div:first-child > div:first-child > div:first-child {       
      width: calc(100% - 48px - 25px);
      margin-left: 25px;
   } */

   #res-header > div .widget-header .widget-title { text-align:center; }


   .resource.user .data #validate { position: relative;}

   .resource.user .data#outline .widget { margin-left:0; }

   .resource.user .data .group .sub h4.first { width:130px; }
   .resource.user .data .group .sub.otherI,
   .resource.user .data .group .sub .FC { display: block;}

}



@media (max-width:535px)
{
   .nav.khmerServer > div > #logo { display:none; }


   #latest h3 { max-width:65%; margin:0; }


   #popDL.print > div { max-width:80vw;width:80vw; }



   #root .App .resultPage #res-header > div .widget-header { max-width:calc( (100% - 40px) / 2); max-height:20px;}
   #res-header > div .widget-header .widget-title { overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }
   #root .App .resultPage #res-header > div ul.widget-header:first-of-type:last-of-type { max-width:calc(100%);}

   #res-header #pagine > div > span,
   #res-header #pagine > div { display:flex; align-items: center;}
   #res-header #pagine #nb { white-space: nowrap;}

   #res-header #pagine #nb { line-height:18px; }

}


.portrait-warn { display: none;}
@media (orientation:portrait) and (max-width:480px) {

   .resource .data#head .header .loader { z-index:1; } 

   .portrait-warn { display: flex; position: fixed; left:0;top:0;bottom:0;right:0;background-color: rgba(0,0,0,0.5);z-index:100001; justify-content: center; align-items: center;}

   .portrait-warn > div  { background-color:white; border-radius:5px; width:50vw;margin:auto; }
   .portrait-warn > div > span { background-image: url(/icons/tilt.png); width:35vw;height:35vw;background-size:80% auto; display: block;border-radius: 50%; 
      background-position: center; background-repeat: no-repeat; margin:auto; animation:3s portrait infinite;
   }
   .portrait-warn > div > p { margin-top:0; }
   .portrait-warn > div > p::before { content:attr(data-tilt1); text-align: center; display: block; font-size:20px; padding:0 10px 5px 10px;}
   .portrait-warn > div > p::after { content:attr(data-tilt2); text-align: center; display: block; font-size:14px;padding:0 10px;}
}
@keyframes portrait {
   0%    { transform: rotate(0); }
   65%,100%  { transform: rotate(90deg); }
 }


@media (max-width:485px)
{

   .App:not(.home) #search-bar > div:first-child > div:first-child > div:first-child { padding-left:24px; }
   
   .nav #logo.khmer + .links { flex-shrink:1; }
   .nav > div { justify-content: space-between; }

/*    
   .nav #logo a#BDRC span, 
   .nav #logo a:last-child span, 
   .nav #logo a:first-child span { display:none; }
   
   .nav > div > #lang,
   .nav > div > #login,
   .nav > div > #login > div > span, 
   .nav > div > :is(a,div,span) { margin:0 0 0 25px !important; }
      */

   .nav > div > .close { margin-right:10px !important;  }

   .nav.khmerServer > div > .close { position: absolute; top:0; }

   /* .nav > div > #logo { position:absolute; left:55px; margin-left:0 !important; } */

   #root .App #res-container, #search-bar > div:first-child > div:first-child { width:100%; }

   .App.home:not(.khmerServer) #search-bar { padding:15px; width:auto;}
   #search-bar > div:first-child > div:first-child  { width:calc(100vw - 30px) !important;  box-sizing: border-box;}
   .SearchPane .formControl { bottom:25px; }
   .App.home .SearchPane .formControl { left:31px; }

   #suggestions li .maxW { display:none; }
   #suggestions li { padding-left:0; }

   #root .App #res-container { width:calc(100% - 30px) !important; box-sizing: border-box; padding:15px 0px;}

   #samples { padding:15px 0; }

   #latest { padding:15px; }
   #latest .seeAll { right:15px; top:20px; }

   .App.home.khmerServer .SearchPane #logo > div > div,
   .SearchPane #logo > div > div { padding:25px; }

   .App.home.khmerServer #search-bar { padding:15px; box-sizing: border-box;}

   .App.home.khmerServer #search-bar > div { width:calc(100vw);}
   .App.home.khmerServer #search-bar > div > div { width:calc(100% - 30px) !important; }

   .App.home.khmerServer #search-bar .links { padding-right:0; position: relative; width:100%;}
   .App.home.khmerServer #search-bar .links a { margin: 0px;}
   .App.home.khmerServer #search-bar .links span { width: 50%; }
   .App.home.khmerServer #search-bar .links span:first-child { justify-content: flex-end;}
   .App.home.khmerServer #search-bar .links span:first-child a { margin-right:20px; }
   .App.home.khmerServer #search-bar .links span:last-child { justify-content: flex-start;}
   .App.home.khmerServer #search-bar .links span:last-child a { margin-left:20px; }

   .App.home.khmerServer section .float img {  width:80vw;height:80vw; object-position: center -28vw; }

   .App.home.khmerServer section .imgs a { display:block; text-align: center;}
   .App.home.khmerServer section .imgs a img { margin-right:0; margin-bottom:30px;}

   .App.home.khmerServer #learn + section + section { display: flex; flex-direction: column;}
   .App.home.khmerServer #learn + section + section > div:first-child { order:1; }
   
   .App.guidedsearch.khmer .static-container.sticky > div:first-child h1 { max-height:100px;overflow:hidden; transition:max-height 400ms ease-in-out; }
   .App.guidedsearch.khmer.scrolled .static-container.sticky > div:first-child h1 { max-height:0; }


   .App.home.khmer.static section.orange-gradient > div { height:auto; }
   .App.home.khmer.static section.orange-gradient > div > div { justify-content: center; height:auto; margin-top:15px;}
   .App.home.khmer.static section.orange-gradient > div > div > div {
      width: 100%;
      text-align: center;
      margin:10px 0 ;
      padding-left:0;
   }
   .App.home.khmer.static section.orange-gradient > div > div + div { margin-top:40px;}

   .App.home.khmer.static section.orange-gradient > div > div > div a { align-self: center; margin-top:15px;}


   #popDL.print > div > .actions { flex-wrap: wrap; justify-content: flex-start;  }
   #popDL.print > div > .actions > a { margin-left:0px; }
   #popDL.print > div > .actions > a + a { margin:20px 0px 20px 0px; }


   .resource .title h2 .T { font-size:18px; }
   .resource .title h2 .T::before { display:block; margin:auto; background-position: center !important; margin-bottom:0px;  }  
   .resource .title h2 .T.work::before { background-size: auto 40px;}
   .resource .title h2 .RID { display: none !important;}

   .resource.hasTabs .title { top:0; width:calc(100% - 66px); position: fixed; right:5px;z-index:10;flex-wrap: wrap;}
   .resource.hasTabs .title::before { width:calc(100% + 60px); height:100%; background: #efefef; display: block; content:'';margin-left:-60px;position:absolute;top:0;left:0;z-index:-1;}
   .resource.hasTabs .title::after { width:calc(100% + 66px); height:10px; background: white; display: block; content:'';margin-left:-56px;}
   #root > div:not(.H100vh) > .resource.hasTabs > .index + div { top:30px;}
   .resource .title h2 { transition:height 200ms; }
   .resource.scrolled .title h2 { height:60px;  }
   .resource .title h2 .T { transition:font-size 200ms; }
   .resource.scrolled .title h2 .T { font-size:0;   }



   .resource .fairuse-IA-link { flex-direction: column; }
   .resource .fairuse-IA-link img.ia { margin-right:0; margin-bottom:5px; }
   .resource .fairuse-IA-link img.link-out { margin-left:0; margin-top:5px; }

   #root > div > .resource.etext-view { padding-left:5px;padding-right:5px;}
   #root > div > .resource.etext-view .data  { padding-left:15px;padding-right:15px;}
   #etext-nav { padding:0 30px 0 20px; }

   .nav #back { max-width:calc(100% - 130px); }



   .resource .data.related > div div.rel-or-crea > div {   width: calc((100vw - 40px - 30px - 20px - 50px)/2); }
   .resource .data.related > div div.rel-or-crea > div .header { height: calc((100vw - 40px - 30px - 20px - 50px)/2); }


   #root div .App .resultPage #filters-UI > div { padding-left:15px; padding-right:15px; }


   .match .inRoot ~ .sepa { display:block; border:none; height:0px;}
   .match .inRoot + .sepa { height:5px; } 

   /* #search-bar > div:first-child > div:first-child > div:first-child { margin-left:32px; } */

   .App:not(.home) .SearchPane .formControl { left:55px; }


   .resource .data#perma > div > #rid { order:-1; margin-left:0; margin-bottom:10px; }
   .resource .data#perma > div { flex-wrap:nowrap; flex-direction: column;}
   .resource .data#perma > div > .NL.nl-cite  { margin:5px; display: block; width:100%; }
   .resource .data#perma > div > :is(#DL,#cite) { padding-right:0; margin:5px;}
   .resource .data#perma > div > #cite { padding-left:0; border-left:none; }




   .resource > .ariane { 
      position: fixed;
      z-index: 100;
      top: 100px;
      left: 10px;
      background: white;
      padding: 0px 9px 4px 9px;
      border-radius: 5px;
      box-shadow: 0 0 5px rgba(0,0,0,0.5);
      transition:top 200ms ease-in-out;
   }


   .resource.scrolled > .ariane { top:65px; }
   
   .resource.portrait-warn-on > .ariane { z-index:0;  }

  .resource > .ariane a span span {
     max-width: 50vw;
     white-space: nowrap;
     overflow: hidden;
     text-overflow: ellipsis;
     vertical-align: bottom;
   }

}


@media (max-width:480px)
{
   
   .nav > div > #lang,
   .nav > div > #login,
   .nav > div > #login > div > span, 
   .nav > div > :is(a,div,span) { margin:0  !important; }
   
   .nav > div > #login { margin-left:0px !important;}

}


@media (max-width:375px)
{
   .nav > div > #logo { display:none; }
}

@media (max-width:400px)
{
   .nav > div > #lang,
   .nav > div > #login,
   .nav > div > #login > div > span, 
   .nav > div > :is(a,div,span) { margin:0 0 0 8px !important; }

   .nav > div > #login { margin-left:4px !important;}

   .nav.khmerServer > div > #lang,
   .nav.khmerServer > div > #login > div > span, 
   .nav.khmerServer > div > :is(a,div,span) { margin:0 0 0 15px !important; }

   .App.guidedsearch.khmer .static-container.sticky > div:first-child + div { padding:10px 20px 40px 20px; }

   #popDL.cite > div > .actions { flex-wrap: wrap; justify-content: flex-start;  }
   #popDL.cite > div > .actions > a { margin-left:0px; }
   #popDL.cite > div > .actions > a + a { margin:20px 20px 20px 0px; }


   .resource .data .images-thumb, 
   .resource .data [data-prop="bdo:workHasInstance"] .images-thumb { width:80px;height:80px;}

   .resource .data > [data-prop="bdo:workHasInstance"] .inst-prov {
      left: 45px;
      top: 45px;
   }


   #etext-nav a#DL img { margin-right:0px; }

   #etext-nav #control + a,
   #etext-nav #dico { display:flex;flex-direction: column; justify-content: center; align-items: center; }

   #etext-nav #control + a img { width:32px; margin-top:5px;} 

   #etext-nav #dico { margin:0 0 0 20px;}

   #etext-nav #dico #check { margin:0 }
   #etext-nav #dico img.ico { width:32px; }


   .resource .data.related > div div.rel-or-crea > div {   width: calc((100vw - 40px - 30px - 20px - 30px)/1); }
   .resource .data.related > div div.rel-or-crea > div .header { height: calc((100vw - 40px - 30px - 20px - 30px)/1); }
}

@media (max-width:480px) {

 
   .infoPanel+.resource>.ariane {
      top: 80px;
   }
   
   /*
   html:not(.auto_rela) {
      overflow:hidden;
   }
   
   html:not(.supports):not(.budax) body:not(.auto_rela) {
     width: 100vh;
     height: 100vw;
     transform: rotate(90deg) translateY(-100vw);
     transform-origin: 0px 0px;
   }

   .fond-logo img { height:100vw;}

   
   
   
   #root .App #res-container,
   #search-bar > div:first-child > div:first-child 
   { width:calc(100vh - 80px) !important; }
   */

   #latest > div:first-of-type { padding:20px; }


   #latest .slide-bg {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr; 
      grid-template-rows: 1fr 1fr; 
      gap: 40px 20px; 
      grid-template-areas: 
      ". . . ."
      ". . . ."; 
   }
   
   #latest .slide-bg > div {
      width: calc((100vw - 20px * 4.5) / 2);
      margin:0; margin-right:0px;
   }
   #latest .slide-bg > div .header {
      height: calc((100vw - 20px * 4.5) / 2);
   }

   #latest .slide-bg.slided > div:nth-child(-n+5),
   #latest .slide-bg:not(.slided) > div:not(:nth-child(-n+3)) { opacity:1;}

   #latest .slide-bg > div:nth-child(n+9) { display:none; }

   #latest .slide-bg > div p { margin-top:15px; }

   #latest .slide-bg.slided {
      margin-left: calc(-100% - 20px);
   }
}

/* -------------------------------------------------------------------------------------------------------------------------------- simplesearch */

.simpleSearch footer,
.simpleSearch #samples,
.simpleSearch .result-content #permalink,
.simpleSearch #res-container > ul:not(#samples) li,
.simpleSearch #settings,
.simpleSearch .mobile-button,
.simpleSearch > .App > div > :not(#res-header):not(#res-container) { display: none; }

#root .simpleSearch .App .resultPage #res-header { height:40px;  }
#root .simpleSearch .App .resultPage #res-header > div { padding:0 10px; }
#root .simpleSearch #res-container { width:calc(100%) !important; padding:10px; margin:0; min-height:360px; box-sizing: border-box;}

.simpleSearch #results { padding:0; }

.simpleSearch .result-content { padding:20px; }

.simpleSearch .result-content+.result-content::before { width:calc(100% - 2 * 20px); left:20px; }

.simpleSearch #res-header { top:0px !important; position: relative;}

.simpleSearch #res-header #pagine #nb:first-child { margin:0; padding:0; }

.simpleSearch .nav { box-shadow:none; }

.SidePane.left.closed { right:-350px; }